import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../reduxStore'

export interface StatusState {
  showError: boolean;
  blockingLoading: boolean;

  // Set this to true when first time model is loaded
  firstTimeModelSyncChecked: boolean;
  debugMode: boolean;
}

// Define the initial state using that type
const initialState: StatusState = {
  showError: false,
  blockingLoading: false,
  firstTimeModelSyncChecked: false,
  debugMode: false
}

const slice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setShowError(state, action: PayloadAction<boolean>) {
      state.showError = action.payload
    },

    setBlockingLoading(state, action: PayloadAction<boolean>) {
      state.blockingLoading = action.payload
    },

    setFirstTimeModelSyncChecked(state, action: PayloadAction<boolean>) {
      state.firstTimeModelSyncChecked = action.payload
    },

    setDebugMode(state, action: PayloadAction<boolean>) {
      state.debugMode = action.payload
    }
  },
})

export const statusActions = slice.actions

export const selectShowError = (state: RootState) => state.status.showError
export const selectBlockingLoading = (state: RootState) => state.status.blockingLoading
export const selectFirstTimeModelSyncChecked = (state: RootState) => state.status.firstTimeModelSyncChecked
export const selectDebugMode = (state: RootState) => state.status.debugMode
export default slice.reducer
