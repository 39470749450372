import { MiddlewareDispatcher } from '../Middleware'
import { ResourcePipelineContext } from '../impl/ResourcePipelineContext'
import { colyseusClient } from '../../../colyseus'
import { extractModelFileName } from '../../extractModelFileName'
import { reduxStore } from '../../../store'
import { ModelLoadingMiddleware } from '../impl'
import { showErrorToast } from '../../../toast'

// Login, server has model, download, decrypt, load into runtime, cache locally
// Login, server no cache model, download default model,  load into runtime,
// Login, server has model, local has cache model, compare, 
//                                                      -  upload local cache
//                                                      -  download remote cache


export async function handleModelLoadingOnStart_WithLogin() {
  // logMiddleware('handleModelLoadingOnStart_WithLogin')
  const loader = new MiddlewareDispatcher<ResourcePipelineContext>()

  // Read local cache
  loader.use(ModelLoadingMiddleware.RemoteResourceWrapperMiddleware)

  loader.use(ModelLoadingMiddleware.ReadLocalCacheMiddleware)
  loader.use(ModelLoadingMiddleware.ReadRemoteCacheMiddleware)
  loader.use(ModelLoadingMiddleware.DefaultModelFallbackMiddleware)
  loader.use(ModelLoadingMiddleware.DownloadResourceMiddleware)
  loader.use(ModelLoadingMiddleware.DecryptionMiddleware)
  loader.use(ModelLoadingMiddleware.LoaderMiddleware)
  loader.use(ModelLoadingMiddleware.WriteLocalCacheMiddleware)

  wrapInRoomModelHandling(loader)

  await loader.dispatch({
    targetId: 'self',
  })
}

export async function handleModelKeepRemoteModel_WithLogin() {
  // logMiddleware('handleModelLoadingOnStart_WithLogin')
  const loader = new MiddlewareDispatcher<ResourcePipelineContext>()

  // Read local cache
  // loader.use(ModelLoadingMiddleware.RemoteResourceWrapperMiddleware)
  loader.use(ModelLoadingMiddleware.ReadRemoteCacheMiddleware)
  loader.use(ModelLoadingMiddleware.DownloadResourceMiddleware)
  loader.use(ModelLoadingMiddleware.DecryptionMiddleware)
  loader.use(ModelLoadingMiddleware.LoaderMiddleware)
  loader.use(ModelLoadingMiddleware.WriteLocalCacheMiddleware)

  await loader.dispatch({
    targetId: 'self',
    forceDownloadRemoteCache: true,
  })
}

/**
 * When the auth state changes and user has logged in
 */
export async function refreshRemoteCache_WithLogin() {
  // logMiddleware('refreshRemoteCache_WithLogin')
  // logMiddleware('begin waiting until all the other request is finished')
  // make a promise to wait until reduxStore.getState().remoteResources.list['self'].status.endsWith('ed')
  const promise = new Promise<void>((resolve, reject) => {
    const unsubscribe = reduxStore.subscribe(() => {
        const state = reduxStore.getState()
        const remoteResources = state.remoteResources.list['self']
        if (
          (remoteResources && (remoteResources.status == undefined || remoteResources.status === 'completed')) || !remoteResources
        ) {
          unsubscribe()
          resolve()
        }
      }
    )
  })
  await promise
  // logMiddleware('start checking second time')

  await handleModelLoadingOnStart_WithLogin()
}

export async function handleModelLoadingOnModelImport_WithLogin(files: File[]) {
  // logMiddleware('handleModelLoadingOnModelImport_WithLogin')

  const loader = new MiddlewareDispatcher<ResourcePipelineContext>()
  loader.use(ModelLoadingMiddleware.RemoteResourceWrapperMiddleware)

  // Read local cache
  loader.use(ModelLoadingMiddleware.LoaderMiddleware)

  //
  loader.use(ModelLoadingMiddleware.WriteLocalCacheMiddleware)
  loader.use(ModelLoadingMiddleware.ReadRemoteCacheMiddleware)
  wrapInRoomModelHandling(loader)

  await loader.dispatch({
    targetId: 'self',
    inputFiles: files,
    directFilesInput: true,
    modelType: 'avatar.live2d.zip',
    version: Date.now(),
    modelName: extractModelFileName(files),
  })
}

function wrapInRoomModelHandling(loader: MiddlewareDispatcher<ResourcePipelineContext>) {
  // When we are in a room, prepare and upload the model
  loader.use(ModelLoadingMiddleware.WriteRemoteCacheMiddleware)
  loader.use(ModelLoadingMiddleware.CompressionMiddleware)
  loader.use(ModelLoadingMiddleware.EncryptionMiddleware)
  loader.use(ModelLoadingMiddleware.UploadResourceMiddleware)

  if (colyseusClient.currentRoom) {
    loader.use(ModelLoadingMiddleware.NotifyRoomNewModelUploadedMiddleware)
  }
}


