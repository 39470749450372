import { ResourcePipelineContext } from '../ResourcePipelineContext'
import { Next } from '../../Middleware'
import { loadModel, loadModelPure } from '../../../loadModel'
import { filterModelFiles, loadCurrentPlayerModel } from '../../../loadModelUtils'
import { reduxStore, updateStatus } from '../../../../store'
import { showErrorToast } from '../../../../toast'
import { colyseusClient, setUpEntity } from '../../../../colyseus'
import { logMiddleware } from '../util/middlewareLogger'

/**
 * This will handle loading the inputFiles into the runtime, throwing error when there's no inputFiles and unable to handle
 * @param context
 * @param next
 * @constructor
 */
export async function LoaderMiddleware(context: ResourcePipelineContext, next: Next): Promise<void> {
  if (!context.inputFiles) throw new Error('No input files')

  let files: File[] | undefined
  if (context.targetId === 'self') {
    files = await handleSelfModelLoad(context, context.targetId, context.inputFiles)

    if (!files) throw new Error('Failed to load model')
    context.filteredFiles = files

    logMiddleware(context, files)
  } else {
    await handleRemoteModelLoad(context.targetId, context.inputFiles)
  }

  return next()
}

async function handleRemoteModelLoad(key: string, f: File[]) {
  updateStatus(key, 'loading')
  const player = colyseusClient.currentRoom?.state.players.get(key);
  if (!player) throw new Error('Player not found, will not load remote model')
  const entity = await loadModelPure(f)
  updateStatus(key, 'loaded')
  setUpEntity(entity, key)
}

async function handleSelfModelLoad(context: ResourcePipelineContext, key: string, f: File[]): Promise<File[] | undefined> {
  let _f: File[] | undefined

  const status = reduxStore.getState().remoteResources.list[key]?.status

  logMiddleware(context, status)
  if (status?.endsWith('ing') && status !== 'starting') {
    await showErrorToast('You are already processing a model, please wait!')
    throw new Error('You are already processing a model, please wait!')
  }

  updateStatus(key, 'loading')
  _f = await loadModelFileDropFiles(context, f)
  updateStatus(key, 'loaded')

  return _f
}

async function loadModelFileDropFiles(context: ResourcePipelineContext, files: File[]): Promise<File[] | undefined> {
  let _files = await filterModelFiles(files)
  if (!_files) throw new Error('No model files found')
  const currentEntity = await loadCurrentPlayerModel(_files)

  logMiddleware(context, _files)

  if (currentEntity) {
    colyseusClient.modelFile = _files
    return _files
  } else {
    throw new Error('Failed to load model')
  }
}