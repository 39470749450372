import React, { useEffect } from 'react'
import { PureLoadingScreen } from './PureLoadingScreen'

export function LoadingScreen() {

  // I make some shit for pre-cache the img
  const imageSteps = [
    'https://avatech-avatar-dev1.nyc3.cdn.digitaloceanspaces.com/public-avatars/welcome_gif/welcome1.gif',
    'https://avatech-avatar-dev1.nyc3.cdn.digitaloceanspaces.com/public-avatars/welcome_gif/welcome2.gif',
    'https://cdn.discordapp.com/attachments/750003831902830655/986491276474069012/unknown.png',
    'https://avatech-avatar-dev1.nyc3.cdn.digitaloceanspaces.com/public-avatars/welcome_gif/welcome4.png',
    'https://cdn.discordapp.com/attachments/750003831902830655/986491798618779688/unknown.png',
    'https://avatech-avatar-dev1.nyc3.cdn.digitaloceanspaces.com/public-avatars/welcome_gif/welcome5.png',
    'https://avatech-avatar-dev1.nyc3.cdn.digitaloceanspaces.com/public-avatars/welcome_gif/welcome_final.gif',
  ];

  useEffect(() => {
    imageSteps.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  return <PureLoadingScreen/>;
}

