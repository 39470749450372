import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../reduxStore'
import { RemoteResourceRequest } from './dataModel'

export type RemoteResourceStatus =
  'starting'

  | 'uploading'
  | 'downloading'
  | 'compressing'
  | 'encrypting'
  | 'decrypting'
  | 'downloaded'
  | 'loaded'
  | 'upload-pending'
  | 'uploaded'
  | 'finishing'
  | 'error'
  | 'canceled'
  | 'cached'
  | 'passed'
  | 'loading'

  | 'completed'

export type RemoteResourcesState = {
  status?: RemoteResourceStatus
  progress: number // 0..1
  description?: string

  shouldCache: boolean

  localCacheVersion?: number; // date
}

export type RemoteResourcesQueue = {
  list: Record<string, RemoteResourcesState>
}

const initialState: RemoteResourcesQueue = {
  list: {},
}

const slice = createSlice({
  name: 'remoteResources',
  initialState,
  reducers: {
    fetchRemoteResource(state, action: PayloadAction<{
      targetId: string,
      shouldCache: boolean
    }>) {
      if (state.list[action.payload.targetId]) {
        state.list[action.payload.targetId].shouldCache = action.payload.shouldCache
      } else {
        state.list[action.payload.targetId] = {
          progress: 0,
          shouldCache: action.payload.shouldCache,
        }
      }
    },

    updateRemoteResourceStatus(state, action: PayloadAction<{
      targetId: string,
      status: RemoteResourceStatus
      description?: string
    }>) {
      if (!state.list[action.payload.targetId]) {
        console.error('updateRemoteResourceStatus: targetId not found', action.payload.targetId)
        return
      }

      state.list[action.payload.targetId].status = action.payload.status
      state.list[action.payload.targetId].description = action.payload.description
    },

    updateRemoteResourceProgress(state, action: PayloadAction<{
      targetId: string,
      progress: number
    }>) {
      if (!state.list[action.payload.targetId]) {
        console.error('updateRemoteResourceProgress: targetId not found', action.payload.targetId)
        return
      }

      state.list[action.payload.targetId].progress = action.payload.progress
    },

    cancelRemoteResource(state, action: PayloadAction<{
      targetId: string,
    }>) {
      if (!state.list[action.payload.targetId]) {
        console.error('cancelRemoteResource: targetId not found', action.payload.targetId)
        return
      }

      state.list[action.payload.targetId].status = 'canceled'
    },

    cleanUpRemoteResourceRequest(state, action: PayloadAction<{
      targetId: string,
    }>) {
      delete state.list[action.payload.targetId]
    },

    cleanUpOutdatedRemoteResourceRequest(state) {

      // Clean up self status to prevent issues
      if (state.list['self']) {
        state.list['self'].status = undefined
        state.list['self'].progress = 0
      }

      for (const key in state.list) {
        if (!state.list[key].shouldCache)
          delete state.list[key]
      }
    },

    updateRemoteResourceLocalVersion(state, action: PayloadAction<{
      targetId: string,
      version: number
    }>) {
      if (!state.list[action.payload.targetId]) {
        console.error('updateRemoteResourceLocalVersion: targetId not found', action.payload.targetId)
        return
      }

      state.list[action.payload.targetId].localCacheVersion = action.payload.version
    },
  },
})

export const remoteResourcesActions = slice.actions
export const selectRemoteResourceState = (state: RootState) => state.remoteResources.list
export const makeSelectRemoteResourceStatus = () =>
  createSelector(
    (state: RootState) => state.remoteResources.list,
    (_: any, id: string) => id,
    (remoteResource, id: string) => remoteResource[id],
  )


export default slice.reducer
