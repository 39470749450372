import { GrowthBook, GrowthBookProvider, useFeature } from "@growthbook/growthbook-react";
import { useEffect, useState } from 'react'
import { getDevice } from '../core/amplitude/amplitude'
import { supabase } from '../core/supabase/client'
import { PureLoadingScreen } from '../components/splash-screen/PureLoadingScreen'

// Create a GrowthBook instance
export const growthbook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    // console.log({
    //   experimentId: experiment.key,
    //   variationId: result.variationId
    // })
  }
});

const GROWTHBOOK_API_KEY = "__GROWTHBOOK_API_KEY__";

export default function GrowthBookWrapper(props: {
  children: React.ReactNode
}) {

  const [loaded, setIsLoaded] = useState(false)
  useEffect(() => {
    //console.log('Refreshinnnnng')
    // Load feature definitions from API
    fetch("https://cdn.growthbook.io/api/features/" + GROWTHBOOK_API_KEY)
      .then((res) => res.json())
      .then((json) => {
        setIsLoaded(true)
        growthbook.setFeatures(json.features);
      });

    const attributes = {
      "id": supabase.auth.session()?.user?.id,
      "deviceId": getDevice(),
      "loggedIn": supabase.auth.session()?.user?.id ? true : false,
      "url": window.location.href
    }
    growthbook.setAttributes(attributes)
  }, [])

  return (
    <GrowthBookProvider growthbook={growthbook}>
      {loaded ? props.children : <PureLoadingScreen />}
    </GrowthBookProvider>
  )
}
