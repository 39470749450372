import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../reduxStore';

export interface feedbackSliceState {
  username: string | null;
  useremail: string | null;
  feedback: string | null;
  isOpen: boolean | null;
}

// Define the initial state using that type
const initialState: feedbackSliceState = {
  username: null,
  useremail: null,
  feedback: null,
  isOpen: false,

};

const slice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setUserName(state, action: PayloadAction<string>) {
      state.username = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.useremail = action.payload;
    },
    setFeedback(state, action: PayloadAction<string>) {
      state.feedback = action.payload;
    },
    setIsOpen(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
    clearFeedback(state) {
      state.username = null;
      state.useremail = null;
      state.feedback = null;
    }
  },
});

export const feedbackAction = slice.actions;
export const selectUsername = (state: RootState) => state.feedback.username;
export const selectUserEmail = (state: RootState) => state.feedback.useremail;
export const selectFeedback = (state: RootState) => state.feedback.feedback;
export const selectIsOpen = (state: RootState) => state.feedback.isOpen;

export default slice.reducer;
