import { createSlice, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit'

type LoadingStatus = 'loading' | 'finished' | 'error'

export interface LoadingState<T> {
  data?: T
  status?: LoadingStatus
}

export const createLoadingSlice = <
  T,
  Reducers extends SliceCaseReducers<LoadingState<T>>
  >({
      name = '',
      initialState,
      reducers,
    }: {
  name: string
  initialState: LoadingState<T>
  reducers: ValidateSliceCaseReducers<LoadingState<T>, Reducers>
}) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      start(state) {
        state.status = 'loading'
      },
      /**
       * If you want to write to values of the state that depend on the generic
       * (in this case: `state.data`, which is T), you might need to specify the
       * State type manually here, as it defaults to `Draft<LoadingState<T>>`,
       * which can sometimes be problematic with yet-unresolved generics.
       * This is a general problem when working with immer's Draft type and generics.
       */
      success(state: LoadingState<T>, action: PayloadAction<T>) {
        state.data = action.payload
        state.status = 'finished'
      },
      ...reducers,

      clear(state: LoadingState<T>) {
        state.data = undefined
        state.status = undefined
      }
    },
  })
}

// const wrappedSlice = createLoadingSlice({
//   name: 'test',
//   initialState: { status: 'loading' } as LoadingState<string>,
//   reducers: {
//     magic(state) {
//       state.status = 'finished'
//       state.data = 'hocus pocus'
//     },
//   },
// })