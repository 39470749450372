import * as checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import { HTMLAttributes } from 'react';

type CheckboxType = HTMLAttributes<HTMLElement> & {
  checked: boolean;
  setChecked: () => void;
  text?: string;
  className?: string;
  offIcon?: JSX.Element;
  onIcon?: JSX.Element;
};

export function Checkbox(props: CheckboxType) {
  const { checked, setChecked, text, className, offIcon, onIcon } = props;
  // const [checked, setChecked] = useState(false);
  return (
    <>
      <div className='flex flex-row gap-2'>
        <checkbox.Root
          checked={checked}
          tabIndex={-1}
          className={`${className} bg-base-100 outline-none w-5 h-5 flex rounded-sm justify-center items-center`}
          onClick={() => setChecked()}
        >
          {checked ? <></> : offIcon}
          <checkbox.Indicator>
            {onIcon == null ?
              checked ? (offIcon ?? <CheckIcon className='text-white' />) : null
              :
              checked ? onIcon : offIcon
            }
            {/* {checked ? events(`${props.eventType}_checkbox_checked`) : events(`${props.eventType}_checkbox_unchecked`)} */}
          </checkbox.Indicator>
        </checkbox.Root>
        <div className='text-white flex items-center'>{text ?? "Don't show again"}</div>
      </div>
    </>
  );
}
