import { colyseusClient } from "../colyseus/colyseusClient";
import {
  apiClient,
  authenticated,
  cleanUpVTSWS,
  vtubeStudioDisconnected,
} from "./vtubeStudioClient";
import { debugPanelEvent } from "../util/eventBus";
import { reduxStore } from "../store/reduxStore";
import {
  patchLocalPlayerStateSelf,
  patchRemotePlayerStateSelf,
} from "../colyseus";
import { useAppDispatch, useAppSelector } from "../../hook/hooks";
import {
  colorAction,
  selectColor,
} from "../../core/store/reducers/colorOverlaySlice";

/**
 * Fetch vTube Studio live 2d param from the API
 * @param  {number} time
 */
export async function fetchVTubeStudioData(time: number) {
  if (!colyseusClient.currentEntity) return;
  if (!colyseusClient.currentEntity.object) return;

  if (vtubeStudioDisconnected()) {
    if (authenticated && apiClient) {
      cleanUpVTSWS();
      debugPanelEvent.emit("refresh_vts_connect_state", true);
    }
    return;
  }

  if (
    !apiClient ||
    !authenticated ||
    !reduxStore.getState().mocap.data?.vtubeStudioState?.active
  ) {
    colyseusClient.parameters = undefined;
    return;
  }

  try {
    const data = await apiClient.live2DParameterList();
    const trackingData = await apiClient.inputParameterList();
    const colorData = await apiClient.sceneColorOverlayInfo();

    const color = {
      B: colorData.colorAvgB,
      G: colorData.colorAvgG,
      R: colorData.colorAvgR,
    };

    // reduxStore.dispatch(colorAction.setColor({ color: color }));
    // reduxStore.dispatch(colorAction.setOverlay(colorData.colorBoost));
    // reduxStore.dispatch(colorAction.setBrightness(colorData.baseBrightness));

    patchRemotePlayerStateSelf(
      {
        R: color.R,
        G: color.G,
        B: color.B,
        Overlay: colorData.colorBoost,
        Brightness: colorData.baseBrightness,
        overlayR: colorData.colorOverlayR,
        overlayG: colorData.colorOverlayG,
        overlayB: colorData.colorOverlayB
      },
      true
    );

    colyseusClient.trackingData = trackingData.defaultParameters;
    colyseusClient.parameters = data.parameters;

    if (colyseusClient.trackingData) {
      const targetParamName = [
        "FacePositionX",
        "FacePositionY",
        "FacePositionZ",
      ];
      let paramXValue: number | undefined = undefined;
      let paramYValue: number | undefined = undefined;
      let paramZValue: number | undefined = undefined;
      for (let i = 0; i < colyseusClient.trackingData.length; i++) {
        const param = colyseusClient.trackingData[i];
        if (param.name === targetParamName[0]) {
          paramXValue = param.value;
        } else if (param.name === targetParamName[1]) {
          paramYValue = param.value;
        } else if (param.name === targetParamName[2]) {
          paramZValue = param.value;
        }

        // and if all three are found, break out of the loop
        if (
          paramXValue !== undefined &&
          paramYValue !== undefined &&
          paramZValue !== undefined
        ) {
          break;
        }
      }

      const extrapolationMultiplier = {
        x: reduxStore.getState().settings.movementConfigX[0],
        y: -reduxStore.getState().settings.movementConfigY[0],
        z: reduxStore.getState().settings.movementConfigZ[0] * -0.001,
      };

      const z = (paramZValue ?? 0) * extrapolationMultiplier.z;

      if (reduxStore.getState().settings.movementConfigEnable) {
        patchLocalPlayerStateSelf({
          movementX: (paramXValue ?? 0) * extrapolationMultiplier.x,
          movementY: (paramYValue ?? 0) * extrapolationMultiplier.y,
          movementZ: z,
        });
      }
      else {
        patchLocalPlayerStateSelf({
          movementX: 0,
          movementY: 0,
          movementZ: 0,
        });
      }
    }
  } catch (e) {}
  if (apiClient) {
    try {
    } catch (e) {
      return;
    }
  }
}
