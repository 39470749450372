import { ApiClient, WebSocketBus } from "vtubestudio";
import { events } from "../amplitude/amplitude";
import { delay } from "./delay";
import { removeAuthToken } from "./loadAuthToken";
import { reloadToken } from "./reloadToken";
import { log } from "../logger/logger";
import { colyseusClient } from "../colyseus/colyseusClient";
import { reduxStore } from "../store/reduxStore";
import { mocapActions } from "../store/reducers/mocapSlice";

export let apiClient: ApiClient | undefined;
export let webSocket: WebSocket | undefined;
let bus: WebSocketBus | undefined;
export let authenticated: boolean = false;

export type VTubeStudioState = {
  active: boolean;
  vTubeStudioVersion: `${number}.${number}.${number}`;
  currentSessionAuthenticated: boolean;
};

export async function getVTubeStudioState(): Promise<| VTubeStudioState
  | undefined> {
  if (!authenticated || apiClient === undefined) return undefined;
  return await apiClient!.apiState();
}

export async function disconnectVTubeStudio() {
  reduxStore.dispatch(mocapActions.start());
  cleanUpVTSWS();

  await delay(100);

  if (webSocket) webSocket?.close();

  reduxStore.dispatch(mocapActions.clear());
}

export function cleanUpVTSWS() {
  authenticated = false;
  apiClient = undefined;
}

export function vtubeStudioDisconnected() {
  return webSocket?.readyState == webSocket?.CLOSED || webSocket?.readyState == webSocket?.CLOSING;
}

export async function connectVTubeStudio(): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    reduxStore.dispatch(mocapActions.start())

    webSocket = new WebSocket("ws://localhost:" + reduxStore.getState().settings.vtubeStudioPort);
    webSocket.addEventListener("error", function(err) {
      webSocket = undefined;
      reduxStore.dispatch(mocapActions.clear());
      reject(err);
    });

    bus = new WebSocketBus(webSocket!);

    webSocket!.onopen = async () => {
      apiClient = new ApiClient(bus!);

      try {
        let token = await reloadToken();
        events("connected_vtube_studio");

        const res = await apiClient.authentication({
          pluginName: "Playground | Avatech",
          pluginDeveloper: "Avatech",
          authenticationToken: token as string
        });

        if (!res.authenticated) {
          log("Re-authenticating...");

          removeAuthToken();
          token = await reloadToken();

          await apiClient.authentication({
            pluginName: "Playground | Avatech",
            pluginDeveloper: "Avatech",
            authenticationToken: token as string
          });
        }

        const stats = await apiClient.apiState();
        authenticated = stats.currentSessionAuthenticated;

        if (!authenticated) {
          disconnectVTubeStudio();
          reject();
          events("connect_vtube_studio_failed");
          reduxStore.dispatch(mocapActions.clear());
          return;
        }
        // log('VTube Studio verison:', stats);
        webSocket!.addEventListener("close", (error) => {
          log("FFF Closed");
          mocapActions.clear()
          // debugPanelEvent.emit('refresh_vts_connect_state', true)
        });

        webSocket!.addEventListener("error", (error) => {
          //reject(error);
          log("FFF Error");
          mocapActions.clear()
          // debugPanelEvent.emit('refresh_vts_connect_state', true)
        });

        events("connect_vtube_studio_success");

        reduxStore.dispatch(mocapActions.success({
          vtubeStudioState: await getVTubeStudioState()
        }));

        resolve(true);
      } catch (e) {
        events("connect_vtube_studio_failed");
        reduxStore.dispatch(mocapActions.clear())
        console.error(e);
        reject(e);
      }
    };


  });
}
