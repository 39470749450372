import { clearTargetPlayerSelectorId, updateTargetPlayerSelectorId } from './updateTargetPlayerSelectorId'

export class PlayerTransformModificationLock {
  timeoutId?: any

  lock(targetId: string, delayUnlock?: number) {
    updateTargetPlayerSelectorId(targetId)

    if (this.timeoutId)
      clearTimeout(this.timeoutId)

    if (delayUnlock)
      this.timeoutId = setTimeout(() => clearTargetPlayerSelectorId(targetId), delayUnlock)
  }

  unlock(targetId: string) {
    clearTimeout(this.timeoutId)
    clearTargetPlayerSelectorId(targetId)
  }
}