import { getAvatarLoaderFromFiles } from './AvatarLoader'
import { Entity } from '../ecs/core/Entity'
import { colyseusClient } from '../colyseus'
import { setupCurrentPlayerModel } from './upload'
import { loadModelPure } from './loadModel'
import { log } from '../logger'

/**
 * Loads a model for the current player from filtered model file.
 * @param files
 * @throws {Error}
 */
export async function loadCurrentPlayerModel(
  files: File[],
): Promise<Entity | undefined> {
  const playerModel = await loadModelPure(files)
  if (!playerModel) throw new Error('Failed to load model')
  await setupCurrentPlayerModel(playerModel)
  return playerModel
}

/**
 * Loads a model for the current player and tries to return the filtered model.
 * @throws {Error}
 * @param files
 */
export async function loadModelFileDropFiles(files: File[]) : Promise<File[] | undefined> {
  // we block avatar drop is we are in a room and uploading avatar
  let _files = await filterModelFiles(files)
  if (!_files) throw new Error('No model files found')
  const currentEntity = await loadCurrentPlayerModel(_files)

  if (currentEntity) {
    colyseusClient.modelFile = files
    // prepareAvatarUpload(files)
    return _files;
  } else {
    throw new Error('Failed to load model')
  }
}

export async function filterModelFiles (files: File[]): Promise<File[]> {
  const loader = getAvatarLoaderFromFiles(files);

  if (!loader) throw new Error('No loader found for files')

  const filteredFiles = await loader.filterFiles(files)

  log(filteredFiles)

  return filteredFiles
}
