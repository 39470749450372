import { createClient, SupabaseClient } from '@supabase/supabase-js'

// export const supabase_url = process.env.NEXT_PUBLIC_SUPABASE_URL;
// const supabase_token = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY;
let supabase: SupabaseClient

export let supabase_url: string | null = '__NEXT_PUBLIC_SUPABASE_URL__'
if (supabase_url === 'undefined')
  supabase_url = null

let supabase_token: string | null = '__NEXT_PUBLIC_SUPABASE_ANON_KEY__'
if (supabase_token === 'undefined')
  supabase_token = null

// log('test')
// log('test')
// log('test')
// log(supabase_url)

if (supabase_url && supabase_token) {
  supabase = createClient(supabase_url, supabase_token)

  // reduxStore.dispatch(userAction.onAuthStateChanged({
  //   user: supabase.auth?.user() ?? null,
  // }))

  // const { data, error } = supabase.auth.onAuthStateChange(
  //   (event, session) => {
  //     console.log(event, session)
  //     reduxStore.dispatch(userAction.onAuthStateChanged({
  //       user: session?.user ?? null,
  //     }))
  //   },
  // )
}

export { supabase }