export function extractModelFileName(files: File[]) {
  let modelName: string = files[0].name || files[0].webkitRelativePath
  let type: string = ''
  modelName.endsWith('.zip') ? (type = '.zip') : (type = '/')
  modelName = modelName.slice(0, modelName.indexOf(type))

  if (modelName === '') {
    //console.log(files)
    throw new Error('Model file name is empty')
  }

  return modelName
}