import React from 'react';
import { CgSpinnerTwo } from 'react-icons/cg';


export function Spinner() {
  return (
    <div className="inline text-gray-200 animate-spin dark:text-[#fff] fill-[#fff] dark:fill-[#fff]">
      <CgSpinnerTwo size={'23'} />
    </div>
  );
}
