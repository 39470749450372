import { $CombinedState, configureStore, Middleware } from '@reduxjs/toolkit'
import { Action, combineReducers, Reducer, ReducersMapObject } from 'redux'
import roomReducers from './reducers/roomSlice'
import mocapSlice from './reducers/mocapSlice'
import settingsSlice, { settingsActions, SettingsState } from './reducers/settingsSlice'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import logSlice, { logMessage } from './reducers/logSlice'
import statusSlice from './reducers/statusSlice'
import { getDevice, initAmplitude } from '../amplitude/amplitude'
import trackerRedux from '@openreplay/tracker-redux'
import { initOpenReplay } from './InitOpenReplay'
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync'
import userSlice from './reducers/userSlice'
import { log } from '../logger/logger'
import { hotjar } from 'react-hotjar'
import playersSlice, { playersActions } from './reducers/playersSlice'
import tempSettingsSlice from './reducers/tempSettingsSlice'
import promptDialogSlice from './reducers/dialog/promptDialogSlice'
import API from '@openreplay/tracker'
import { initSentry } from '../util/initSentry'
import { listenerMiddleware } from './middleware/reduxListener'
import remoteResourcesSlice, {
  RemoteResourcesQueue,
} from './reducers/remote-resources/remoteResourcesSlice'
import pipelineSlice from './reducers/graph-log/pipelineSlice'
import tempRoomSlice, { tempRoomActions } from './reducers/tempRoomSlice'
import shortcutKeySlice, { ShortcutState } from './reducers/shortcutKeySlice'
import feedbackSlice from './reducers/feedbackSlice'
import sceneSlice from './reducers/sceneSlice'
import colorOverlaySlice from './reducers/colorOverlaySlice'
import pixiSceneSlice from './reducers/pixiSceneSlice'

const stateSyncConfig = {
  blacklist: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'logger', 'promptDialog', 'tempRoom'],
}

const persistReducers = {
  settings: persistReducer({
    key: 'settings',
    version: 1,
    storage,
    blacklist: ['mainMenuGUIState', 'showImportTips', 'playBreathAnimation']
  }, settingsSlice),

  shortcutKey: persistReducer({
    key: 'shortcutState',
    version: 1,
    storage,
  }, shortcutKeySlice),

  sceneStates: persistReducer({
    key: 'sceneStates',
    version: 1,
    storage,
  }, sceneSlice),

  remoteResources: persistReducer( {
    key: 'cacheBlob',
    version: 1,
    storage,
  }, remoteResourcesSlice),
}

const staticReducers = {
  room: roomReducers,
  mocap: mocapSlice,
  logger: logSlice,
  pixiScene: pixiSceneSlice,

  user: userSlice,
  status: statusSlice,
  players: playersSlice,
  tempSettings: tempSettingsSlice,
  promptDialog: promptDialogSlice,
  pipeline: pipelineSlice,
  tempRoom: tempRoomSlice,
  feedback: feedbackSlice,
  colorOverlay: colorOverlaySlice,
  ...persistReducers
}

function createReducer(asyncReducers?: ReducersMapObject) {
  const rootReducer = combineReducers({
    ...staticReducers,
    ...asyncReducers,
  })
  return rootReducer
}

export const vercelEnv: string = '__VERCELENV__'

let trackerMiddleware: any

let middlewares: Middleware[] = []

initAmplitude()

export let tracker: API | undefined
hotjar.initialize(2835953, 6)

if (document.location.hostname !== 'localhost') {
  tracker = initOpenReplay(getDevice())
  if (tracker) {
    trackerMiddleware = tracker.use(trackerRedux({
      stateTransformer: (state: RootState) => {
        const { players, promptDialog, tempRoom, ..._state } = state
        return _state
      },
      actionFilter: (action: Action) => {
        const filterAction = [
          // Ignore frequently updated actions
          playersActions.onPlayerUpdate,
          playersActions.onPlayerBatchUpdate,
          playersActions.onPlayerAttachmentBatchUpdate,
          playersActions.onPlayersBatchUpdate,
          settingsActions.setbgColorState,

          settingsActions.setPlayerLocalStateAngle,
          settingsActions.setPlayerLocalStatePosition,
          settingsActions.setPlayerLocalStateScale,
          settingsActions.setPlayerLocalState,

          playersActions.onPlayerIsSelectedUpdate,
          tempRoomActions.updateRoomPassword,
          logMessage,
        ]

        for (let i = 0; i < filterAction.length; i++)
          if (filterAction[i].match(action)) return false

        log(action.type)

        return true
      },
    }))
    middlewares.push(trackerMiddleware)
  }
  initSentry(getDevice())
}

// @ts-ignore
if (window.obsstudio != undefined) {
  middlewares.push(createStateSyncMiddleware(stateSyncConfig))
}

const reduxStore = configureStore({
  reducer: createReducer(),
  // only enable devtools in development mode
  devTools: vercelEnv === 'localhost',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).prepend(listenerMiddleware.middleware).concat(middlewares),
})

export const appState = () => reduxStore.getState()

// Setup broadcast channel listener, allowing redux state sunc
// @ts-ignore
if (window.obsstudio != undefined) {
  initMessageListener(reduxStore)
}

let asyncReducers: ReducersMapObject = {}

export function injectReducer(key: string, newAsyncReducer: Reducer) {
  asyncReducers[key] = newAsyncReducer
  reduxStore.replaceReducer(createReducer(asyncReducers))
}

let persistor = persistStore(reduxStore)

export type AppDispatch = typeof reduxStore.dispatch
export type RootState = ReturnType<typeof reduxStore.getState> & {
  // https://stackoverflow.com/questions/67293951/exported-variable-store-has-or-is-using-name-combinedstate-from-external-mo
  // to get away from a type error
  readonly [$CombinedState]?: undefined;
};
export { reduxStore, persistor }

