import {
  FileLoader,
  Live2DFactory,
  Live2DFactoryContext,
  Live2DLoader,
  Live2DLoaderContext,
  Live2DLoaderTarget,
  Middleware,
  ModelSettings,
  XHRLoader,
} from 'pixi-live2d-display';
import { BaseTexture, FORMATS, ImageBitmapResource, Texture } from 'pixi.js'
import * as PIXI from 'pixi.js';
import PNG from 'png-ts';
import { colyseusClient } from 'playground-core';
import { log } from 'playground-core';

import type { decodeImageBitMap } from "./worker/imageDecodeWorker";
import ImageDecodeWorker from "./worker/imageDecodeWorker?worker";


import type { loadPixiGif } from "./worker/animtedGifWorker";
import AnimatedImageDecodeWorker from "./worker/animtedGifWorker?worker";

import { wrap } from "comlink";
import { AnimatedGIF } from '@pixi/gif'

Live2DFactory.live2DModelMiddlewares[
  Live2DFactory.live2DModelMiddlewares.indexOf(Live2DFactory.urlToJSON)
] = jsonToSettings;
Live2DFactory.live2DModelMiddlewares[
  Live2DFactory.live2DModelMiddlewares.indexOf(
    Live2DFactory.createInternalModel,
  )
] = createInternalModel;

async function jsonToSettings(
  context: Live2DFactoryContext,
  next: (err?: any) => Promise<void>,
) {
  log(context);

  log(Live2DFactory.live2DModelMiddlewares);
  log(Live2DLoader.middlewares);

  return Live2DFactory.urlToJSON(context, next);
  // return next();
}

const factory: Middleware<Live2DFactoryContext> = async (context, next) => {
  // @ts-ignore
  context.originalSource = context.source;
  return FileLoader.factory(context, next);
};

Live2DFactory.live2DModelMiddlewares[
  Live2DFactory.live2DModelMiddlewares.indexOf(FileLoader.factory)
] = factory;

/**
 * A middleware that creates the InternalModel. Requires ModelSettings in context.
 */
async function createInternalModel(
  context: Live2DFactoryContext,
  next: (err?: any) => Promise<void>,
) {
  // @ts-ignore
  context.settings.originalSource = context.originalSource;
  return Live2DFactory.createInternalModel(context, next);
}

const loader: Middleware<Live2DLoaderContext> = async (context, next) => {
  //   log(context);
  //   log(context.url);

  // @ts-ignore
  const files = context.settings.originalSource as File[];
  // TODO using endsWith might have edge cases,
  const file = files.find((x) => x.webkitRelativePath.endsWith(context.url));

  log(context.type);

  if (context.type === 'json') {
    // motion/hiyori_m08.motion3.json
    // log(context.url)
    context.result = await JSON.parse(await file!.text());
    // log(context.result)
    return next();
  } else if (context.type === 'arraybuffer') {
    // motion/hiyori_m08.motion3.json
    // log(context.url)
    context.result = await file?.arrayBuffer();
    // log(context.result)
    return next();
  }

  return new Promise<void>((resolve, reject) => {
    const xhr = XHRLoader.createXHR(
      context.target,
      context.settings ? context.settings.resolveURL(context.url) : context.url,
      context.type,
      (data) => {
        context.result = data;
        resolve();
      },
      reject,
    );
    xhr.send();
  });
};

Live2DLoader.middlewares[Live2DLoader.middlewares.indexOf(XHRLoader.loader)] =
  loader;

export async function loadPixiImageFormFileFast(file: Blob) {
  let bitmap;

  // Seems like there is a problem with the worker in safari
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (typeof (Worker) !== 'undefined' && !isSafari) {
    const worker = new ImageDecodeWorker();
    const asyncDecodeImageBitMap = wrap<typeof decodeImageBitMap>(worker);
    bitmap = await asyncDecodeImageBitMap(file!);
    worker.terminate()
  } else {
    // web-worker not supported
    bitmap = await createImageBitmap(file!)
  }

  return new colyseusClient.PIXI.Texture(new BaseTexture(new ImageBitmapResource(bitmap)))
}

export async function loadPixiGifFileFast(imageArray: ArrayBuffer){
  if (typeof(Worker) !== "undefined") {
    const worker = new AnimatedImageDecodeWorker();
    const asyncDecodeImageBitMap = wrap<typeof loadPixiGif>(worker);
    let sprite = await asyncDecodeImageBitMap(imageArray);
    worker.terminate()
    return sprite
  } else {
    // web-worker not supported
    // bitmap = await createImageBitmap(file!)
  }

  return null
}

/**
 * A middleware that populates the Live2DModel with essential resources.
 * Requires ModelSettings in context immediately, and InternalModel in context
 * when all the subsequent middlewares have finished.
 */
const useBrowserLoading = false;
const useNativeDecode = true;
export const setupEssentials: Middleware<Live2DFactoryContext> = async (
  context,
  next,
) => {
  if (context.settings) {
    const live2DModel = context.live2dModel;

    // @ts-ignore
    const files = context.originalSource as File[];
    // TODO using endsWith might have edge cases,

    const textureLoadings = context.settings.textures.map(async (tex) => {
      const url = context.settings!.resolveURL(tex);
      const file = files.find((x) => x.webkitRelativePath.endsWith(tex));

      log(url);
      log(tex);
      log(file);

      if (!useBrowserLoading) {
        return new Promise<Texture>(async (resolve) => {
          let t;
          if (useNativeDecode) {
            t = loadPixiImageFormFileFast(file!);
          } else {
            // Create a PNG object
            const b = await file!.arrayBuffer();
            log(b);
            var imageUintArray = new Uint8Array(b);
            const image = PNG.load(imageUintArray);
            log(image)
            //
            // // `pixels` is a 1D array (in rgba order) of decoded pixel data
            const pixels = image.decodePixels()

            t = colyseusClient.PIXI.Texture.fromBuffer(
              pixels,
              image.width,
              image.height,
              {
                alphaMode: colyseusClient.PIXI.ALPHA_MODES.PREMULTIPLY_ON_UPLOAD,
                mipmap: colyseusClient.PIXI.MIPMAP_MODES.POW2,
                scaleMode: colyseusClient.PIXI.SCALE_MODES.LINEAR,

                // anisotropicLevel?: number;
                // wrapMode: PIXI.WRAP_MODES.;
                // format: colyseusClient.PIXI.FORMATS.RGBA,
                // type: PIXI.TYPES.,
                // target: PIXI.TARGETS,
                // alphaMode: PIXI.ALPHA_MODES,
                // width: number;
                // height: number;
                // resolution: number;
                // resourceOptions: any;
              }
            );
          }

          log(t);
          resolve(t);
        });
      } else {
        return createTexture(url, { crossOrigin: context.options.crossOrigin });
      }
    });

    // wait for the internal model to be created
    await next();

    if (context.internalModel) {
      live2DModel.internalModel = context.internalModel;
      live2DModel.emit('modelLoaded', context.internalModel);
    } else {
      throw new TypeError('Missing internal model.');
    }

    live2DModel.textures = await Promise.all(textureLoadings);
    log(live2DModel.textures);
    live2DModel.emit('textureLoaded', live2DModel.textures);
  } else {
    throw new TypeError('Missing settings.');
  }
};

Live2DFactory.live2DModelMiddlewares[
  Live2DFactory.live2DModelMiddlewares.indexOf(Live2DFactory.setupEssentials)
] = setupEssentials;

export function createTexture(url: string, options: { crossOrigin?: string } = {}): Promise<Texture> {
  const textureOptions: any = { resourceOptions: { crossorigin: options.crossOrigin } };

  // there's already such a method since Pixi v5.3.0
  if ((colyseusClient.PIXI.Texture as any).fromURL) {
    return colyseusClient.PIXI.Texture.fromURL(url, textureOptions).catch((e: any) => {
      if (e instanceof Error) {
        throw e;
      }

      // assume e is an ErrorEvent, let's convert it to an Error
      const err = new Error('Texture loading error');
      (err as any).event = e;

      throw err;
    });
  }

  // and in order to provide backward compatibility for older Pixi versions,
  // we have to manually implement this method
  // see https://github.com/pixijs/pixi.js/pull/6687/files

  textureOptions.resourceOptions.autoLoad = false;

  const texture = Texture.from(url, textureOptions);

  if (texture.baseTexture.valid) {
    return Promise.resolve(texture);
  }

  const resource = texture.baseTexture.resource as any;

  // before Pixi v5.2.2, the Promise will not be rejected when loading has failed,
  // we have to manually handle the "error" event
  // see https://github.com/pixijs/pixi.js/pull/6374
  resource._live2d_load ??= new Promise<Texture>((resolve, reject) => {
    const errorHandler = (event: ErrorEvent) => {
      (resource.source as HTMLImageElement).removeEventListener('error', errorHandler);

      // convert the ErrorEvent to an Error
      const err = new Error('Texture loading error');
      (err as any).event = event;

      reject(err);
    };

    (resource.source as HTMLImageElement).addEventListener('error', errorHandler);

    (resource.load() as Promise<unknown>).then(() => resolve(texture)).catch(errorHandler);
  });

  return resource._live2d_load;
}
