import { cloneElement, useState } from 'react';
import {
  Placement,
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss,
} from '@floating-ui/react-dom-interactions';
import { Transition } from '@headlessui/react';


interface Props {
  dataTip?: string | React.ReactNode;
  placement?: Placement;
  children: JSX.Element;
  className?: string
}

export default function Tooltip({
  children,
  dataTip,
  placement = 'top',
  className,
}: Props) {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [offset(5), flip(), shift({ padding: 8 })],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    // useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  if (!dataTip) return <>{children}</>;

  return (
    <>
      <div {...getReferenceProps({ ref: reference })}>
        {cloneElement(
          children, {...children.props}
        )}
      </div>

     <Transition
        show={open && dataTip != undefined}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        as="div"
        className='absolute'
      >
       <div
         {...getFloatingProps({
           ref: floating,
           className: `min-w-xs bg-base-100 rounded-lg text-sm px-2 py-1 w-max ${className ?? ''}`,
           style: {
             position: strategy,
             top: y ?? '',
             left: x ?? '',
           },
         })}
       >
         {dataTip}
       </div>
     </Transition>
    </>
  );
}
