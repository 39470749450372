import { ResourcePipelineContext } from '../ResourcePipelineContext'
import { Next } from '../../Middleware'
import { colyseusClient, sendRoomEventSafe } from '../../../../colyseus'
import { logMiddleware } from '../util/middlewareLogger'

export async function InRoomModelHandlingMiddleware(context: ResourcePipelineContext, next: Next): Promise<void> {
  if (!colyseusClient.currentRoom) {
    logMiddleware(context,"Skipping rest, no room")
    return
  }
  return next()
}