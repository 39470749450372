import { colyseusClient } from '../colyseusClient'
import { appState, reduxStore, selectHostId, selectMocapConnected } from '../../store'
import { mapSessionIdToReduxPlayerId } from '../utils'
import { AdvancedBloomFilter } from 'pixi-filters'

export function updateVtsFilter(t: number) {
  let convertR: number
  let convertG: number
  let convertB: number

  const vtsFilter = colyseusClient.vtsAdjustmentFilter
  // Do not update if there's no filter
  if (!vtsFilter) return

  if (!selectMocapConnected(appState())) {
    vtsFilter.enabled = false
    return
  }

  let host = reduxStore.getState().players.players[mapSessionIdToReduxPlayerId(selectHostId(reduxStore.getState())!)]

  // if we are not in a room use self as taget value
  if (!host) host = reduxStore.getState().players.players['self']

  if (host) {
    vtsFilter.enabled = host?.syncState?.colorOverlayEnable
  }

  if (host && !(host?.syncState.overlayR === 255 && host?.syncState.overlayG === 255 && host?.syncState.overlayB === 255)) {
    const hostColorR = host?.syncState.R
    const hostColorG = host?.syncState.G
    const hostColorB = host?.syncState.B
    const hostOverlay = host?.syncState.Overlay
    const hostBrightness = host?.syncState.Brightness

    convertR = hostColorR / 255 * hostOverlay / 50 + hostBrightness / 100
    convertG = hostColorG / 255 * hostOverlay / 50 + hostBrightness / 100
    convertB = hostColorB / 255 * hostOverlay / 50 + hostBrightness / 100

    vtsFilter.red = convertR
    vtsFilter.green = convertG
    vtsFilter.blue = convertB

    let updateBloomScale: number
    let updateBrightness: number

    updateBloomScale = 0.5 + Math.max(convertR, convertG, convertB) / (255 * 4)
    updateBrightness = 0.5 + Math.max(convertR, convertG, convertB) / (255 * 8)

    let filter = (colyseusClient.app?.stage.filters?.[0] as AdvancedBloomFilter)
    if (filter) {
      filter.bloomScale = updateBloomScale
      filter.brightness = updateBrightness
    }
  } else if (host) {
    vtsFilter.red = 1
    vtsFilter.green = 1
    vtsFilter.blue = 1
  }
}