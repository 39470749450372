import { MiddlewareDispatcher } from '../Middleware'
import { ResourcePipelineContext } from '../impl/ResourcePipelineContext'
import { ModelDecryptKey } from 'playground-data-model'
import { log } from '../../../logger'
import { RemoteResourceWrapperMiddleware } from '../impl/RemoteResourceWrapperMiddleware'
import { DownloadResourceMiddleware } from '../impl/network/DownloadResourceMiddleware'
import { DecryptionMiddleware } from '../impl/modifier/CryptoMiddleware'
import { LoaderMiddleware } from '../impl/resource-loader/LoaderMiddleware'
import {
  handleModelLoadingOnModelImport_NoLogin,
  handleModelLoadingOnStart_NoLogin,
  handleModelUploadOnRoomJoin_NoLogin,
} from './Pipeline_NoLogin'
import { reduxStore, selectHasLogin, statusActions } from '../../../store'
import {
  handleModelLoadingOnModelImport_WithLogin,
  handleModelLoadingOnStart_WithLogin,
  refreshRemoteCache_WithLogin,
} from './Pipeline_Login'
import { supabase } from '../../../supabase'
import { showErrorToast, showToast } from '../../../toast'
import { checkForRecoverSession } from 'playground-web/src/components/core/LoginEventReceiver'

export const hasLogin = () => selectHasLogin(reduxStore.getState())

export async function handleModelLoadingOnStart() {
  log('hello')
  log(hasLogin())

  reduxStore.dispatch(statusActions.setBlockingLoading(true))

  if (hasLogin()) {
    // If we have valid recover session, we don't need to do anything
    if (!checkForRecoverSession())
      await handleModelLoadingOnStart_WithLogin()
  } else {
    await handleModelLoadingOnStart_NoLogin()
  }

  reduxStore.dispatch(statusActions.setBlockingLoading(false))
  reduxStore.dispatch(statusActions.setFirstTimeModelSyncChecked(true))
}

export async function handleModelLoadingOnModelImport(files: File[]) {
  if (hasLogin()) {
    await handleModelLoadingOnModelImport_WithLogin(files)
  } else {
    await handleModelLoadingOnModelImport_NoLogin(files)
  }
}

export async function handleModelUploadOnRoomJoin() {
  if (hasLogin()) {
  } else {
    await handleModelUploadOnRoomJoin_NoLogin()
  }
}

export async function handleRemoteCacheRefresh() {
  if (hasLogin()) {
    showToast('Refreshing remote cache...')
    reduxStore.dispatch(statusActions.setFirstTimeModelSyncChecked(false))
    reduxStore.dispatch(statusActions.setBlockingLoading(true))
    await refreshRemoteCache_WithLogin()
    reduxStore.dispatch(statusActions.setBlockingLoading(false))
    reduxStore.dispatch(statusActions.setFirstTimeModelSyncChecked(true))
  } else {

  }
}

export async function handleModelLoadingRemoteModel(modelUrl: string, sessionId: string, iv?: string, key?: string) {
  const loader = new MiddlewareDispatcher<ResourcePipelineContext>()

  let decryptKey: ModelDecryptKey | undefined

  // Default model dont have encryption
  if (iv && key)
    decryptKey = {
      iv: iv!,
      key: key!,
    }

  log('Test Test')
  log(decryptKey)

  loader.use(RemoteResourceWrapperMiddleware)
  loader.use(DownloadResourceMiddleware)
  if (decryptKey) {
    loader.use(DecryptionMiddleware)
  }
  loader.use(LoaderMiddleware)

  await loader.dispatch({
    targetId: sessionId,
    modelDownloadUrl: modelUrl,
    decryptKey: decryptKey,
    modelName: sessionId + '.avatar.live2d.zip',
  })
}