import { PostgrestFilterBuilder } from '@supabase/postgrest-js'
import { log } from '../logger/logger'
import { showToast } from '../toast/showToast'

export type extractGeneric<Type> = Type extends PostgrestFilterBuilder<infer X> ? X : never

export async function fetchSupabase<J extends PostgrestFilterBuilder<extractGeneric<J>>>(supabaseQuery: J): Promise<extractGeneric<J>[] | null> {
    const data = await supabaseQuery
    log(data)
    if (data.error) {
      if (data.error.message)
        showToast('Error: ' + data.error.message)
      else
        showToast('Error: something went wrong')

      throw new Error(data.error.message)
    }
    return data.data
}