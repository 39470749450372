import localforage from 'localforage';

const storeName = 'playground-settings';
// export const currentPlayerModelState = atom<Live2DModel | undefined>({
//   key: 'currentPlayerModelState', // unique ID (with respect to other atoms/selectors)
//   default: undefined, // default value (aka initial value)
// });
localforage.config({
  // driver      : localforage.WEBSQL, // Force WebSQL; same as using setDriver()
  name: 'Playground',
  version: 1,
  // size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
  storeName: storeName, // Should be alphanumeric, with underscores.
  // description : 'some description'
});

export const store = localforage.createInstance({
  name: storeName
});
