import { ResourcePipelineContext } from '../ResourcePipelineContext'
import { Next } from '../../Middleware'
import { log } from '../../../../logger'
import { fetchUrlWithSupabaseSession, queryModelData, supabase } from '../../../../supabase'
import { checkInput } from '../util/checkInput'
import { reduxStore, selectHasLogin, updateStatus } from '../../../../store'
import { eventsError } from '../../../../amplitude'
import { ModelUrlResponse } from 'playground-data-model'
import { colyseusClient } from '../../../../colyseus'
import { decodeDecryptKey } from '../modifier/CryptoMiddleware'
import { hasLogin } from '../../pipeline/Pipeline'
import { logMiddleware } from '../util/middlewareLogger'
import { showDialog } from 'playground-web/src/components/utils/dialogUtils'
import {BsCloudUpload as UploadIcon} from 'react-icons/bs'
import { showCacheConflictsDialog } from 'playground-web/src/components/dialog/CacheConflictsDialog'

export async function ReadRemoteCacheMiddleware(context: ResourcePipelineContext, next: Next): Promise<void> {
  // let hasLogin = selectHasLogin(reduxStore.getState())
  if (!hasLogin()) {
    logMiddleware(context,'bypass ReadRemoteCacheMiddleware, no login')
    return next()
  }
  // if (!context.localCacheVersion) {
  //   throw new Error('localCacheVersion is not set')
  // }

  context.userId = supabase.auth.user()!.id

  const remoteModel = await queryModelData(context.userId)

  if (!remoteModel || !remoteModel[0]) {
    logMiddleware(context,'bypass ReadRemoteCacheMiddleware, no remote model')
    return next()
  }

  const data = remoteModel[0]

  try {
    checkInput({
      upload_time: data.upload_time,
      model_name: data.model_name,
      model_key: data.model_key,
    })
  } catch (e: any) {
    // logMiddleware(context,e.message)
    // updateStatus(context.targetId, 'error', e.message)
    logMiddleware(context,'bypass ReadRemoteCacheMiddleware, ' + e.message)
    return next()
  }

  context.remoteCacheVersion = data.upload_time ?? undefined
  context.modelId = data.model_id

  // Only apply the server values if we are not directly loading from the local
  if (!context.directFilesInput) {
    if (!context.forceDownloadRemoteCache && (data.upload_time ?? 0) > (context.localCacheVersion ?? 0)) {
      const ok = await showCacheConflictsDialog('download', context.localCacheVersion,data.upload_time ?? undefined)

      if (!ok) {
        logMiddleware(context,'bypass ReadRemoteCacheMiddleware, user declined to download remote model')
        context.forceWriteRemoteCache = true
        return next()
      }
    }


    context.modelName = data.model_name
    context.modelBucket = data.model_bucket ?? undefined
    context.rawModelKey = data.model_key ?? undefined
    context.modelType = data.type ?? undefined
    context.version = data.upload_time ?? undefined
    context.remoteCacheVersion = data.upload_time ?? undefined
    if (data.model_key)
      context.decryptKey = decodeDecryptKey(data.model_key)
    if (context.decryptKey)
      colyseusClient.modelDecryptKey = context.decryptKey
  } else {
    // The dragged in models should always be latest, so we don't need to check and can skip to next step
    logMiddleware(context,'bypass ReadRemoteCacheMiddleware, directFilesInput')
    return next()
  }

  // logMiddleware(context,'Setting modelDecryptKey' + colyseusClient.modelDecryptKey)
  // logMiddleware(context,colyseusClient.modelDecryptKey)
  // logMiddleware(context,data)

  try {
    checkInput({
      remoteCacheVersion: context.remoteCacheVersion,
      modelName: context.modelName,
      modelId: context.modelId,
      modelBucket: context.modelBucket,
      rawModelKey: context.rawModelKey,
    })
  } catch (e: any) {
    // logMiddleware(context, e.message)
    // updateStatus(context.targetId, 'error', e.message)
    logMiddleware(context,'bypass ReadRemoteCacheMiddleware, ' + e.message)
    return next()
  }

  // If local cache is newer or the same, skip
  if ((context.localCacheVersion != undefined && context.localCacheVersion > context.remoteCacheVersion!) || context.localCacheVersion === context.remoteCacheVersion) {
    logMiddleware(context,'bypass ReadRemoteCacheMiddleware, local cache is up to date')
    return next()
  }

  const res = await fetchUrlWithSupabaseSession('/get-model-download-url?modelId=' + context.modelId + '&modelBucket=' + context.modelBucket)

  if (!res) {
    logMiddleware(context,'No download url')
    eventsError('get_cache_model_no_download_url')
    // updateStatus(context.targetId, 'error', 'Unable to get resources download url.')
    throw new Error('Unable to get resources download url.')
    // return next()
  }

  let modelUrlResponse: ModelUrlResponse = await res.json()
  context.modelDownloadUrl = modelUrlResponse.url
  context.modelBucket = modelUrlResponse.modelBucket

  // Clearing the local input files to ensure it gets the latest from the server
  context.inputFiles = undefined

  // logMiddleware(context,modelUrlResponse)

  return next()
}