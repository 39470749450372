import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PromptDialogEntry } from './dialogDataType'
import { RootState } from '../../reduxStore'

export interface PromptDialogState {
  dialogQueue: Record<string, any>
}

// Define the initial state using that type
const initialState: PromptDialogState = {
  dialogQueue: {},
}

const slice = createSlice({
  name: 'promptDialog',
  initialState,
  reducers: {
    queueDialog(state, action: PayloadAction<{
      id: string
    }>) {
      state.dialogQueue[action.payload.id] = {}
    },
    dismissDialog(state, action: PayloadAction<string>) {
      delete state.dialogQueue[action.payload]
    }
  }
})

export const promptDialogActions = slice.actions
export const selectPromptDialog = (state: RootState) => state.promptDialog.dialogQueue

export default slice.reducer
