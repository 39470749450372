import { ResourcePipelineContext } from '../ResourcePipelineContext'
import { Next } from '../../Middleware'
import { colyseusClient } from '../../../../colyseus'
import { getAvatarLoaderKeyFromFiles } from '../../../AvatarLoader'
import { logMiddleware } from '../util/middlewareLogger'

export async function RemoteResourceUploadUrlLocator_Anon(context: ResourcePipelineContext, next: Next) {
  const room = colyseusClient.currentRoom

  if (!context.inputFiles) {
    throw new Error('No input files')
  }

  if (!room) {
    throw new Error('Not in a room')
  }

  const loader = getAvatarLoaderKeyFromFiles(context.inputFiles)
  const model_id = room.id + '_' + room!.sessionId + '_' + loader

  room?.send('upload', model_id)

  const getUrl = async () => {
    return await new Promise<string>(async (resolve) => {
      const unbind = room?.onMessage('signedUrl', async (data) => {
        unbind()
        resolve(data)
      })
    })
  }

  context.modelId = model_id
  context.modelUploadUrl = await getUrl()

  return next()
}