import { listenerMiddleware } from '../../middleware'
import { remoteResourcesActions } from './remoteResourcesSlice'

// an id to blob mapping
export const globalBlobQueue: Record<string, {
  request?: XMLHttpRequest
}> = {}

listenerMiddleware.startListening(
  {
    actionCreator: remoteResourcesActions.cancelRemoteResource,
    effect: (action, api) => {
      globalBlobQueue[action.payload.targetId]?.request?.abort()
    },
  },
)

