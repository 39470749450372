import { log } from '../logger/logger'
import JSZip from 'jszip'

export async function compressFilesDirectly(entries: File[], onUpdate?: (metadata: {
  percent: number;
  currentFile: string;
}) => void): Promise<Blob> {
  log('compressFilesDirectly')

  var zip = new JSZip()

  for (let i = 0; i < entries.length; i++) {
    const file = entries[i]
    // const name = basename(file.webkitRelativePath);
    // const data = await readAsBase64(file);
    zip.file(file.webkitRelativePath, file, { base64: true })
  }

  const blob = await zip.generateAsync({ type: 'blob' }, onUpdate as any)
  log(blob)

  // saveAs(blob, 'vtube.zip');

  return blob
}
