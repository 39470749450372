import { Client, Room } from 'colyseus.js';
import { MyRoomState, ColorData, Vector3, ModelDecryptKey } from 'playground-data-model'
import { ILive2DParameter, IVTSParameter } from 'vtubestudio';
import { JwkPair } from '../util/generateKeyPair';
import { Entity } from '../ecs/core/Entity';
import { getColyseusUrl } from './getColyseusUrl';
import { LayerProps } from '../live2d/Live2DAvatarLoader';
import * as PIXI from 'pixi.js';
import { PlayerTransformModificationLock } from './transform-sync/PlayerTransformModificationLock';
import { SVG } from './SVG'
import { AdjustmentFilter, AdvancedBloomFilter } from 'pixi-filters'

var client = new Client(getColyseusUrl());

export const defaultModelUrl = 'https://avatech-avatar-dev1.nyc3.cdn.digitaloceanspaces.com/public-avatars/hiyori_pro_en_Default-Model-Is-Not-Encrypted.zip'

let colyseusClient: {
  client: Client;
  currentRoom?: Room<MyRoomState>;
  modelFile?: File[] | null;
  loadDefaultMode: boolean;
  trackingData?: IVTSParameter[];
  parameters?: ILive2DParameter[];
  PIXI: typeof PIXI;

  app?: PIXI.Application;
  modelContainer?: PIXI.Container;
  vtsAdjustmentFilter?: AdjustmentFilter;
  backgroundAdjustmentFilter?: AdjustmentFilter;
  overallBloomFilter?: AdvancedBloomFilter;

  roomPassword?: string;
  modelDecryptKey?: ModelDecryptKey;
  keyPair?: JwkPair;
  leavingRoom?: boolean;
  currentEntity?: Entity;
  allEntities?: Entity[];
  avatarIcons?: LayerProps[];
  attachments?: {id: string, attachedPlayerId: string, drawerId: string, attachment: SVG | PIXI.Sprite, drawableName: string, nearestVertex: number, offsetX: number, offsetY: number}[];

  holdingEntity?: Entity
  lastSelectedModel?: Entity
  touchingEntity?: Entity
  previousModel?: Entity
  initX: number
  initY: number

  playerLock: PlayerTransformModificationLock
} = {
  PIXI: PIXI,
  client: client,
  modelFile: null,
  loadDefaultMode: true,
  allEntities: [],
  avatarIcons: [],
  attachments: [],

  initX: 0,
  initY: 0,

  playerLock: new PlayerTransformModificationLock()
};

export { colyseusClient };
