import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../reduxStore'
import { PlayerSyncState, defaultSyncState } from 'playground-data-model'

export interface EntityState {
  [id: string]: any


  syncState: PlayerSyncState
  iconUrl?: string
  isSelected?: boolean
  attachments: Record<string, {x: number, y: number}>
}

export interface PlayersState {
  players: Record<string, EntityState>
}

const initialState: PlayersState = {
  players: {},
}

const slice = createSlice({
  name: 'playersSlice',
  initialState,
  reducers: {
    onPlayerJoin(state, action: PayloadAction<{
      key: string,
      defaultState?: Partial<PlayerSyncState>
    }>) {
      state.players[action.payload.key] = {
        syncState: {
          ...defaultSyncState,
          ...action.payload.defaultState,
        },
        attachments: {}
      }
    },
    onPlayerLeave(state, action: PayloadAction<string>) {
      delete state.players[action.payload]
    },
    onPlayerUpdate(state, action: PayloadAction<{
      playerId: string
      changedField: string
      changedValue: any
    }>) {
      const player = state.players[action.payload.playerId]
      if (player) {
        (state.players[action.payload.playerId].syncState as any)[action.payload.changedField] = action.payload.changedValue
      }
    },

    onPlayerBatchUpdate(state, action: PayloadAction<{
      playerId: string
      changes: {
        changedField: string
        changedValue: any
      }[]
    }>) {
      const player = state.players[action.payload.playerId]
      if (player) {
        action.payload.changes.forEach((change) => {
          (state.players[action.payload.playerId].syncState as any)[change.changedField] = change.changedValue
        })
      }
    },

    onPlayersBatchUpdate(state, action: PayloadAction<{
      playerId: string
      changes: {
        changedField: string
        changedValue: any
      }[]
    }[]>) {
      action.payload.forEach((c) => {
        const player = state.players[c.playerId]
        if (player) {
          c.changes.forEach((change) => {
            (state.players[c.playerId].syncState as any)[change.changedField] = change.changedValue
          })
        }
      })
    },

    onPlayerIconUpdate(state, action: PayloadAction<{
      playerId: string
      iconUrl: string
    }>) {
      state.players[action.payload.playerId].iconUrl = action.payload.iconUrl
    },

    onPlayerAttachmentUpdate(state, action: PayloadAction<{
      playerId: string
      id: string
      x: number
      y: number
    }>) {
      state.players[action.payload.playerId].attachments[action.payload.id] = {x: action.payload.x, y: action.payload.y}
    },

    onPlayerAttachmentBatchUpdate(state, action: PayloadAction<{
      playerId: string
      id: string
      x: number
      y: number
    }[]>) {
      action.payload.forEach((payload) => {
        if (state.players[payload.playerId])
          state.players[payload.playerId].attachments[payload.id] = {x: payload.x, y: payload.y}
      })
    },

    onPlayerIsSelectedUpdate(state, action: PayloadAction<{
      playerId: string
      isSelected: boolean
    }>) {
      // Checking if the player is still here.
      if (state.players[action.payload.playerId])
        state.players[action.payload.playerId].isSelected = action.payload.isSelected
    }
  },

})

export const playersActions = slice.actions
export const selectPlayerIds = (state: RootState) => Object.keys(state.players.players)

export const makeSelectPlayerHostState = () =>
  createSelector(
    (state: RootState) => state.players.players,
    (_: any, id: string) => id,
    (players, id: string) => {
      return {
        bgColorOverlayEnable: players[id]?.syncState?.bgColorOverlayEnable,
      }
    },
    {
      // This will have the effect of only re-rendering when the player's selected props changes
      memoizeOptions: {
        resultEqualityCheck: shallowEqual,
      },
    },
  )


export const makeSelectPlayerIconState = () =>
  createSelector(
    (state: RootState) => state.players.players,
    (_: any, id: string) => id,
    (players, id: string) => {
      return {
        iconUrl: players[id].iconUrl,
        playerNumber: players[id].syncState.playerNumber,
        isSelected: players[id].isSelected,
      }
    },
    {
      // This will have the effect of only re-rendering when the player's selected props changes
      memoizeOptions: {
        resultEqualityCheck: shallowEqual,
      },
    },
  )
export const makeSelectPlayerMouseState = () =>
  createSelector(
    (state: RootState) => state.players.players,
    (_: any, id: string) => id,
    (players, id: string) => {
      return {
        mouseX: players[id].syncState.mouseX,
        mouseY: players[id].syncState.mouseY,
        mouseType: players[id].syncState.mouseType,
        mouseStatus: players[id].syncState.mouseStatus,
        mousePressure: players[id].syncState.mousePressure,
        playerNumber: players[id].syncState.playerNumber,
        selectingPlayerId: players[id].syncState.selectingPlayerId,
        drawingid: players[id].syncState.drawingId,
        localMouseX: players[id].syncState.localMouseX,
        localMouseY: players[id].syncState.localMouseY,
        brushStrokeType: players[id].syncState.brushStrokeType,
        brushColor: players[id].syncState.brushColor,
      }
    },
    {
      // This will have the effect of only re-rendering when the player's selected props changes
      memoizeOptions: {
        resultEqualityCheck: shallowEqual,
      },
    },
  )

  export const makeSelectPlayerModelState = () =>
  createSelector(
    (state: RootState) => state.players.players,
    (_: any, id: string) => id,
    (players, id: string) => {
      return {
        modelX: players[id].syncState.x,
        modelY: players[id].syncState.y,
        iconUrl: players[id].iconUrl,
      }
    },
    {
      // This will have the effect of only re-rendering when the player's selected props changes
      memoizeOptions: {
        resultEqualityCheck: shallowEqual,
      },
    },
  )

export const makeSelectPlayerIds = () =>
  createSelector(
    (state: RootState) => state.players.players,
    (players) => {
      return Object.keys(players).sort(
        (a, b) => players[b].syncState.z - players[a].syncState.z,
      )
    },
    {
      memoizeOptions: {
        resultEqualityCheck: (a: string[], b: string[]) => {
          return a.length === b.length && a.every((v, i) => v === b[i])
        },
      },
    },
  )



export default slice.reducer


// a temp duplicate of shallowEqual to avoid a dependency on reselect/react-redux
function is(x: unknown, y: unknown) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y
  } else {
    return x !== x && y !== y
  }
}
function shallowEqual(objA: any, objB: any) {
  if (is(objA, objB)) return true

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false
  }

  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)

  if (keysA.length !== keysB.length) return false

  for (let i = 0; i < keysA.length; i++) {
    if (
      !Object.prototype.hasOwnProperty.call(objB, keysA[i]) ||
      !is(objA[keysA[i]], objB[keysA[i]])
    ) {
      return false
    }
  }

  return true
}
