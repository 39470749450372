import {
  Id,
  toast,
  ToastOptions,
  ToastPromiseParams,
  TypeOptions,
  UpdateOptions,
} from 'react-toastify';
import { logInfo } from '../logger/logger'

let customToast = {
  showToast: defaultShowToast,
  updateToast: defaultUpdateToast,
  dismissToast: defaultDismiss,
  showErrorToast: defaultErrorToast,
  showLoadingToast: defaultLoadingToast,
};

async function showToast(
  content: string,
  options?: ToastOptions,
): Promise<React.ReactText> {
  logInfo(content)
  return customToast.showToast(content, options);
}

async function updateToast(id: string, options?: UpdateOptions) {
  logInfo(options?.render as string)
  return customToast.updateToast(id, options);
}

async function dismissToast(content: string) {
  return customToast.dismissToast(content);
}

async function showErrorToast(
  content: string,
  options?: ToastOptions,
): Promise<React.ReactText> {
  logInfo(content)
  return customToast.showErrorToast(content, options);
}

async function showLoadingToast(
  content: string,
  options?: ToastOptions,
): Promise<React.ReactText> {
  logInfo(content)
  return customToast.showLoadingToast(content, options);
}

export function isStr(v: any): v is String {
  return typeof v === 'string';
}

async function showToastPromise<T = unknown>(
  promise: Promise<T>,
  { pending, error, success }: ToastPromiseParams,
  options?: ToastOptions,
) {
  let id: string;

  if (pending) {
    if (isStr(pending))
      id = (await showLoadingToast(pending, options)).toString();
  }

  const resetParams = {
    isLoading: null,
    autoClose: null,
    closeOnClick: null,
    closeButton: null,
    draggable: null,
    delay: 100,
  };

  const resolver = async (type: TypeOptions, input: any | undefined, result: T) => {
    // Remove the toast if the input has not been provided. This prevents the toast from hanging
    // in the pending state if a success/error toast has not been provided.
    if (input == null) {
      await dismissToast(id);
      return;
    }

    const baseParams = {
      type,
      ...resetParams,
      ...options,
      // data: result,
    };
    const params = isStr(input) ? { render: input } : input;

    // console.log(id);

    // if the id is set we know that it's an update
    if (id) {
      await updateToast(id, {
        ...baseParams,
        ...params,
      });
    } else {
      // using toast.promise without loading
      await showToast(params.render, {
        ...baseParams,
        ...params,
      } as ToastOptions);
    }

    return result;
  };

  const p = promise;

  //call the resolvers only when needed
  p.then((result) => resolver('success', success, result)).catch((err) =>
    resolver('error', error, err),
  );

  return p;
}

async function defaultShowToast(
  content: string,
  options?: ToastOptions,
): Promise<React.ReactText> {
  return toast(content, options);
}

function defaultUpdateToast(id: string, options?: UpdateOptions) {
  toast.update(id, options);
}

async function defaultDismiss(content: string) {
  return toast.dismiss(content);
}

async function defaultErrorToast(
  content: string,
  options?: ToastOptions,
): Promise<React.ReactText> {
  return toast.error(content, options);
}

async function defaultLoadingToast(
  content: string,
  options?: ToastOptions,
): Promise<React.ReactText> {
  return toast.loading(content, options);
}

export {
  customToast,
  showToast,
  updateToast,
  dismissToast,
  showErrorToast,
  showToastPromise,
};
