import { ResourcePipelineContext } from '../ResourcePipelineContext'
import { Next } from '../../Middleware'
import { cacheRemoteSources, RemoteResourceResponse, updateLocalVersion, updateStatus } from '../../../../store'
import { logMiddleware } from '../util/middlewareLogger'
import { log } from '../../../../logger'

export async function WriteLocalCacheMiddleware(context: ResourcePipelineContext, next: Next): Promise<void> {
  if (context.localCacheVersion) {
    logMiddleware(context,'The files probably read from cache, lets\'t skip this writing local cache')
    return next()
  }

  if (context.bypassCache) {
    logMiddleware(context,'bypassCache')
    return next()
  }

  if (!context.filteredFiles || context.filteredFiles.length === 0)
    throw new Error('No filtered files')

  if (!context.version)
    throw new Error('version is required')

  if (!context.modelType)
    throw new Error('modelType is required')

  const response: RemoteResourceResponse = {
    data: context.filteredFiles,
    targetId: context.targetId,
    version: context.version,
    modelType: context.modelType,
    responseAction: 'none',
  }

  context.localCacheVersion = context.version

  // logMiddleware(context,response);
  
  await cacheRemoteSources(context.targetId, response)
  updateLocalVersion(context.targetId, context.version)
  updateStatus(context.targetId, 'cached')

  return next()
}