import {
  JoinRoomOptions,
  PlayerSyncState, RoomSyncState,
  RPCKeys, RPCParams, RPCProps,
  RPCRequest,
  UpdatePlayerStateProps, UpdateRoomStateProps,
  Vector3,
} from 'playground-data-model'
import { Room } from 'colyseus.js'
import { Player } from 'playground-data-model'
import { MyRoomState } from 'playground-data-model'
import { log } from '../logger/logger'
import { events } from '../amplitude/amplitude'
import { generateKeyPair } from '../util/generateKeyPair'
import { Entity } from '../ecs/core/Entity'
import { handleOnRoomReady } from './handleOnRoomReady'
import { colyseusClient } from './colyseusClient'
import { debugPanelEvent } from '../util/eventBus'
import { appState, reduxStore } from '../store/reduxStore'
import { joinRoomSuccess, leaveRoom, roomActions, updatePasswordProtected } from '../store/reducers/roomSlice'
import { supabase } from '../supabase/client'
import { selectHasLogin } from '../store/reducers/userSlice'
import { playersActions } from '../store/reducers/playersSlice'
import remoteResourcesSlice, { remoteResourcesActions } from '../store/reducers/remote-resources/remoteResourcesSlice'
import { handleModelUploadOnRoomJoin } from '../model-loading'
import { rpc } from 'playground-data-model'
import { mapSessionIdToReduxPlayerId } from './utils/mapSessionIdToReduxPlayerId'
import { tempRoomActions } from '../store'

export function sendRPC<T extends RPCKeys>(eventName: RPCKeys,props: RPCParams<typeof eventName>) {
  console.log('sendRPC', eventName, props)

  if (!colyseusClient.currentRoom?.sessionId) {
    return
  }

  const message: RPCRequest<any> = {
    type: 'everyone',
    payload: props,
  }
  sendRoomEventSafe( "RPC_" + eventName, message)
}

export function patchLocalPlayerStateSelf(props: Partial<PlayerSyncState>) {
  // Sometimes, we might want to apply the value immediately locally
  patchLocalPlayerState('self', props)
}

export function patchLocalPlayerState(target: string, props: Partial<PlayerSyncState>) {
  // Sometimes, we might want to apply the value immediately locally
  reduxStore.dispatch(playersActions.onPlayerBatchUpdate({
    playerId: target,
    changes: Object.entries(props).map(([key, value]) => {
      return {
        changedField: key,
        changedValue: value,
      }
    }),
  }))
}

export function patchRemotePlayerStateSelf(props: Partial<PlayerSyncState>, applyNow: boolean = false) {
  // Sometimes, we might want to apply the value immediately locally
  if (applyNow) {
    patchLocalPlayerStateSelf(props)
  }

  if (!colyseusClient.currentRoom?.sessionId) {
    return
  }

  patchRemotePlayerState(colyseusClient.currentRoom?.sessionId, props)
}

export function patchRemotePlayerState(target: string, props: Partial<PlayerSyncState>, applyNow: boolean = false) {
  if (applyNow) {
    patchLocalPlayerState(mapSessionIdToReduxPlayerId(target), props)
  }

  const message: UpdatePlayerStateProps = {
    target: target,
    props: props,
  }
  sendRoomEventSafe('updatePlayerState', message)
}

export function patchRemoteRoomState(props: Partial<RoomSyncState>) {
    // Sometimes, we might want to apply the value immediately locally
  reduxStore.dispatch(roomActions.onRoomStateUpdate({
    changes: Object.entries(props).map(([key, value]) => {
      return {
        changedField: key,
        changedValue: value,
      }
    }),
  }))

  const message: UpdateRoomStateProps = {
    props: props,
  }
  sendRoomEventSafe('updateRoomState', message)
}

export function sendRoomEventSafe(type: string | number, message?: any) {
  if (colyseusClient.leavingRoom) return

  colyseusClient.currentRoom?.send(type, message)
}

export function updateModelUrl(iv: string, key: JsonWebKey, modelId: string) {
  colyseusClient.modelDecryptKey = { iv, key: JSON.stringify(key) }
  sendRoomEventSafe('updateModelUrl', { modelId: modelId })
  log('send update model url')
}

export async function createRoom(
  id?: string,
  password?: string,
): Promise<Room<MyRoomState> | undefined> {
  try {
    log('Created Room')
    const colyseusRoom = await colyseusClient.client.create<MyRoomState>(
      'my_room',
      await getJoinRoomOptions(password),
    )
    if (password) {
      // colyseusClient.passwordProtected = true;
      reduxStore.dispatch(updatePasswordProtected(true))
    }
    handleRoomSetup(colyseusRoom);
    events('create_room', { room_id: colyseusRoom.id, session_id: colyseusRoom.sessionId });

    return colyseusRoom
  } catch (error) {
    return undefined
  }
}

export async function joinRoom(
  id: string,
  password?: string,
): Promise<Room<MyRoomState> | undefined> {

  const room = await colyseusClient.client.joinById<MyRoomState>(
    id,
    await getJoinRoomOptions(password),
  )
  handleRoomSetup(room)
  // join room ok
  events('join_room', { room_id: room.id, session_id: room.sessionId });

  return room
}

async function getJoinRoomOptions(password?: string): Promise<JoinRoomOptions> {
  const p = colyseusClient.currentEntity
  colyseusClient.keyPair = await generateKeyPair()
  let hasLogin: boolean = selectHasLogin(reduxStore.getState())
  log('getJoinRoomOptions')
  const isDefaultAvatar = reduxStore.getState().remoteResources.list['self']?.localCacheVersion === undefined || reduxStore.getState().remoteResources.list['self']?.localCacheVersion === 0
  log('isDefaultAvatar ' + isDefaultAvatar)

  return {
    transform: {
      position: getDefaultPosition(p),
      scale: p
        ? { x: p.object.scale.x, y: p.object.scale.y, z: 1 }
        : { x: 0.2, y: 0.2, z: 1 },
      angle: p?.object.angle!,
    },
    publicKey: JSON.stringify(colyseusClient.keyPair.publicKeyJwk),
    password: password,
    isDefaultAvatar: isDefaultAvatar && colyseusClient.loadDefaultMode,
    accessToken: hasLogin ? supabase.auth.session()!.access_token : undefined,
    versionBuiltDate: new Date('__DATE__').getTime(),
    sessionID: colyseusClient.currentEntity?.sessionId,

    playerSyncState: {
      mouseInteract: reduxStore.getState().players.players['self'].syncState.mouseInteract
    }
  };
}

export function getDefaultPosition(model?: Entity): Vector3 {
  if (!model) {
    return {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
      z: 0,
    }
  }

  const reduxPlayer = reduxStore.getState().players.players[model.sessionId!]

  return {
    x: reduxPlayer.syncState.x,
    y: reduxPlayer.syncState.y,
    z: 0,
  }
}

async function handleRoomSetup(room: Room<MyRoomState>) {
  if (room.id == null) return false
  log(room.id + '_' + room.sessionId)
  sessionStorage.setItem('roomId', room.id)
  sessionStorage.setItem('sessionId', room.sessionId)
  colyseusClient.leavingRoom = false
  colyseusClient.currentRoom = room
  handleOnRoomReady(room)
  // let jwt = supabase.auth.session()
  // log(jwt)
  // if (jwt) sendRoomEventSafe('userState', { jwt: jwt });
  reduxStore.dispatch(joinRoomSuccess({
    roomId: room.id,
    sessionId: room.sessionId,
  }))

  // Doing this temporary
  patchRemotePlayerStateSelf( { bgColorOverlayEnable: appState().settings.backgroundColorOverlay}, true)

  // console.log(reduxStore);

  // if (colyseusClient.modelFile){
  //   await prepareAvatarUpload(colyseusClient.modelFile);
  // }

  await handleModelUploadOnRoomJoin()

  return true
}

export async function leaveCurrentRoom() {
  if (colyseusClient.currentRoom) {
    sendRoomEventSafe('leaveRoom', { isLeavingRoom: true })
    colyseusClient.leavingRoom = true
    await colyseusClient.currentRoom.leave()
  }
}

export function cleanUpOnRoomLeave() {
  reduxStore.dispatch(leaveRoom())
  // colyseusClient.passwordProtected = false;
  colyseusClient.currentRoom?.state.players.forEach((player, key) => {
    if (key === colyseusClient.currentRoom?.sessionId) {
      cleanUpPendingModelProgress(player, key)
      return
    }
    removePlayerModel(player, key)
  })
  colyseusClient.currentRoom = undefined
  colyseusClient.leavingRoom = false
}

export function removePlayerModel(player: Player, key: string) {
  cleanUpPendingModelProgress(player, key)

  // Cause player will be there all the time, dont need to add it
  if (key !== reduxStore.getState().room.sessionId) {
    reduxStore.dispatch(playersActions.onPlayerLeave(key))

    // Remove the remote avatar resource
    reduxStore.dispatch(
      remoteResourcesActions.cleanUpRemoteResourceRequest({
        targetId: mapSessionIdToReduxPlayerId(key),
      }),
    )
  }

  if (player.data.entity) removeModel(player.data.entity)
  else debugPanelEvent.emit('model-remove', key)
}

export function cleanUpPendingModelProgress(player: Player, key: string) {
  // Ensure the player model request is cancelled
  // console.log('cleanUpPendingModelProgress', key)
  reduxStore.dispatch(
    remoteResourcesActions.cancelRemoteResource({
      targetId: mapSessionIdToReduxPlayerId(key),
    }),
  )

  player.data.uploadModelRequest?.abort()
  player.data.uploadModelRequest = undefined
}

export function removeModel(model: Entity) {
  model.remove()
  debugPanelEvent.emit('model-remove', model.props.playerId)
}
