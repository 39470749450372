import { animateModel } from '../model-loading/loadModel';
import { log } from '../logger/logger';
import { Entity } from '../ecs/core/Entity';
import { colyseusClient, defaultModelUrl } from './colyseusClient'
import { removeModel } from "./multiplayer";
import { handleModelLoadingRemoteModel } from '../model-loading'

export async function setupRemoteAvatar(
  sessionId: string,
  modelUrl: string,
  iv?: string,
  key?: string) {

  if (!modelUrl || modelUrl === '' || modelUrl === 'isDefaultAvatar') {
    modelUrl = defaultModelUrl;
  }

  await handleModelLoadingRemoteModel(modelUrl, sessionId, iv, key);
}

export function setUpEntity(entity: Entity, sessionId: string){
  const player = colyseusClient.currentRoom?.state.players.get(sessionId);

  if (entity != null) {
    log('Model loaded');

    if (!player) {
      log('Player already left, delete avatar');
      removeModel(entity);
      return;
    }
    // Make sure to clean up the existing model
    else if (player!.data.entity) {
      removeModel(player!.data.entity);
    }

    animateModel(entity, true, sessionId);

    entity.object.x = player!.x;
    entity.object.y = player!.y;
    entity.object.zIndex = player!.z;
    entity.object.scale.set(player!.scaleX, player!.scaleY);
    entity.object.angle = player!.angle;

    player!.data.entity = entity;
  } else {
    log('Unable to load model ');
  }
}