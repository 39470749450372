import {DefaultModelFallbackMiddleware} from './io/DefaultModelFallbackMiddleware'
import { ReadLocalCacheMiddleware } from './io/ReadLocalCacheMiddleware'
import { WriteLocalCacheMiddleware } from './io/WriteLocalCacheMiddleware'
import { CompressionMiddleware } from './modifier/CompressionMiddleware'
import { DecryptionMiddleware, EncryptionMiddleware } from './modifier/CryptoMiddleware'
import { DownloadResourceMiddleware } from './network/DownloadResourceMiddleware'
import { RemoteResourceWrapperMiddleware } from './RemoteResourceWrapperMiddleware'
import { NotifyRoomNewModelUploadedMiddleware } from './network/NotifyRoomNewModelUploadedMiddleware'
import { ReadRemoteCacheMiddleware } from './network/ReadRemoteCacheMiddleware'
import { RemoteResourceUploadUrlLocator_Anon } from './network/RemoteResourceUploadUrlLocator_Anon'
import { UploadResourceMiddleware } from './network/UploadResourceMiddleware'
import { WriteRemoteCacheMiddleware } from './network/WriteRemoteCacheMiddleware'
import { LoaderMiddleware } from './resource-loader/LoaderMiddleware'
import { SkipModelLoadingModel } from './network/SkipModelLoadingModel'
import { InRoomModelHandlingMiddleware } from './network/InRoomModelHandlingMiddleware'

export const ModelLoadingMiddleware = {
  RemoteResourceWrapperMiddleware,

  DefaultModelFallbackMiddleware,
  ReadLocalCacheMiddleware,
  WriteLocalCacheMiddleware,

  InRoomModelHandlingMiddleware,
  CompressionMiddleware,
  DecryptionMiddleware,
  EncryptionMiddleware,

  DownloadResourceMiddleware,
  NotifyRoomNewModelUploadedMiddleware,
  ReadRemoteCacheMiddleware,

  RemoteResourceUploadUrlLocator_Anon,
  UploadResourceMiddleware,
  WriteRemoteCacheMiddleware,
  LoaderMiddleware,

  SkipModelLoadingModel
}
