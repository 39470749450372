import {
  ConsentOption, ModelData, ModelDecryptKey, OnPlayerAvatarUrlReadyOption, PlayerSyncState,
  RequestE2EEModelKeyOption,
  SendE2EEModelKeyOption,
} from 'playground-data-model'
import { setModelLayer } from '../model-loading/loadModel'
import { Room } from 'colyseus.js'
import { Player } from 'playground-data-model'
import { MyRoomState } from 'playground-data-model'
import { log } from '../logger/logger'
import { debugPanelEvent } from '../util/eventBus'
import {
  decryptText,
  deriveKey,
  encryptText,
} from '../util/generateKeyPair'
import { setUpEntity, setupRemoteAvatar } from './setupRemoteAvatar'
import { colyseusClient, defaultModelUrl } from './colyseusClient'
import {
  sendRoomEventSafe,
  cleanUpOnRoomLeave,
  removePlayerModel,
  joinRoom,
  patchLocalPlayerStateSelf,
} from './multiplayer'
import { events, getDevice } from '../amplitude/amplitude'
import { showToast } from '../toast/showToast'
import { reconnect } from './reconnect'
import { reduxStore } from '../store/reduxStore'
import { onRoomStateUpdate, RoomState, selectIsHost, updateRoomUserCount } from '../store/reducers/roomSlice'
import { playersActions } from '../store/reducers/playersSlice'
import { updatePlayerPosition } from './update/updatePlayerPosition'
import { fetchSupabase, lowFpsTicker, mapSessionIdToReduxPlayerId, openReplaySessionId, settingsActions, supabase, updateStatus } from '..'
import { justInitRemoteResource } from '../model-loading/middleware/impl/RemoteResourceWrapperMiddleware'
import { growthbook } from '../../utils/GrowthBook'

let reconnecting: boolean = false

type PlayerSyncStateKey = keyof PlayerSyncState
const PlayerSyncStateSelfIgnoreNotHost: PlayerSyncStateKey[] = [
  'x', 'y', 'scaleX', 'scaleY', 'angle',
]

const PlayerSyncStateSelfIgnore: PlayerSyncStateKey[] = [
  'mouseX', 'mouseY',
  'mousePressure', 'selectingPlayerId', 'localMouseX', 'localMouseY',
]
const PlayerSyncStateAllIgnore: PlayerSyncStateKey[] = [
  'blendshapes'
]
const PlayerSyncStateMouseInteract: PlayerSyncStateKey[] = [
  'mouseX', 'mouseY',
]

export function handleOnRoomReady(room: Room<MyRoomState>, fromReconnect: boolean = false) {
  updatePlayerPosition(
    colyseusClient!.currentEntity,
    room.sessionId,
  )
  log(colyseusClient.allEntities)
  room.onMessage(
    'requestE2EEModelKey',
    async (options: RequestE2EEModelKeyOption) => {
      const t = showToast('Generating E2EE model key')
      log('Encrypting this')
      log(colyseusClient.modelDecryptKey)
      const encryptedMessage = await encryptText(
        JSON.stringify(colyseusClient.modelDecryptKey),
        await deriveKey(
          JSON.parse(options.publicKey) as JsonWebKey,
          colyseusClient.keyPair!.privateKeyJwk,
        ),
      )

      const data: SendE2EEModelKeyOption = {
        encryptedMessage,
        publicKey: JSON.stringify(colyseusClient.keyPair!.publicKeyJwk),
        sessionId: options.sessionId,
      }
      log(data)
      sendRoomEventSafe('sendE2EEModelKey_' + colyseusClient.currentRoom?.sessionId + '_' + options.sessionId, data)
    },
  )

  // sessionId = other player id
  room.onMessage(
    'onPlayerAvatarUrlReady',
    async (data: OnPlayerAvatarUrlReadyOption) => {
      log('onPlayerAvatarUrlReady [New]')
      log(data)

      const message = await decryptText(
        JSON.stringify(data.e2eeOptions.encryptedMessage),
        await deriveKey(
          JSON.parse(data.e2eeOptions.publicKey) as JsonWebKey,
          colyseusClient.keyPair!.privateKeyJwk,
        ),
      )

      log(message)

      const decryptKey = JSON.parse(message) as ModelDecryptKey

      await setupRemoteAvatar(
        data.sessionId,
        data.modelUrl,
        decryptKey.iv,
        decryptKey.key,
      )
    },
  )

  room.onMessage('requestConsent', async (options: ConsentOption) => {
    debugPanelEvent.emit('request-consent', options)
  })

  room.onError((code, message) => {
    log(code)
    log(message)
    if (message)
      showToast(message)
  })

  room.onLeave(async (code) => {
    log('Room on leave')
    log(code)

    events('on_leave_room', {
      code: code,
    })

    if (code === 1000) {
      showToast('Disconnected from server')
    } else if (code >= 1001 && code <= 1015 && code !== 1006) {
      showToast('Disconnected from server abnormally, try rejoining.')
    } else if (code >= 4000) {
      showToast('Disconnected from server, sth might went wrong')
    } else {
      colyseusClient.PIXI.Ticker.shared.stop()
      lowFpsTicker.stop()
      try {
        reconnecting = true
        await reconnect(room)
      } catch (error) {
        cleanUpRoom()
      }
      lowFpsTicker.start()
      colyseusClient.PIXI.Ticker.shared.start()
      return
    }
    if (growthbook.isOn("player_state"))
      await playerStateOnQuit(room)

    cleanUpRoom()
  })

  room.state.onChange = async (changes) => {
    const confirmedChanges = changes
      .filter(
        (change) => {
          if (change.field === 'players')
            return false
          return true
        }
      )
      .map((change) => {
        return {
          changedField: change.field,
          changedValue: change.value,
        }
      })

    if (confirmedChanges && confirmedChanges.length > 0)
      reduxStore.dispatch(
        onRoomStateUpdate({
          changes: confirmedChanges,
        }),
      )
  }

  room.state.players.onRemove = (player: Player, key: string) => {
    events('player_length_quit', { player_length: room.state.players.size, player_id: key })

    // debugPanelEvent.emit('player-left', key);
    reduxStore.dispatch(updateRoomUserCount(colyseusClient.currentRoom?.state.players.size || 0))

    removePlayerModel(player, key)
    if (!player.data.entity) {
      debugPanelEvent.emit('model-remove', key)
    }
    showToast(key + ' left the room')
  }

  room.state.players.onAdd = async (player: Player, key: string) => {
    log('onAdd')
    log(key)
    log(room.sessionId)
    if (key !== room.sessionId) {
      showToast('A new player ' + key + ' joined the room')
      // let hasLogin: boolean = selectHasLogin(reduxStore.getState())
      // if (hasLogin)
      //   sendRoomEventSafe('sendModelData', {
      //     sessionId: key,
      //     userId: supabase.auth.user()!.id,
      //     modelName: 'Model',
      //   })
    }
    // debugPanelEvent.emit('player-joined', key);
    reduxStore.dispatch(updateRoomUserCount(colyseusClient.currentRoom?.state.players.size || 0))
    if (growthbook.isOn("player_state"))
      await playerStateOnJoin(key, room)
    // Cause player will be there all the time, dont need to add it
    if (!fromReconnect) {
      if (key !== reduxStore.getState().room.sessionId)
        reduxStore.dispatch(playersActions.onPlayerJoin({
          key: key,
        }))
    }

    player!.data = {}
    if (key === room.sessionId) {
      const player = room.state.players.get(key)
      player!.data.entity = colyseusClient.currentEntity
    } else {
      colyseusClient.allEntities?.map((entity) => {
        if (entity.props.playerId === key)
          player!.data.entity = entity
      })
      // if (!reconnecting) {
      //   colyseusClient.avatarIcons?.push({ entity: undefined, url: '', playerId: key, color: 'white-outline' })
      // }
      // debugPanelEvent.emit('layer-update', true)
      if (!fromReconnect) {
        if (player.isDefaultAvatar)
          setupRemoteAvatar(key, defaultModelUrl)
        else {
          justInitRemoteResource(key)
          updateStatus(key, 'upload-pending')
        }
      }
    }
    events('player_length_join',
      {
        player_length: room.state.players.size,
        player_id: key,
        is_self: key == room.sessionId,
        room_id: room.id,
        open_replay_session: openReplaySessionId
      })

    log(player, 'has been added at', key)

    // If you want to track changes on a child object inside a map, this is a common pattern:
    player.onChange = function (changes) {
      let player = room.state.players.get(key)!

      const mappedKey = mapSessionIdToReduxPlayerId(key)

      const confirmedChanges = changes
        .filter(
          (change) => {
            if (change.field === 'z') {
              if (player.data.entity !== undefined) {
                setModelLayer(player.data.entity.object!, change.value)
              }
            }

            if (PlayerSyncStateAllIgnore.includes(change.field as PlayerSyncStateKey)) {
              return false
            }

            // To prevent conflicting data applies from server for local position
            if (mappedKey === 'self') {
              if (growthbook.isOn('host_override_position')) {
                // Separate override logic
                if (PlayerSyncStateSelfIgnore.includes(change.field as PlayerSyncStateKey)) {
                  return false
                }
                // When we are not host, we should still accept incoming data override
                // If self selector is also self, ignore incoming data
                if (
                  (selectIsHost(reduxStore.getState()) || reduxStore.getState().players.players['self'].syncState.selectorId === reduxStore.getState().room.sessionId)
                  &&
                  PlayerSyncStateSelfIgnoreNotHost.includes(change.field as PlayerSyncStateKey)
                ) {
                  return false
                }
              } else {
                if (PlayerSyncStateSelfIgnore.includes(change.field as PlayerSyncStateKey)
                  || PlayerSyncStateSelfIgnoreNotHost.includes(change.field as PlayerSyncStateKey)) {
                  return false
                }
              }
            } else if (reduxStore.getState().players.players[mappedKey].syncState.selectorId === reduxStore.getState().room.sessionId &&
              PlayerSyncStateSelfIgnoreNotHost.includes(change.field as PlayerSyncStateKey)) {
              return false
            }

            // We skip updating any remote player data if we don't sync object transform
            if (!reduxStore.getState().settings.syncObjectTransform && !PlayerSyncStateMouseInteract.includes(change.field as PlayerSyncStateKey)) return false

            return true
          }
        )
        .map((change) => {
          return {
            changedField: change.field,
            changedValue: change.value,
          }
        })

      if (confirmedChanges && confirmedChanges.length > 0)
        reduxStore.dispatch(playersActions.onPlayerBatchUpdate({
          playerId: mappedKey,
          changes: confirmedChanges,
        }))
    }
    player.triggerAll()
    log('trigger all')
  }
}

async function playerStateOnQuit(room: Room<MyRoomState>) {
  await fetchSupabase(
    supabase.from('player_state').insert(
      {
        user_id: supabase.auth.user()?.id ?? getDevice(),
        player_state: 'player_length_quit',
        player_name: supabase.auth.user()?.user_metadata.name ?? 'anonymous',
        room_id: room.id,
        state_info: {
          player_length: room.state.players.size,
          open_replay_session: openReplaySessionId,
        },
      }
    )
  )
}

async function playerStateOnJoin(key: string, room: Room<MyRoomState>) {
  if (key !== room.sessionId) return
  await fetchSupabase(
    supabase.from('player_state').insert(
      {
        user_id: supabase.auth.user()?.id ?? getDevice(),
        player_state: 'player_length_join',
        player_name: supabase.auth.user()?.user_metadata.name ?? 'anonymous',
        session_id: key,
        room_id: room.id,
        state_info: {
          player_length: room.state.players.size,
          open_replay_session: openReplaySessionId,
          is_self: key == room.sessionId,
        },
      }
    )
  )
  sendRoomEventSafe('playerState')
}

function cleanUpRoom() {
  const dispatch = reduxStore.dispatch
  patchLocalPlayerStateSelf({
    playerNumber: 1
  })
  cleanUpOnRoomLeave()
  dispatch(settingsActions.setMainMenuGUIState(true))
  debugPanelEvent.emit('update-room-status', {
    roomId: undefined,
  })
}

