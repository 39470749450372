import { reduxStore } from '../../store/reduxStore'
import { playersActions } from '../../store/reducers/playersSlice'
import { settingsActions } from '../../store/reducers/settingsSlice'
import { colyseusClient } from '../colyseusClient'
import { sendRoomEventSafe } from '../multiplayer'

export function updateLocalPlayerPosition(playerId: string, pointX: number, pointY: number) {
  reduxStore.dispatch(
    playersActions.onPlayerUpdate(
      {
        playerId: playerId,
        changedField: 'x',
        changedValue: pointX,
      },
    ),
  )

  reduxStore.dispatch(
    playersActions.onPlayerUpdate(
      {
        playerId: playerId,
        changedField: 'y',
        changedValue: pointY,
      },
    ),
  )

  reduxStore.dispatch(
    settingsActions.setPlayerLocalStatePosition({
      x: pointX,
      y: pointY,
      z: 1,
    }),
  )
}

export function updateLocalPlayerScale(playerId: string, x: number, y: number) {
  reduxStore.dispatch(
    playersActions.onPlayerUpdate(
      {
        playerId: playerId,
        changedField: 'scaleX',
        changedValue: x,
      },
    ),
  )

  reduxStore.dispatch(
    playersActions.onPlayerUpdate(
      {
        playerId: playerId,
        changedField: 'scaleY',
        changedValue: y,
      },
    ),
  )

  reduxStore.dispatch(
    settingsActions.setPlayerLocalStateScale({
      x: x,
      y: y,
      z: 1,
    }),
  )
}

export function updateLocalPlayerAngle(playerId: string, angle: number){
  reduxStore.dispatch(
    playersActions.onPlayerUpdate(
      {
        playerId: playerId,
        changedField: 'angle',
        changedValue: angle,
      },
    )
  )

  reduxStore.dispatch(
    settingsActions.setPlayerLocalStateAngle(angle)
  )
}

export function reSyncLocalPlayerState(playerId: string) {
  const player = colyseusClient.currentRoom?.state.players.get(playerId);

  if (!player) return

  player.triggerAll()
}

export function reSyncAllLocalPlayerState() {
  const players = colyseusClient.currentRoom?.state.players;

  if (!players) return

  players.forEach((player, key) => {
    player.triggerAll()
  })

}


export function updateRemotePlayerScale(x: number, y: number) {
  sendRoomEventSafe('updateScale', {
    x: x,
    y: y,
    target: colyseusClient.currentRoom?.sessionId
  });
}