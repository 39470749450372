// Creating a new cache only for the model cache
import localforage from 'localforage'
import { RemoteResourceResponse } from './dataModel'

const storeName = 'playground-blob-cache'
localforage.config({
  name: 'Playground',
  version: 1,
  storeName: storeName, // Should be alphanumeric, with underscores.
})

export const blobStore = localforage.createInstance({
  name: storeName,
})

export async function cacheRemoteSources(targetId: string, response: RemoteResourceResponse) {
  await blobStore.setItem(targetId, response)
}

export async function getCachedRemoteSource(targetId: string): Promise<RemoteResourceResponse | null> {
  return await blobStore.getItem<RemoteResourceResponse>(targetId)
}