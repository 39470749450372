import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Player, PlayerSyncState } from 'playground-data-model'
import {
  colyseusClient,
  mapReduxPlayerIdToSessionId,
  playersActions,
  reduxStore,
  RootState,
  selectRoomId,
} from 'playground-core'

export type SceneEntity = Partial<PlayerSyncState> & {
  id: number,
}

export type Scene = {
  name: string;
  connectedObsSceneName?: string;
  entities: Record<number, SceneEntity>;
}

export interface SceneState {
  sceneStates: Record<string, Scene>;
  currentObsSceneName?: string;
}

const initialState: SceneState = {
  sceneStates: {}
};

const slice = createSlice({
  name: "sceneSnapshot",
  initialState,
  reducers: {
    updateSceneState(
      state,
      action: PayloadAction<Scene>
    ) {
      state.sceneStates[action.payload.name] = {
        ...state.sceneStates[action.payload.name],
        ...action.payload
      };
    },

    setCurrentObsSceneName(
      state,
      action: PayloadAction<string>
    ) {
      state.currentObsSceneName = action.payload;
    },

    removeSceneState(
      state,
      action: PayloadAction<string>
    ) {
      delete state.sceneStates[action.payload];
    },

    updateConnectedObsSceneName(
      state,
      action: PayloadAction<{
        targetSceneName: string;
        sceneName: string;
      }>
    ) {
      state.sceneStates[action.payload.targetSceneName].connectedObsSceneName = action.payload.sceneName;
    }
  },
})

export const sceneActions = slice.actions;

export const sceneSelector = {
  selectCurrentObsSceneName: (state: RootState) => state.sceneStates.currentObsSceneName,
  selectAllSceneState: (state: RootState) => state.sceneStates.sceneStates,
}

export default slice.reducer;


export function playerToSyncState(player: Player): Partial<PlayerSyncState> {
  return {
    x: player.x,
    y: player.y,
    z: player.z,

    scaleY: player.scaleY,
    scaleX: player.scaleX,

    angle: player.angle,

    playerNumber: player.playerNumber,
  }
}

export function syncStateToSceneEntity(syncState: Partial<PlayerSyncState>): SceneEntity {
  return {
    id: syncState.playerNumber!,
    ...syncState,
  }
}

function getCurrentEntitiesToSceneState() {
  const players = colyseusClient.currentRoom?.state?.players

  if (!selectRoomId(reduxStore.getState()) || !players) {
    return [
      syncStateToSceneEntity(reduxStore.getState().players.players['self'].syncState)
    ]
  }

  let state = []
  for (let [key, player] of players) {
    state.push(syncStateToSceneEntity(playerToSyncState(player)))
  }

  state = state.sort((a, b) => {
    if (a.playerNumber! < b.playerNumber!)
      return -1
    else if (a.playerNumber! > b.playerNumber!)
      return 1
    else return 0
  })

  return state
}

function toRecord<T extends Record<string, any>, K extends keyof T>(array: T[], selector: K): Record<T[K], T> {
  return array.reduce((acc, item) => ({ ...acc, [item[selector]]: item }), {} as Record<T[K], T>)
}

export function updateSceneSnapshot(name: string) {
  reduxStore.dispatch(
    sceneActions.updateSceneState(
      {
        name: name,
        entities: toRecord(getCurrentEntitiesToSceneState(), 'playerNumber'),
      }
    )
  )
}

function getPlayerIdFromPlayerNumber(playerNumber: number) {
  const players = Object.entries(reduxStore.getState().players.players)
  let playerKey = 'self'
  for (let [key, player] of players) {
    if (player.syncState.playerNumber === playerNumber) {
      playerKey = key
      break
    }
  }
  return playerKey
}

export function applySceneState(scene: Scene) {
  const e = Object.entries(scene.entities)
  const c = e.map(([playerNumber,entity]) => ({
    playerId: getPlayerIdFromPlayerNumber(Number.parseInt(playerNumber)),
    changes: Object.entries(entity).map(([key, value]) => {
      return {
        changedField: key,
        changedValue: value,
      }
    }),
  }))

  reduxStore.dispatch(playersActions.onPlayersBatchUpdate(c))
}

