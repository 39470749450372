import { createContext, HTMLAttributes, ReactNode, useContext } from 'react'

export type EventContextProps = {
  children: ReactNode
  eventType: string
} & HTMLAttributes<HTMLDivElement>

export const EventContext = createContext('')

export function ContextProvider(props: EventContextProps) {
  const value = useContext(EventContext)
  const { eventType, children } = props
  return (
    <EventContext.Provider value={`${value}_${eventType}`}>
      {children}
    </EventContext.Provider>
  )
}
