import { reduxStore } from '../../../reduxStore'
import { remoteResourcesActions } from '../remoteResourcesSlice'

export function updateStatusProgress(targetId: string, progress: number) {
  // Mark resource finish loading
  reduxStore.dispatch(remoteResourcesActions.updateRemoteResourceProgress(
    {
      targetId: targetId,
      progress: progress,
    },
  ))
}