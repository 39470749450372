import './style.css'
import { App } from './app'
import ReactDOM from 'react-dom'
import React from 'react'

// import ReloadPrompt from './components/reload-prompt/ReloadPrompt';
// import { registerSW } from "virtual:pwa-register";
//
// if ("serviceWorker" in navigator) {
//   // && !/localhost/.test(window.location)) {
//   registerSW();
// }

// Removing all registered service workers to ensure the previous pwa don't mess up with the new version
if ('serviceWorker' in navigator) {
  try {
    navigator.serviceWorker.getRegistrations().then(async function(registrations) {
      try {
        let hasSW = registrations.length > 0;
        for (let registration of registrations) {
          await registration.unregister()
        }
        if (hasSW)
          window.location.reload()
      } catch (e) {
        console.error(e)
      }
    })
  } catch (e) {
    console.error(e)
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
    {/*<ReloadPrompt />*/}
  </React.StrictMode>,
  document.getElementById('app'),
)

