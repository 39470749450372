import { getTranslation } from '../../../i18n/utils/getTranslation'
import {
  colyseusClient,
  debugPanelEvent,
  joinRoom,
  log,
  reduxStore,
  settingsActions,
  showToast,
  updatePasswordProtected,
} from 'playground-core'
import { IntlShape, useIntl } from 'react-intl'
import { useAppDispatch } from '../../../hook/hooks'
import { showPromptInput } from '../../dialog/PromptDialog'
import { showDialog } from '../../utils/dialogUtils'
import { leaveRoomWithPrompt } from './JoinRoom'
import { Translation } from '../../../i18n/utils/Translation'

export async function joiningRoom(
  text: string,
  value?: string,
  setLoading?: (loading: boolean) => void,
  password?: string,
  intl?: IntlShape,
  cancel?: boolean
) {
  // let dispatch = useAppDispatch()
  if (cancel){
    setLoading?.(false)
  return}
  if (!text || text === '') {
    showToast("Room id can't be empty!")
    return
  }
  if (colyseusClient.currentRoom && colyseusClient.currentRoom.id === text) {
    showToast('You already in this room!')
    return
  }
  await leaveRoomWithPrompt()
  setLoading?.(true)
  // console.log(text);

  try {
    await joinRoom(text, password)
    console.log(password)
    if (password) {
      reduxStore.dispatch(updatePasswordProtected(true))
    }
    reduxStore.dispatch(settingsActions.setMainMenuGUIState(false))
  } catch (error: any) {
    log(JSON.stringify(error.code))
    // Error code 4212 seems like is room not found error
    if (error.code === 4212) {
      showToast('Failed to join room!')
    } else if (error.code == 401) {
      // Auth failed
      console.log(password)
      if (!password) {
        // Attempt to join room with password
        const password = await showPromptInput({
          eventType: `${value}_with_password`,
          // hideCancel: true,
          message: 'Please enter the room password',
          title: <Translation id='join_room_with_password_title' />,
          customStyle: 'flex justify-center items-center',
          checkboxGap: 'justify-start',
          // hideConfirm: true,
          confirmText: 'Join',
          hideBlackBlock: true,
        })
        if(password == null)
        joiningRoom(text, `${value}_with_password`, setLoading, '', intl!, true)
        else joiningRoom(text, `${value}_with_password`, setLoading, password, intl!)
        return
      }
      
    }
    else if (error.code == 400) {

        
        const password = await showPromptInput({
          eventType: `${value}_with_password`,
          // hideCancel: true,
          message: 'Please enter the room password',
          title: getTranslation(intl!, 'join_room_with_password_title'),
          customStyle: 'flex justify-center items-center ',
          //TODO: language
          warningText: 'Wrong Password!',
          warningStyle: 'text-[#FF6D1B] text-sm',
          // hideConfirm: true,
          confirmText: 'Join',
          hideBlackBlock: true,
       
        }, '')
        if(password == null) 
        joiningRoom(text, `${value}_with_password`, setLoading, '', intl!, true)
          
          else joiningRoom(text, `${value}_with_password`, setLoading, password, intl!,)
          return
      
      
    }
    else if(error.code == 487){ // version mismatch
      showDialog({
        eventType: 'version_error',
        title: 'Version mismatch',
        message:
          'Seems like the room that you want to join is using a older version, please tell room host to update by clearing cache!',
        hideConfirm: true,
        hideOnTapOutside: true,
      })
    } else if (error.code == 488) {
      // version mismatch
      showDialog({
        eventType: 'version_error',
        title: 'Version mismatch',
        message:
          'Seems like the room that you want to join is using a newer version, please update by clearing cache!',
        hideConfirm: true,
        hideOnTapOutside: true,
      })
    } 

    else {
      showToast('Failed to join room! ' + error + " " + error.code)
    }
  }

  debugPanelEvent.emit('update-room-status', {
    roomId: colyseusClient.currentRoom?.id,
  })
  // log('Joined room!');
  setLoading?.(false)
}
