import EventEmitter from 'eventemitter3';

type DebugEvents =
  | 'toggle-physics-debug'
  | 'toggle-physics-debug-drag'
  | 'toggle-physics'
  | 'set-spline-url'
  | 'toggle-spline'
  | 'toggle-debug-panel';

type Events =
  | DebugEvents
  | 'player-left'
  | 'player-joined'
  | 'show-help'
  | 'update-room-status'
  | 'show-prompt'
  | 'models-updated'
  | 'update-player-model'
  | 'request-consent'
  | 'close-prompt'
  | 'layer-update'
  | 'model-remove'
  | 'obs-status'
  | 'on-load-cached-model'
  | 'refresh_vts_connect_state'
  ;

export const debugPanelEvent = new EventEmitter<Events>();
