import {
  mapReduxPlayerIdToSessionId,
  mapSessionIdToReduxPlayerId,
  patchRemotePlayerState,
  reduxStore,
} from '../../../core'

export function isTargetPlayerOnLock(id: string) {
  if (reduxStore.getState().players.players[mapSessionIdToReduxPlayerId(id)].syncState.selectorId) return true
  return false
}

export function updateTargetPlayerSelectorId(id: string) {
  // Update the selector ID

  if (isTargetPlayerOnLock(id)) return

  // If the selector ID is the same as the player ID, we don't need to update it
  if (reduxStore.getState().players.players[mapSessionIdToReduxPlayerId(id)].syncState.selectorId === reduxStore.getState().room.sessionId!) return

  // console.log(id)
  if (reduxStore.getState().room.sessionId)
    patchRemotePlayerState(
      mapReduxPlayerIdToSessionId(id), {
      selectorId: reduxStore.getState().room.sessionId!,
    }, true)
}

export function clearTargetPlayerSelectorId(id: string) {
  if (reduxStore.getState().players.players[mapSessionIdToReduxPlayerId(id)].syncState.selectorId !== reduxStore.getState().room.sessionId!) return
  // console.log(id)

  patchRemotePlayerState(mapReduxPlayerIdToSessionId(id), {
    selectorId: undefined,
  }, true)
}