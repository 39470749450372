import { ResourcePipelineContext } from '../ResourcePipelineContext'
import { Next } from '../../Middleware'
import { sendRoomEventSafe } from '../../../../colyseus'
import { logMiddleware } from '../util/middlewareLogger'

export async function NotifyRoomNewModelUploadedMiddleware(context: ResourcePipelineContext, next: Next): Promise<void> {
  if (!context.modelId) throw new Error('modelId is required')
  logMiddleware(context,'Notifying updateModelUrl' + context.modelId)
  sendRoomEventSafe('updateModelUrl', { modelId: context.modelId })
  return next()
}