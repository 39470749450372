import { remoteResourcesActions, RemoteResourceStatus } from '../remoteResourcesSlice'
import { reduxStore } from '../../../reduxStore'
import { logInfo } from '../../../../logger'

export function updateStatus(targetId: string, status: RemoteResourceStatus, description?: string) {
  // Mark resource finish loading
  if (status == 'error')
    logInfo('Resources error: ' + targetId + ' ' + (description ? description : ''))
  reduxStore.dispatch(remoteResourcesActions.updateRemoteResourceStatus(
    {
      targetId: targetId,
      status: status,
      description: description,
    },
  ))
}