import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../reduxStore";
import { Vector3 } from 'playground-data-model'

export interface TempSettingsState {
  hideCurrentEntity: boolean;
}

// Define the initial state using that type
const initialState: TempSettingsState = {
  hideCurrentEntity: false,
};

const slice = createSlice({
  name: "tempSettings",
  initialState,
  reducers: {
    setHideCurrentEntity(state, action: PayloadAction<boolean>) {
      state.hideCurrentEntity = action.payload;
    }
  },
});

export const tempSettingsActions = slice.actions;
export const selectHideCurrentEntity = (state: RootState) => state.tempSettings.hideCurrentEntity;

export default slice.reducer;
