import { ResourcePipelineContext } from '../ResourcePipelineContext'
import { Next } from '../../Middleware'
import { sendRoomEventSafe } from '../../../../colyseus'
import { globalBlobQueue } from '../../../../store'
import { handleFileOperation } from '../util/HandleFileOperation'
import { log } from '../../../../logger'
import { logMiddleware } from '../util/middlewareLogger'

export async function UploadResourceMiddleware(context: ResourcePipelineContext, next: Next): Promise<void> {
  logMiddleware(context,'UploadResourceMiddleware')
  if (!context.modelUploadUrl) throw new Error('modelUploadUrl is required')
  if (!context.modelId) throw new Error('modelId is required')
  if (!context.encryptedFile) throw new Error('encryptedFile is required')

  // logMiddleware(context)

  await handleFileOperation(context.targetId, context.modelUploadUrl, 'PUT',
    (req) => {
      globalBlobQueue[context.targetId].request = req
      req.send(context.encryptedFile)
    },
    true,
  )

  return next()
}

