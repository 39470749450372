import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../reduxStore';

export interface color{
  color: {
    B: number | null;
    G: number | null;
    R: number | null;
  };
}

export interface colorOverlaySliceState extends color {
  overlay: number | null;
  brightness: number | null;
}

// Define the initial state using that type
const initialState: colorOverlaySliceState = {
  color: { B: null, G: null, R: null },
  overlay: null,
  brightness: null,
};

const slice = createSlice({
  name: 'colorOverlay',
  initialState,
  reducers: {
    setColor(state, action: PayloadAction<color>) {
      state.color = action.payload.color;
    },
    setOverlay(state, action: PayloadAction<number | null>) {
      state.overlay = action.payload;
    },
    setBrightness(state, action: PayloadAction<number | null>) {
      state.brightness = action.payload;
    }
  },
});

export const colorAction = slice.actions;
export const selectColor = (state: RootState) => state.colorOverlay.color;
export const selectOverlay = (state: RootState) => state.colorOverlay.overlay;
export const selectBrightness = (state: RootState) => state.colorOverlay.brightness;

export default slice.reducer;
