import { ResourcePipelineContext } from '../ResourcePipelineContext'
import { pipelineAction, reduxStore } from '../../../../store'
import { logMiddlewareTimeEnd, logMiddlewareTimeStart, logTimeEnd, logTimeStart } from '../../../../logger'

function cloneObject<T>(obj: T) {
  return JSON.parse(JSON.stringify(obj))
}

function filterFilesArray<T, K extends keyof T>(obj: T, targetObject: T, field: K) {
  if (obj[field]) {
    // @ts-ignore
    targetObject[field] = 'REDACTED, there are ' + obj[field].length + ' files'
  }
}

function logMiddlewareContext<T extends ResourcePipelineContext>(context: T) {

  // TODO finds a better to remove sensitive data from context
  const sanitisedContext = cloneObject(context)

  if (context.rawModelKey && context.rawModelKey.length > 0) {
    // redacted rawModelKey
    sanitisedContext.rawModelKey = 'REDACTED'
  }

  if (context.decryptKey) {
    // redacted decryptKey
    sanitisedContext.decryptKey.key = 'REDACTED'
    sanitisedContext.decryptKey.iv = 'REDACTED'
  }

  filterFilesArray(context, sanitisedContext, 'inputFiles')
  filterFilesArray(context, sanitisedContext, 'filteredFiles')

  if (context.compressedFile) {
    // @ts-ignore
    sanitisedContext.compressedFile = 'REDACTED'
  }

  if (context.encryptedFile) {
    // @ts-ignore
    sanitisedContext.encryptedFile = 'REDACTED'
  }

  if (context.modelDownloadUrl) {
    // @ts-ignore
    sanitisedContext.modelDownloadUrl = 'REDACTED'
  }

  if (context.modelUploadUrl) {
    // @ts-ignore
    sanitisedContext.modelUploadUrl = 'REDACTED'
  }

  logMiddleware(context, sanitisedContext)
}

export function logMiddlewareStart<T extends ResourcePipelineContext>(context: T, middlewareId: string) {
  if (!context.pipelineId) return
  if (!context.pipelineNameCurrentMWId) return

  reduxStore.dispatch(
    pipelineAction.updateMiddlewareStatus({
      pipelineId: context.pipelineId,
      middlewareId: context.pipelineNameCurrentMWId,
      status: 'loading',
    }),
  )
  logMiddlewareTimeStart('Middleware : ' + middlewareId)
  logMiddlewareContext(context)
}

export function logMiddlewareEnd<T extends ResourcePipelineContext>(context: T, middlewareId: string) {
  if (!context.pipelineId) return
  if (!context.pipelineNameCurrentMWId) return

  if (reduxStore.getState()
    .pipeline.pipelines.find(p => p.id === context.pipelineId)!
    .middleware.find(m => m.id === context.pipelineNameCurrentMWId)!
    .status === 'loading'
  )
  {
    reduxStore.dispatch(
      pipelineAction.updateMiddlewareStatus({
        pipelineId: context.pipelineId!,
        middlewareId: context.pipelineNameCurrentMWId!,
        status: 'success',
      }),
    )
    let n = logMiddlewareTimeEnd('Middleware : ' + middlewareId)
    logMiddleware(context, context.targetId + ' this session time used : ' + n.toFixed(2) + 'ms')
    logMiddlewareContext(context)
  }
}

/**
 * Log the middleware context to the redux store + debug graph log, make sure there will not be any sensitive data
 * @param context
 * @param args
 */
export function logMiddleware<T extends ResourcePipelineContext>(context: T, args: any) {
  if (!context.pipelineId) return
  if (!context.pipelineNameCurrentMWId) return

  reduxStore.dispatch(
    pipelineAction.logMiddleware({
      pipelineId: context.pipelineId,
      middlewareId: context.pipelineNameCurrentMWId,
      log: (args !== undefined && args !== null) ? JSON.parse(JSON.stringify(args)) : args,
    }),
  )
}
