import { animateModel } from './loadModel'
import { Entity } from '../ecs/core/Entity'
import { colyseusClient } from '../colyseus/colyseusClient'
import { removeModel } from '../colyseus/multiplayer'
import { debugPanelEvent } from '../util/eventBus'
import { reduxStore } from '../store/reduxStore'
import { getPlayerDefaultState } from '../util/getPlayerDefaultState'

export async function setupCurrentPlayerModel(playerModel: Entity) {
  // Remove the current player model when it is ready
  const defaultState = getPlayerDefaultState()

  if (colyseusClient.currentEntity) {
    removeModel(colyseusClient.currentEntity)
  }

  colyseusClient.currentEntity = playerModel

  if (colyseusClient.currentRoom) {
    const p = colyseusClient.currentRoom.state.players.get(
      colyseusClient.currentRoom.sessionId,
    )
    p!.data.entity = playerModel
  }
  animateModel(playerModel, false, 'self')

  if (defaultState) {
    playerModel.setPosition({
      x: defaultState.x!,
      y: defaultState.y!,
      z: defaultState.z!,
    })
    playerModel.object.zIndex = reduxStore.getState().players.players['self'].syncState.z
    playerModel.object.scale.x = defaultState.scaleX!
    playerModel.object.scale.y = defaultState.scaleY!
  }
  debugPanelEvent.emit('update-player-model', playerModel)
}