
// a function to throw error if any of the input variable is empty or null, also log out the variable name
export function checkInput(inputs: any) {
  for (const key of Object.keys(inputs)) {
    const input = inputs[key]
    if (input === undefined || input === null || input === '') {
      throw new Error(`${key} is empty or null`)
    }
  }
}

