import { Entity } from '../../ecs/core/Entity'
import { getDefaultPosition, sendRoomEventSafe } from '../multiplayer'
import { colyseusClient } from '../colyseusClient'

export function updatePlayerPosition(
  model?: Entity,
  playerId?: string,
  uuid?: string,
) {
  sendRoomEventSafe('updatePosition', {
    //TODO - this will reset to 0 point
    position: getDefaultPosition(model),
    target: playerId === '' ? colyseusClient.currentRoom?.sessionId : playerId,
    uuid: uuid,
  })
}