import { showDialog } from '../utils/dialogUtils'
import { BsCloud as UploadIcon } from 'react-icons/bs'
import { MdOutlineDesktopWindows as DownloadIcon } from 'react-icons/md'
import { BsArrowRight as ArrowRight } from 'react-icons/bs'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Tooltip from '../common/Tooltip'
import model_icon from '../../../img/model_icon.jpg'

dayjs.extend(relativeTime)

function VersionText({ version }: { version?: number }) {

  if (!version) return null

  return <Tooltip dataTip={'Version: ' + version}>
    <div>{dayjs(version).toNow(true)} ago</div>
  </Tooltip>
}

export async function showCacheConflictsDialog(type: 'download' | 'upload', localVersion?: number, remoteVersion?: number): Promise<boolean> {
  switch (type) {
    case 'download':
      return await showDialog({
        title: 'Model Cache Conflict',
        cancelText: 'Keep Local',
        confirmText: 'Download',
        customContent: <div>
          A newer version is available on the server, would you like to update?
          <div className="flex items-center mx-auto justify-center mt-4">
            <div className="flex relative flex-col justify-center items-center">
              <UploadIcon size={'60'} />
              <img src={model_icon}
                   className="aspect-square h-8 rounded-full absolute bottom-5 -right-2 animate-bounce" />
              <VersionText version={remoteVersion} />
            </div>
            <ArrowRight className={'mx-10'} />
            <div className="flex flex-col items-center">
              <DownloadIcon size={'60'} />
              <VersionText version={localVersion} />
            </div>
          </div>
        </div>,
      })
    case 'upload':
      return await showDialog({
        title: 'Model Cache Conflict',
        cancelText: 'Keep Server',
        confirmText: 'Upload',
        customContent: <div>
          Your server cache is not up to date.  Would you like to upload from local?
          <div className="flex items-center mx-auto justify-center mt-4">
            <div className="flex relative flex-col justify-center items-center">
              <DownloadIcon size={'60'} />
              <img src={model_icon}
                   className="aspect-square h-8 rounded-full absolute bottom-5 -right-2 animate-bounce" />
              <VersionText version={localVersion} />
            </div>

            <ArrowRight className={'mx-10'} />
            <div className="flex flex-col items-center">
              <UploadIcon size={'60'} />
              <VersionText version={remoteVersion} />
            </div>
          </div>
        </div>,
      })
  }
}