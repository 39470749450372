// Give user id > Find model data related to the user id
import { ModelData } from 'playground-data-model'
import { fetchSupabase } from './fetchSupabase'
import { supabase } from './client'

export async function queryModelData(userId: string): Promise<ModelData[] | null> {
  const data = await fetchSupabase(
    supabase
      .from<ModelData>('models')
      .select().eq('user_id', userId),
  )
  return data
}