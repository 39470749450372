import { Spinner } from '../common/Spinner'
import React, { ReactNode } from 'react'

export function PureLoadingScreen(props: { label?: string | ReactNode, spinning?: boolean }) {
  return (
    <div className="custom-bg w-screen h-screen text-center items-center flex justify-center gap-2">
      {props.label ?? 'Setting up playground...'}
      {props.spinning === undefined || props.spinning === true ? <Spinner /> : null}
    </div>
  )
}