import { colyseusClient, updateLocalPlayerPosition, updatePlayerPosition } from '../index'
import { reduxStore, selectIsHost } from '../../store'
import { updateTargetPlayerSelectorId } from '../transform-sync/updateTargetPlayerSelectorId'
import { log } from '../../logger'
import { handleHostOverridePosition } from '../transform-sync/handleHostOverridePosition'
import { growthbook } from '../../../utils/GrowthBook'
import { InteractionManager } from 'pixi.js'

export function updatePlayerDrag(deltaTime: number) {
  const interaction: InteractionManager = colyseusClient.app?.renderer.plugins.interaction
  const holdingEntity = colyseusClient.holdingEntity
  const previousModel = colyseusClient.previousModel

  if (interaction && holdingEntity) {
    colyseusClient.lastSelectedModel = holdingEntity
    holdingEntity.props.dragging = true

    if (previousModel !== holdingEntity) {
      if (previousModel !== undefined) {
        previousModel.props.dragging = false
      }
      holdingEntity.props._pointerX = colyseusClient.initX - holdingEntity.object.x
      holdingEntity.props._pointerY = colyseusClient.initY - holdingEntity.object.y
      colyseusClient.previousModel = holdingEntity
    }

    if (holdingEntity.props.dragging) {
      const pointX = interaction.mouse.global.x - holdingEntity.props._pointerX
      const pointY = interaction.mouse.global.y - holdingEntity.props._pointerY

      let playerId = 'self'

      if (holdingEntity.sessionId !== reduxStore.getState().room.sessionId)
        playerId = holdingEntity.sessionId!

      if (holdingEntity.props.playerId === 'self') {
        if (colyseusClient.currentRoom

          // If this is not host and disabled sync, we will not send the position information
          // && !(!reduxStore.getState().settings.syncObjectTransform && !selectIsHost(reduxStore.getState()))
          && (reduxStore.getState().settings.syncObjectTransform)
        ) {
          updateTargetPlayerSelectorId(holdingEntity.sessionId!)
          updatePlayerPosition(holdingEntity, colyseusClient.currentRoom.sessionId)
        } else {
          log('Can\'t send position to server')
        }
      } else {
        handleHostOverridePosition(playerId, pointX, pointY)
      }

      if (growthbook.isOn('host_override_position') && reduxStore.getState().settings.syncObjectTransform) {
        // When in host override position, only apply self position
        if (holdingEntity.props.playerId === 'self')
          updateLocalPlayerPosition(playerId, pointX, pointY)
      } else {
        updateLocalPlayerPosition(playerId, pointX, pointY)
      }
    }
  }
}