import type { PlaygroundWindow } from './types'

declare let window: PlaygroundWindow

if (window.playgroundCore == null) {
  window.playgroundCore = {}
}

export type { PlaygroundWindow }

export * from './ecs/core'
export * from './amplitude'
export * from './live2d'
export * from './toast'
export * from './logger'
export * from './vtubestudio'
export * from './model-loading'
export * from './store'
export * from './util'
export * from './supabase'
export * from './colyseus'
export * from './types'