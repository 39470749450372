import { ResourcePipelineContext } from '../ResourcePipelineContext'
import { Next } from '../../Middleware'
import { log } from '../../../../logger'
import { logMiddleware } from '../util/middlewareLogger'

export async function SkipModelLoadingModel(context: ResourcePipelineContext, next: Next): Promise<void> {
  if (context.inputFiles) {
    return next();
  }
  logMiddleware(context,'bypassing upload model, no local cache')
}

