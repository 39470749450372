import { en, EnKeys } from '../messages/en-US'
import { useIntl } from 'react-intl'
import { IntlShape } from 'react-intl/src/types'

export function getTranslation(
  intl:IntlShape,
  id: EnKeys
) {
  return intl.formatMessage({
    id: id,
    defaultMessage: en[id],
  })
}