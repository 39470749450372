import { useAppDispatch } from '../../hook/hooks'
import { useEffect, useRef } from 'react'
import { log, showToast, supabase } from 'playground-core'
import { userAction } from 'playground-core'
import { useParams, useSearchParams } from 'react-router-dom'
import { showDialog } from '../utils/dialogUtils'
import { handleRemoteCacheRefresh } from 'playground-core'
import { getParsedRecoverSession, getRecoverSession } from '../toolbar/room/JoinRoom'

export function checkForRecoverSession() {
  // make sure we only run once after login
  let haveRecoverSession = false;

  // if there's hash, check
  if (window.location.hash) {
    const recover_session = getRecoverSession(window.location.hash)
    if (recover_session) haveRecoverSession = getParsedRecoverSession(recover_session) !== undefined
  }

  return haveRecoverSession
}

export function LoginEventReceiver() {
  let [searchParams, setSearchParams] = useSearchParams();

  // To prevent repeatedly called onAuthStateChange
  let onAuthStateEventListener = useRef(false)

  useEffect(() => {
    const error = searchParams.get('error');
    const error_description = searchParams.get('error_description');

    if (error && error_description) {
      let finalMessage = error_description;
      if (error_description === 'Error getting user email from external provider') {
        finalMessage = error_description + ' Please add and verify your email address in your Twitter account.';
      }
      showDialog({
        eventType: "server_error",
        title: error,
        message: finalMessage,
      })
      setSearchParams(new URLSearchParams());
    }

  }, [searchParams])

  const dispatch = useAppDispatch()
  useEffect(() => {
    const { data, error } = supabase.auth.onAuthStateChange(
      (event, session) => {
        if (!onAuthStateEventListener.current)
          dispatch(userAction.onAuthStateChanged({
            user: session?.user ?? null,
          }))

        // refresh page if there is no user
        if (!session) {
          window.location.reload()
        } else if (!onAuthStateEventListener.current) {
          handleRemoteCacheRefresh()
        }

        onAuthStateEventListener.current = true
      },
    )
  }, [])

  return <></>
}