import { ResourcePipelineContext } from './ResourcePipelineContext'
import { Next } from '../Middleware'
import {
  beginRemoteResourceRequest,
  globalBlobQueue, pipelineAction,
  reduxStore,
  updateStatus,
  updateStatusProgress,
} from '../../../store'
import { log, logMiddlewareTimeEnd, logMiddlewareTimeStart, logTimeEnd, logTimeStart } from '../../../logger'
import { logMiddleware } from './util/middlewareLogger'
import { showErrorToast } from '../../../toast'
import { feedbackAction } from 'playground-web/src/core'

export function justInitRemoteResource(targetId: string) {
  beginRemoteResourceRequest(targetId, targetId === 'self')
  globalBlobQueue[targetId] = {}
  updateStatusProgress(targetId, 0)
}

function initRemoteResource(context: ResourcePipelineContext, targetId: string) {
  // TODO better represent the should cache property
  logMiddleware(context, '[RR] Starting up ' + targetId)
  logMiddlewareTimeStart(context.pipelineId!)
  justInitRemoteResource(targetId)
}

function cleanup(context: ResourcePipelineContext, targetId: string) {
  logMiddleware(context, '[RR] Cleaning up ' + targetId)
  let n = logMiddlewareTimeEnd(context.pipelineId!)
  logMiddleware(context, context.targetId + ' total time used : ' + n.toFixed(2) + 'ms')
  delete globalBlobQueue[targetId]
}

let errorCount = 0

export async function RemoteResourceWrapperMiddleware(context: ResourcePipelineContext, next: Next): Promise<void> {
  const status = reduxStore.getState().remoteResources.list[context.targetId]?.status
  if (status?.endsWith('ing') && status != 'upload-pending') {
    if (context.targetId === 'self')
      showErrorToast('Please wait until the previous model is loaded')
    else
      console.error('Conflicting model operation: ' + context.targetId)
    return
  }

  let n
  let hasError = false
  try {
    initRemoteResource(context, context.targetId)
    updateStatus(context.targetId, 'starting')
    n = await next()
  } catch (e: any) {
    console.error(e)
    updateStatus(context.targetId, 'error', e.message)
    logMiddleware(context, e.message)
    // logMiddleware(context)
    hasError = true

    reduxStore.dispatch(
      pipelineAction.updateMiddlewareStatus({
        pipelineId: context.pipelineId!,
        middlewareId: context.pipelineNameCurrentMWId!,
        status: 'failed',
      }),
    )
    errorCount++
    if (errorCount == 3) {
      reduxStore.dispatch(feedbackAction.setIsOpen(true))
      errorCount = 0
    }
  } finally {
    if (!hasError) {
      updateStatus(context.targetId, 'completed')
    }
    cleanup(context, context.targetId)

  }

  return n
}
