import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "@supabase/supabase-js";
import { RootState } from "../reduxStore";
import { toast } from 'react-toastify'
import { supabase } from '../../supabase/client'

export interface UserState {
    user?: User | null
    // uploadModel...
}

const initialState: UserState = {
    user: supabase.auth?.user()
}

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        onAuthStateChanged(state, action: PayloadAction<{
            user: User | null
        }>) {
            const user = action.payload.user
            let userName = user ? user.user_metadata.user_name : state.user?.user_metadata.user_name
            state.user = action.payload.user;

            // console.log('hi')

            // TODO - put this back to redux later
            toast(`${userName} ${!user? 'logged out' : 'signed in'}`);
        },
    },
});


export const userAction = slice.actions
export const selectEmail = (state: RootState) => state.user.user?.email
export const selectHasLogin = (state: RootState) => state.user.user != null
export const selectUserName = (state: RootState) => state.user.user?.user_metadata?.user_name
export const selectUserId = (state: RootState) => state.user.user?.id

export default slice.reducer