import {
  appState,
  colyseusClient,
  debugPanelEvent,
  events,
  handleModelLoadingOnStart,
  handleRemoteCacheRefresh,
  leaveCurrentRoom,
  patchRemoteRoomState,
  reduxStore,
  selectEnterRoomPrompt,
  selectIsHost,
  settingsActions,
  showToast,
  statusActions,
  supabase,
} from 'playground-core'
import { useContext, useEffect, useState } from 'react'
import { hidePrompt, showPrompt } from '../../dialog/PromptDialog'
import { Button } from '../../common/Button'
import { useIsOBSView } from '../../../hook/useIsOBSView'
import { useLocation, useParams } from 'react-router-dom'
import { faqUrl } from '../../../utils/constUrl'
import { useAppSelector } from '../../../hook/hooks'
import { MdEast } from 'react-icons/md'
import { joiningRoom } from './JoiningRoom'
import { showDialog } from '../../utils/dialogUtils'
import { EventContext } from '../../common/ContextProvider'
import { RecoverSession } from '../streaming/RecoverSession'
import { IntlShape, useIntl } from 'react-intl'
import { getTranslation } from '../../../i18n/utils/getTranslation'
import { Translation } from '../../../i18n/utils/Translation'

function LeaveRoomDialogContent() {
  const intl = useIntl()
  return (
    <>
      <div>{getTranslation(intl, 'leave_room_description1')}</div>
      <div className='text-71FBC9 mt-2'>
        {getTranslation(intl, 'leave_room_description2')}
      </div>
    </>
  )
}

export async function leaveRoomWithPrompt() {
  if (colyseusClient.currentRoom) {
    const confirmToLeaveRoom = await showDialog({
      eventType: 'tool_bar_leave_room',
      title: <Translation id='leave_room_title' />,
      message: '',
      customStyle: 'flex justify-end items-end pr-60',
      checkboxGap: 'justify-start',
      hideBlackBlock: true,
      customContent: LeaveRoomDialogContent,
    })
    if (confirmToLeaveRoom) {
      showToast('Leaving current room...')
      await leaveCurrentRoom()
      debugPanelEvent.emit('update-room-status', {
        roomId: undefined,
      })
      reduxStore.dispatch(settingsActions.setBackgroundSceneName('default'))
      if (selectIsHost(reduxStore.getState()))
        patchRemoteRoomState({ currentSceneId: 'default' })
    } else {
      return
    }
  }
}

function BeforeJoinPromptContent() {
  const intl = useIntl()
  return (
    <div className='py-2 mb-2'>
      <p className='block'>{getTranslation(intl, 'join_room_description1')}</p>

      <p className='mt-2 block text-[#FF6D1B]'>
        {getTranslation(intl, 'join_room_description2')}
      </p>
    </div>
  )
}

const beforeShareRoomPrompt = () =>
  showPrompt({
    eventType: 'before_join_room',
    title: 'Before You Join',
    showHelp: true,
    helpUrl: faqUrl + '/rooms/join-rooms',
    message: '',
    hideConfirm: true,
    hideOnTapOutside: false,
    showCheckbox: true,
    hideCancel: true,
    confirmText: 'OK',
    customStyle: 'flex justify-end items-end',
    checkboxGap: 'justify-between',
    customContent: BeforeJoinPromptContent,
  })

export function ToolbarDimLeft() {
  const enterRoomPrompt = useAppSelector(selectEnterRoomPrompt)
  //const [hideGUI, setHideGUi] = useRecoilState(enterRoomPromptState);

  if (!enterRoomPrompt) return <></>

  return (
    <div className='z-20 absolute top-0 left-0 w-screen h-screen bg-black bg-opacity-50'></div>
  )
}

export function MainLayoutDim() {
  const enterRoomPrompt = useAppSelector(selectEnterRoomPrompt)

  if (!enterRoomPrompt) return <></>

  return (
    <div className='absolute top-0 left-0 w-screen h-screen bg-black bg-opacity-50'></div>
  )
}

export function cleanUrl() {
  window.history.replaceState(
    null,
    '',
    window.location.protocol + '//' + window.location.host
  )
}

export function getRecoverSession(hash: string) {
  // showToast('Incoming hash detected')
  const p = new URLSearchParams(hash.substring(1))
  return p.get('recover_session')
}

export function getParsedRecoverSession(recover_session: string) {
  let sessionw = JSON.parse(atob(recover_session)) as RecoverSession
  const lastSessionDate = Number.parseInt(
    localStorage.getItem('recover_session_date') ?? '0'
  )
  // We only login with the session if we havent done that previously
  if (sessionw.date !== lastSessionDate) {
    return sessionw
  }
  return undefined
}

function handleRecoverSession(hash: string, isObsView: boolean): boolean {
  const recover_session = getRecoverSession(hash)
  if (!recover_session) return false

  showToast('Incoming session detected')

  const sessionw = getParsedRecoverSession(recover_session)
  if (!sessionw) return false
  ;(async () => {
    window.location.hash = sessionw.refresh_token
    reduxStore.dispatch(statusActions.setFirstTimeModelSyncChecked(false))
    const { data, error } = await supabase.auth.getSessionFromUrl({
      storeSession: true,
    })

    if (error) {
      showToast(error.message)
      console.error(error)
      reduxStore.dispatch(statusActions.setFirstTimeModelSyncChecked(true))
      // sth went wrong with sign in, so we contiune the normal model loading pipeline
      return
    }

    localStorage.setItem('recover_session_date', sessionw.date.toString())

    if (sessionw.room_id && sessionw.room_id !== '')
      await JoinRoomAfterLoginFinish(sessionw.room_id, isObsView)
  })()
  return true
}

export function JoinRoomFromUrl() {
  let { id: inComingRoomId } = useParams()
  const { hash } = useLocation()

  // Hack to make enable the dim
  const isObsView = useIsOBSView()

  useEffect(() => {
    if (hash) {
      if (!handleRecoverSession(hash, isObsView)) {
        // failed at recover session, run normal handleModelLoading..
        handleModelLoadingOnStart()
      }
    } else {
      if (inComingRoomId) {
        if (inComingRoomId && inComingRoomId !== '') {
          JoinRoomAfterLoginFinish(inComingRoomId, isObsView)
        }
      }
    }
  }, [inComingRoomId])

  return null
}

async function JoinRoomAfterLoginFinish(
  inComingRoomId: string,
  isObsView: boolean
) {
  // create a promise to wait until the blockingLoading is false and call beginJoinRoom
  const promise = new Promise<boolean>(async (resolve, reject) => {
    const unsubscribe = reduxStore.subscribe(() => {
      const state = reduxStore.getState()
      if (
        !state.status.blockingLoading &&
        state.status.firstTimeModelSyncChecked
      ) {
        unsubscribe()
        resolve(true)
      }
    })
  })

  showToast('Room link detected')

  const ok = await promise
  if (ok) await beginJoinRoom(inComingRoomId!, 'load_from_url', undefined)

  if (!isObsView) {
    // Removing the room id from url to hide it form showing in the url bar
    cleanUrl()
  }
}

const beginJoinRoom = async (
  roomId: string,
  value: string,
  setLoading?: (loading: boolean) => void
) => {
  if (roomId === '') return
  await showPrompt({
    eventType: `${value}_before`,
    title: <Translation id='join_room_title' />,
    showHelp: true,
    helpUrl: faqUrl + '/rooms/join-rooms',
    message: '',
    hideOnTapOutside: false,
    // hideCancel: true,
    onConfirm: () => {
      hidePrompt()
      joiningRoom(roomId, value, setLoading, undefined)
    },
    onCancel: async () => {
      // log('cancel');
      // await delay(500)
    },
    customStyle: 'flex justify-center items-center z-0',
    // checkboxGap: 'justify-between',
    // For making this dialog on top of the the dim area
    className: 'mb-40 ',
    hideBlackBlock: true,
    customContent: BeforeJoinPromptContent,
  })
}

export const JoinRoom = () => {
  const value = useContext(EventContext)
  const [roomId, setText] = useState('')
  const [loading, setLoading] = useState(false)
  const intl = useIntl()

  const handleOnClick = async (e: any) => {
    e.preventDefault()
    await beginJoinRoom(roomId, value, setLoading)
    setText('')
    return
  }

  const inputBarPlaceHolder = getTranslation(intl, 'join_room_placeholder')

  return (
    // ENTER BAR
    <form
      autoComplete='off'
      className='mx-5 relative z-[70] rounded-lg pl-4 pr-1 flex flex-row justify-between bg-black'
    >
      <input
        id={`${value}#input_bar`}
        className='text-sm h-9 w-40 text-secondary bg-black'
        placeholder={inputBarPlaceHolder}
        type='text'
        value={roomId}
        onFocus={() => events(`${value}#input_bar_focus`)}
        onChange={(event) => {
          const input = event.target.value
          if (input.startsWith('http')) {
            const url = new URL(input)
            // extract the room id from room path https://invite.avatech.gg/room/b838bb80-9127-4caf-8868-b44c8f933aea?from_url=http://localhost:3000
            if (url.pathname.startsWith('/room/')) {
              const roomId = url.pathname.split('/')[2]
              setText(roomId)
            }
          } else {
            setText(input)
          }
        }}
      />
      <Button
        role={'submit'}
        name={`enter`}
        eventProperty={{ value: roomId || 'not have this room' }}
        content={<MdEast size={15} />}
        isLoading={loading}
        className='h-7 self-center pr-3'
        onClick={handleOnClick}
        eventAction
        removeBg
        removeStyle
      />
    </form>
  )
}
