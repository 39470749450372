import * as Sentry from '@sentry/react';
import { BrowserTracing } from "@sentry/tracing";

export function initSentry(userId: string | undefined) {
  Sentry.init({
    dsn: 'https://4f7242113ea04bc2a7d64a96588ca0fc@o1210015.ingest.sentry.io/6345673',
    integrations: [new BrowserTracing()],
    release: '__VERSION__',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  // Sentry.setUser({ username: userId! })
  Sentry.setTags({ userId: userId! })
}
