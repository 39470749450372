import "reflect-metadata";

export const componentKey = Symbol('component');

interface ComponentMetadata {
  name: string;
}

/**
 * To preserve class name though mangling.
 * @example
 * @name('Customer')
 * class Customer {}
 * @param metadata
 */
export function component(metadata: ComponentMetadata): ClassDecorator {
  return Reflect.metadata(componentKey, metadata);
}

/**
 * @example
 * const type = Customer;
 * getName(type); // 'Customer'
 * @param type
 */
export function getComponentMetadataFromClass(type: Function): ComponentMetadata {
  return Reflect.getMetadata(componentKey, type);
}

/**
 * @example
 * const instance = new Customer();
 * getInstanceName(instance); // 'Customer'
 * @param instance
 */
export function getComponentMetadata(instance: Object): ComponentMetadata {
  return Reflect.getMetadata(componentKey, instance.constructor);
}

