import { Cubism4InternalModel, InternalModel, Live2DModel } from 'pixi-live2d-display'
import { colyseusClient } from '../colyseus/colyseusClient'
import { log } from '../logger/logger'
import { Entity } from '../ecs/core/Entity'
import { reduxStore } from '../store/reduxStore'
import { playersActions } from '../store/reducers/playersSlice'
import { version } from 'jszip'
import { mapSessionIdToReduxPlayerId } from '../colyseus/utils/mapSessionIdToReduxPlayerId'
import { mapReduxPlayerIdToSessionId } from '../colyseus/utils/mapReduxPlayerIdToSessionId'
import { SVG } from '../colyseus/SVG'
import { Container, Graphics, InteractionData, InteractionManager, Sprite } from 'pixi.js'
import { xhrCallback } from '@sentry/tracing/types/browser/request'
import { pixiSceneState } from 'core/store/reducers/pixiSceneSlice'

export function attachItem(entity: Entity, localX: number, localY: number, itemId: string, attachemnt: SVG | Sprite, offsetX: number = 0, offsetY: number = 0, drawerId: string){
  if (!containAttachment(itemId).contains){
    const nearestVertex = findNearestVertex(localX, localY, entity)
    colyseusClient.attachments!.push({
      id: itemId,
      attachedPlayerId: entity.props.playerId,
      drawerId: drawerId,
      attachment: attachemnt,
      drawableName: nearestVertex.drawableName,
      nearestVertex: nearestVertex.vertexIndex,
      offsetX: offsetX,
      offsetY: offsetY,
    })

    const externalModel = entity.object as Live2DModel<InternalModel>
    externalModel.addChild(attachemnt)
    // attachemnt.scale.set(externalModel.scale.x / attachItem.)

    attachemnt.scale.set(
      attachemnt.scale.x / externalModel.scale.x,
      attachemnt.scale.y / externalModel.scale.y
    )

    attachemnt.position.set(nearestVertex.localX + offsetX, nearestVertex.localY + offsetY)
  }
}

export function clearAttachment(entity: Entity){
  const externalModel = entity.object as Live2DModel<InternalModel>
  externalModel.removeChildren()
}

export function containAttachment(id: string): {contains: boolean, index: number}{
  for(let i = 0; i < colyseusClient.attachments!.length; i++){
    if (colyseusClient.attachments![i].id === id){
      return {contains: true, index: i}
    }
  }
  return {contains: false, index: -1}
}

export function findNearestVertex(pointX: number, pointY: number, entity: Entity): {drawableName: string, vertexIndex: number, localX: number, localY: number, worldX: number, worldY: number}{
  const externalModel = entity.object as Live2DModel<InternalModel>
  let model = externalModel.internalModel as Cubism4InternalModel
  const renderer = model.renderer

  const canvasWidth = renderer.getModel().getCanvasWidth()
  const canvasHeight = renderer.getModel().getCanvasHeight()
  let largerCanvas: number = 0
  let smallerCanvas: number = 0
  if (canvasHeight > canvasWidth) {
    largerCanvas = canvasHeight
    smallerCanvas = canvasWidth
  } else {
    largerCanvas = canvasWidth
    smallerCanvas = canvasHeight
  }

  const centerX = externalModel.internalModel.width * externalModel.anchor.x
  const centerY = externalModel.internalModel.height * externalModel.anchor.y
  const drawableIds: string[] = renderer.getModel().getDrawableIds()


  let closest: number = Number.MAX_VALUE;
  let drawableName: string = ''
  let closestIndex: number = 0;
  let finalX: number = 0;
  let finalY: number = 0;
  let globalX: number = 0;
  let globalY: number = 0;
  for (let i = 0; i < drawableIds.length; i++) {
    if (renderer.getModel().getDrawableDynamicFlagIsVisible(i) === true) {
      const vertices = renderer.getModel().getDrawableVertexPositions(i)
      for (let k = 0; k < vertices.length; k = k + 2) {
        let x = vertices[k] * externalModel.internalModel.width
        let y = -vertices[k + 1] * externalModel.internalModel.height
        if (canvasWidth > canvasHeight){
          x = x * smallerCanvas / largerCanvas
        }
        if (canvasHeight > canvasWidth){
          y = y * smallerCanvas / largerCanvas
        }
        x += centerX
        y+= centerY

        let distance = Math.sqrt(Math.pow(x - pointX, 2) + Math.pow(y - pointY, 2))
        if (distance < closest){
          closest = distance
          drawableName = drawableIds[i]
          closestIndex = k;
          finalX = x;
          finalY = y;
        }
      }
    }
  }
  const globalPoint = externalModel.toGlobal({x: finalX, y: finalY})
  globalX = globalPoint.x
  globalY = globalPoint.y

  return {drawableName: drawableName, vertexIndex: closestIndex, localX: finalX, localY: finalY, worldX: globalX, worldY: globalY}
}


export function AttachmentUpdate(t: number){
  if (!colyseusClient.attachments) return

  colyseusClient.attachments.forEach((value) =>{
    const entity = findEntityByPlayerId(mapReduxPlayerIdToSessionId(value.attachedPlayerId));

    if (!entity) return

    const externalModel = entity.object as Live2DModel<InternalModel>
    let model = externalModel.internalModel as Cubism4InternalModel
    const renderer = model.renderer

    const canvasWidth = renderer.getModel().getCanvasWidth()
    const canvasHeight = renderer.getModel().getCanvasHeight()
    let largerCanvas: number = 0
    let smallerCanvas: number = 0
    if (canvasHeight > canvasWidth) {
      largerCanvas = canvasHeight
      smallerCanvas = canvasWidth
    } else {
      largerCanvas = canvasWidth
      smallerCanvas = canvasHeight
    }

    const drawableIndex = renderer.getModel().getDrawableIndex(value.drawableName);
    const vertices = renderer.getModel().getDrawableVertexPositions(drawableIndex)
    const centerX = externalModel.internalModel.width * externalModel.anchor.x
    const centerY = externalModel.internalModel.height * externalModel.anchor.y

    let x = vertices[value.nearestVertex] * externalModel.internalModel.width
    let y = -vertices[value.nearestVertex + 1] * externalModel.internalModel.height
    if (canvasWidth > canvasHeight){
      x = x * smallerCanvas / largerCanvas
    }
    if (canvasHeight > canvasWidth){
      y = y * smallerCanvas / largerCanvas
    }
    x += centerX
    y += centerY

    value.attachment.x = x + value.offsetX
    value.attachment.y = y + value.offsetY
  })

  const attachmentUpdate = colyseusClient.attachments.map((value) => {
    const entity = findEntityByPlayerId(value.attachedPlayerId);
    return {
      playerId: mapSessionIdToReduxPlayerId(entity?.props.playerId),
      id: value.id,
      x: value.attachment.x,
      y: value.attachment.y
    }
  })

  if (attachmentUpdate && attachmentUpdate.length > 0)
    reduxStore.dispatch(playersActions.onPlayerAttachmentBatchUpdate(
      attachmentUpdate
    ))
}

export function findEntityByPlayerId(playerId: string): Entity | undefined{
  for(let i = 0; i < colyseusClient.allEntities!.length; i++){
    if (mapReduxPlayerIdToSessionId(colyseusClient.allEntities![i].props.playerId) === playerId){
      return colyseusClient.allEntities![i];
    }
  }
  return undefined
}

// TODO: Support Cubism2InternalModel Selection
const clickOnPixel = async (entity: Entity, mouseX: number, mouseY: number, width: number, height: number): Promise<{ r: number; g: number; b: number; a: number; } | undefined> => {

  let externalModel = entity.object as Live2DModel<InternalModel>
  const renderTexture = colyseusClient.app!.renderer.generateTexture(
    externalModel,
    colyseusClient.PIXI.SCALE_MODES.NEAREST,
    1,
    new colyseusClient.PIXI.Rectangle(0, 0, width * 2, height),
  )

  const sprite: Sprite = new colyseusClient.PIXI.Sprite(renderTexture)
  // await delay(100)
  sprite.scale.set(1 / entity.object.scale.x, 1 / entity.object.scale.y)

  var color: { r: number, g: number, b: number, a: number, pixels: Uint8ClampedArray } | undefined

  let isSelf: boolean = false
  if (entity.props.playerId === '' || colyseusClient.currentRoom && colyseusClient.currentEntity?.props.playerId === colyseusClient.currentRoom.sessionId)
    isSelf = true

  sprite.x = -externalModel.x / entity.object.scale.x + sprite.texture.width * externalModel.anchor.x - width * 0.5
  sprite.y = -externalModel.y / entity.object.scale.y + sprite.texture.height * externalModel.anchor.y
  //externalModel.addChild(sprite)

  color = checkPixels(sprite, mouseX, mouseY, width, height)
  sprite.destroy({ children: true, texture: true, baseTexture: true })
  renderTexture.destroy(true)
  if (color) {
    log(`${color.r}, ${color.g}, ${color.b}, ${color.a}`)
    return { r: color.r, g: color.g, b: color.b, a: color.a }
  }
  return undefined
}

const checkPixels = (sprite: Sprite, mouseX: number, mouseY: number, width: number, height: number, providedPixels?: Uint8ClampedArray): { r: number; g: number; b: number; a: number; pixels: Uint8ClampedArray } => {

  let pixels: Uint8ClampedArray = new Uint8ClampedArray()
  pixels = colyseusClient.app!.renderer.plugins.extract.pixels(
    sprite,
    { x: 0, y: 0, resolution: 0.5, width: width, height: height },
  )

  var index = (Math.floor(mouseY) * width * 2 + Math.floor(mouseX)) * 4
  log(index)
  log(pixels.length)
  const r = pixels[index]
  const g = pixels[index + 1]
  const b = pixels[index + 2]
  const a = pixels[index + 3]
  return { r: r, g: g, b: b, a: a, pixels: pixels }
}

const colorValues: number[] = [
  0xFA5F3E,
  0xFECE51,
  0x96FE51,
  0x51FEF0,
  0x516AFE,
  0xC251FE,
  0xFE51A7,
  0xFE5154,
  0xFDEDC0,
  0xDAFDC0,
  0xC0FDF5,
  0xC0C2FD,
  0xFDC0F7,
  0xFDC0C6,
]

var outlineContainers: Container[] = []

export function clearModelVertices(){
  if (outlineContainers.length > 0){
    outlineContainers.forEach((container) =>{
      container.destroy()
    })
    outlineContainers = []
  }
}

export function drawModelVertices(){

  for(let i = 0; i < colyseusClient.allEntities!.length; i++){
    const entity = colyseusClient.allEntities![i]

    const externalModel = entity.object as Live2DModel<InternalModel>
    let model = externalModel.internalModel as Cubism4InternalModel
    const renderer = model.renderer

    const canvasWidth = renderer.getModel().getCanvasWidth()
    const canvasHeight = renderer.getModel().getCanvasHeight()
    let largerCanvas: number = 0
    let smallerCanvas: number = 0
    if (canvasHeight > canvasWidth) {
      largerCanvas = canvasHeight
      smallerCanvas = canvasWidth
    } else {
      largerCanvas = canvasWidth
      smallerCanvas = canvasHeight
    }


    let outlineContainer = new colyseusClient.PIXI.Container()
    outlineContainers.push(outlineContainer)
    externalModel.addChild(outlineContainer)

    var graphics = new colyseusClient.PIXI.Graphics()

    const centerX = externalModel.internalModel.width * externalModel.anchor.x
    const centerY = externalModel.internalModel.height * externalModel.anchor.y
    const drawableIds: string[] = renderer.getModel().getDrawableIds()
    for(let i = 0; i < drawableIds.length; i++){
      if (renderer.getModel().getDrawableDynamicFlagIsVisible(i) === true){

        const vertices = renderer.getModel().getDrawableVertexPositions(i)
        for(let k = 0; k < vertices.length; k = k + 2){
          let x = vertices[k] * externalModel.internalModel.width
          let y = -vertices[k + 1] * externalModel.internalModel.height
          if (canvasWidth > canvasHeight){
            x = x * smallerCanvas / largerCanvas
          }
          if (canvasHeight > canvasWidth){
            y = y * smallerCanvas / largerCanvas
          }
          x += centerX
          y+= centerY

          graphics.beginFill(colorValues[i % colorValues.length]);
          graphics.drawRect(x, y, 10, 10);
          outlineContainer.addChild(graphics)
        }
      }
    }

    // center
    graphics.beginFill(0xFFFFFF);
    graphics.drawCircle(centerX, centerY, 50)
    outlineContainer.addChild(graphics)

    // right
    graphics.beginFill(0xFFFFFF);
    graphics.drawCircle(centerX - externalModel.internalModel.width / 2, centerY, 50)
    outlineContainer.addChild(graphics)

    // left
    graphics.beginFill(0xFFFFFF);
    graphics.drawCircle(centerX + externalModel.internalModel.width / 2, centerY, 50)
    outlineContainer.addChild(graphics)

    // top
    graphics.beginFill(0xFFFFFF);
    graphics.drawCircle(centerX, centerY - externalModel.internalModel.height / 2, 50)
    outlineContainer.addChild(graphics)

    // bottom
    graphics.beginFill(0xFFFFFF);
    graphics.drawCircle(centerX, centerY + externalModel.internalModel.height / 2, 50)
    outlineContainer.addChild(graphics)
  }
}


