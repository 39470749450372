import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../reduxStore'

export type MiddlewareState = {
  id: string
  label: string
  status: 'loading' | 'success' | 'failed' | 'not-started'
  log: any[]
}

export type PipelineState = {
  id: string
  name: string
  middleware: MiddlewareState[]
}

export interface PipelinesState {
  pipelines: PipelineState[]
}

// Define the initial state using that type
const initialState: PipelinesState = {
  pipelines: [],
}

const slice = createSlice({
  name: 'pipeline',
  initialState: initialState,
  reducers: {
    addPipeline(state, action: PayloadAction<PipelineState>) {
      state.pipelines.push(action.payload)
    },
    updateMiddlewareStatus(state, action: PayloadAction<{
      pipelineId: string
      middlewareId: string
      status: 'loading' | 'success' | 'failed' | 'not-started'
    }>) {
      const pipeline = state.pipelines.find(p => p.id === action.payload.pipelineId)
      if (pipeline) {
        const middleware = pipeline.middleware.find(m => m.id === action.payload.middlewareId)
        if (middleware) {
          middleware.status = action.payload.status
        }
      }
    },
    logMiddleware(state, action: PayloadAction<{
      pipelineId: string
      middlewareId: string
      log: any
    }>) {
      const pipeline = state.pipelines.find(p => p.id === action.payload.pipelineId)
      if (pipeline) {
        const middleware = pipeline.middleware.find(m => m.id === action.payload.middlewareId)
        if (middleware) {
          middleware.log.push(action.payload.log)
        }
      }
    },
  },
})

export const pipelineAction = slice.actions
export const selectAllPipelines = (state: RootState) => state.pipeline.pipelines

export default slice.reducer