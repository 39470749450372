import { MiddlewareDispatcher } from '../Middleware'
import { ResourcePipelineContext } from '../impl/ResourcePipelineContext'
import { colyseusClient } from '../../../colyseus'
import { log } from '../../../logger'
import { ModelLoadingMiddleware } from '../impl'
import { reduxStore } from '../../../store'
import { showErrorToast } from '../../../toast'
import { InRoomModelHandlingMiddleware } from '../impl/network/InRoomModelHandlingMiddleware'


export async function handleModelLoadingOnStart_NoLogin() {
  const loader = new MiddlewareDispatcher<ResourcePipelineContext>()

  // Read local cache
  loader.use(ModelLoadingMiddleware.RemoteResourceWrapperMiddleware)
  loader.use(ModelLoadingMiddleware.ReadLocalCacheMiddleware)
  loader.use(ModelLoadingMiddleware.DefaultModelFallbackMiddleware)
  loader.use(ModelLoadingMiddleware.DownloadResourceMiddleware)
  loader.use(ModelLoadingMiddleware.LoaderMiddleware)

  await loader.dispatch({
    targetId: 'self',
  })
}

export async function handleModelLoadingOnModelImport_NoLogin(files: File[]) {
  log('handleModelLoadingOnModelImport_NoLogin')

  const loader = new MiddlewareDispatcher<ResourcePipelineContext>()
  loader.use(ModelLoadingMiddleware.RemoteResourceWrapperMiddleware)

  // Read local cache
  loader.use(ModelLoadingMiddleware.LoaderMiddleware)

  // Only filtered files will be cached
  loader.use(ModelLoadingMiddleware.WriteLocalCacheMiddleware)
  wrapInRoomModelHandling(loader)

  await loader.dispatch({
    targetId: 'self',
    inputFiles: files,
    modelType: 'avatar.live2d.zip',
    version: Date.now(),
  })
}

export async function handleModelUploadOnRoomJoin_NoLogin() {
  const loader = new MiddlewareDispatcher<ResourcePipelineContext>()
  loader.use(ModelLoadingMiddleware.RemoteResourceWrapperMiddleware)

  // Read local cache
  loader.use(ModelLoadingMiddleware.ReadLocalCacheMiddleware)
  // If we don't cache, skip upload
  loader.use(ModelLoadingMiddleware.SkipModelLoadingModel)
  wrapInRoomModelHandling(loader)

  await loader.dispatch({
    targetId: 'self',
  })
}

function wrapInRoomModelHandling(loader: MiddlewareDispatcher<ResourcePipelineContext>) {
  // When we are in a room, prepare and upload the model
  // if (colyseusClient.currentRoom) {

    // This middleware will cancel the model uploading if we are not in a room
    loader.use(ModelLoadingMiddleware.InRoomModelHandlingMiddleware)
    // Compress
    loader.use(ModelLoadingMiddleware.CompressionMiddleware)

    // Impl a middleware to upload the encrypted model
    loader.use(ModelLoadingMiddleware.EncryptionMiddleware)
    loader.use(ModelLoadingMiddleware.RemoteResourceUploadUrlLocator_Anon)
    loader.use(ModelLoadingMiddleware.UploadResourceMiddleware)
    loader.use(ModelLoadingMiddleware.NotifyRoomNewModelUploadedMiddleware)
  // }
}


