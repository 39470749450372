import { init, track, Identify, identify, setGroup, setUserId, getDeviceId, setDeviceId } from '@amplitude/analytics-browser'
import { log, logInfo } from '../logger/logger'
import { reduxStore, tracker } from '../store/reduxStore'
import { showToast } from '../toast'
import copy from 'copy-to-clipboard';

const event = new Identify()

let amplitudeKey: string | null = '__NEXT_PUBLIC_AMPLITUDE_KEY__'
if (amplitudeKey === 'undefined')
  amplitudeKey = null

export function initAmplitude() {
  init(amplitudeKey! || '60c1242eb8e1baaae4eb239800880dac')
}

// trigger an event
export function events(event: string, eventProperties?: Record<string, any> | undefined) {
  if (event == 'undefined') return

  log?.(`add ${event} event`)
  if (eventProperties) {
    log?.(eventProperties)
    track(event, eventProperties)
    tracker?.event(
      event, eventProperties,
    )
  } else {
    track(event)
    tracker?.event(
      event, {},
    )
  }

  if (reduxStore.getState().status.debugMode) {
    logInfo('Select event: ' + event)
  }
}


export function eventsError(errorType: string) {
  events('error', {
    error_type: errorType
  })
}

// export function eventsError(event: string, eventProperties?: Record<string, any> | undefined) {
//   if (event == 'undefined') return
//   events(
//     'error#' + event,
//     'error_' + event,
//     eventProperties,
//   )
// }

export function setUser(userId: string) {
  setUserId(userId)
}

export function getDevice() {
  return getDeviceId()
}

export function setDevice(id: string) {
  setDeviceId(id)
}
// non-stackable record (e.g. value : 1)
export function setRecord(record: string, value: any) {
  event.set(record, value)
  identifyEvent()
}

// stackable record (e.g. first times : value : 1, 
//                        second times : value : 2, ... etc)
export function addRecord(record: string, value: any) {
  event.add(record, value)
  identifyEvent()
}

export function identifyEvent() {
  identify(event)
}

export function set_group() {
  setGroup('group_name', ['group_id', 'asdasd'])
}