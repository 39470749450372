import { RootState } from "../reduxStore";
import { VTubeStudioState } from "../../vtubestudio/vtubeStudioClient";
import { createLoadingSlice, LoadingState } from './GenericState'

export interface MocapState{
  // connected: boolean,
  vtubeStudioState?: VTubeStudioState;
}

// Define the initial state using that type
const initialState: MocapState = {
  // connected: false,
}

const slice = createLoadingSlice({
  name: 'mocapInput',
  initialState: initialState as LoadingState<MocapState>,
  reducers: {
    
  },
});

export const mocapActions = slice.actions
export const selectMocapConnected = (state: RootState) => state.mocap.status === 'finished'
export const selectMocapConnecting = (state: RootState) => state.mocap.status === 'loading'
export const selectVTubeStudioState = (state: RootState) => state.mocap.data?.vtubeStudioState

export default slice.reducer