import { growthbook } from '../../../utils/GrowthBook'
import { reduxStore, selectIsHost } from '../../store'
import { colyseusClient, patchLocalPlayerState, patchRemotePlayerState } from '../../colyseus'
import { updateTargetPlayerSelectorId } from './updateTargetPlayerSelectorId'
import { mapReduxPlayerIdToSessionId } from '../../../core'

export function handleHostOverridePosition(playerId: string, pointX: number, pointY: number) {
  if (!growthbook.isOn('host_override_position')) return
  if (!selectIsHost(reduxStore.getState())) return
  if (!colyseusClient.currentRoom) return

  updateTargetPlayerSelectorId(playerId)

  patchRemotePlayerState(
    mapReduxPlayerIdToSessionId(playerId),
    {
      x: pointX,
      y: pointY,
    }, true,
  )
}