import { colyseusClient } from '../colyseusClient'
import { lerp } from '../lerp'
import { reduxStore } from '../../store/reduxStore'
import { Entity } from '../../ecs/core/Entity'
import { Player } from 'playground-data-model'

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

/*
 * @param t deltaTime from last update
 */
export function update(t: number) {
  let deltaTime = t
  const remotePlayers = colyseusClient.currentRoom?.state.players

  // Loop through all local players
  for (const [key, reduxPlayer] of Object.entries(reduxStore.getState().players.players)) {
    let entity: Entity | undefined
    let player: Player | undefined

    if (key === 'self')
      entity = colyseusClient.currentEntity
    else
    // hook up remote entity
    {
      player = remotePlayers?.get(key)
      entity = player?.data.entity
    }

    if (!entity) continue

    const isSelf = key === 'self'
    const syncObjectTransform = reduxStore.getState().settings.syncObjectTransform

    // lerp self position, also update others if not syncObjectTransform
    // if (isSelf || (!isSelf && !syncObjectTransform)) {
    const pointX = reduxPlayer.syncState.x
    const pointY = reduxPlayer.syncState.y

    // const blendshapes = key === 'self' ? colyseusClient.parameters as Live2DParameter[] : player?.blendshapes!

    // const interpolatedXMovement = lerp(
    //   entity.object.x - reduxPlayer.syncState.x,
    //   reduxPlayer.syncState.movementX,
    //   deltaTime * 0.12,
    // )
    // const interpolatedYMovement = lerp(
    //   entity.object.y - reduxPlayer.syncState.y,
    //   reduxPlayer.syncState.movementY,
    //   deltaTime * 0.12,
    // )

    // Interpolation only for remote players
    const finalX = reduxPlayer.syncState.x + reduxPlayer.syncState.movementX
    const finalY = reduxPlayer.syncState.y + reduxPlayer.syncState.movementY
    const finalScaleX = reduxPlayer.syncState.scaleX + clamp(reduxPlayer.syncState.movementZ, -Math.abs(reduxPlayer.syncState.scaleX) + 0.01, Math.abs(reduxPlayer.syncState.scaleX))
    const finalScaleY = reduxPlayer.syncState.scaleY + clamp(reduxPlayer.syncState.movementZ, -Math.abs(reduxPlayer.syncState.scaleX) + 0.01, Math.abs(reduxPlayer.syncState.scaleX))

    // console.log('finalX', reduxPlayer.syncState.movementX)
    // console.log('finalY', reduxPlayer.syncState.movementY)
    // console.log('finalScaleX', reduxPlayer.syncState.movementZ)

    if (!isSelf && Math.sqrt(Math.pow(pointX - entity.object.x, 2) + Math.pow(pointY - entity.object.y, 2)) > 0.5) {
      entity.object.x = lerp(
        entity.object.x,
        finalX,
        deltaTime * 0.12,
      )
      entity.object.y = lerp(
        entity.object.y,
        finalY,
        deltaTime * 0.12,
      )
    } else {
      entity.object.x = lerp(
        entity.object.x,
        finalX,
        deltaTime * 0.12,
      )
      entity.object.y = lerp(
        entity.object.y,
        finalY,
        deltaTime * 0.12,
      )
    }

    // lerp all entity scale
    entity.object.scale.set(
      lerp(entity.object.scale.x, finalScaleX, deltaTime * 0.1),
      lerp(entity.object.scale.y, finalScaleY, deltaTime * 0.1),
    )

    // lerp all entity angle
    entity.object.angle = lerp(entity.object.angle, reduxPlayer.syncState.angle, deltaTime * 0.1)

    // Make sure when the entity is being dragged, we will not update the transform.
    if (entity.props.dragging) continue

    // Skip lerping local player
    if (!isSelf && syncObjectTransform) {
      entity.object.x = lerp(
        entity.object.x,
        reduxPlayer.syncState.x,
        deltaTime * 0.1,
      )

      entity.object.y = lerp(
        entity.object.y,
        reduxPlayer.syncState.y,
        deltaTime * 0.1,
      )
    }
  }
}
