import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../reduxStore";

interface Log {
  message: string
  timestamp: number
}

export interface LogState{
  logs: Log[],
}

// Define the initial state using that type
const initialState: LogState = {
  logs: [],
}

const slice = createSlice({
  name: 'logger',
  initialState,
  reducers: {
    logMessage: (state, action: PayloadAction<Log>) => {
      state.logs.push(action.payload)
      // Make sure array is limited to 1000 items
      if (state.logs.length > 1000) state.logs.shift()
    },
  },
});

export const { logMessage } = slice.actions
export const selectLogs = (state: RootState) => state.logger.logs

export default slice.reducer