import { Cubism2InternalModel, Cubism4InternalModel, Live2DModel, MotionPreloadStrategy } from 'pixi-live2d-display'
import { colyseusClient } from '../colyseus/colyseusClient'
import { patchRemotePlayerStateSelf, sendRoomEventSafe } from '../colyseus/multiplayer'
import { removeItemOnce } from '../colyseus/removeItemOnce'
import { lerp } from '../colyseus/lerp'
import { debugPanelEvent } from '../util/eventBus'
import { Entity } from '../ecs/core/Entity'
import { AvatarLoader, avatarLoaders } from '../model-loading/AvatarLoader'
import { ILive2DParameter } from 'vtubestudio'
import { log, logTimeEnd, logTimeStart } from '../logger/logger'
import JSZip, { file } from 'jszip'
import { events } from '../amplitude/amplitude'
import { reduxStore } from '../store/reduxStore'
import { FileFilter } from '../model-loading/FileFilter'
import { extractModelFileName } from '../model-loading/extractModelFileName'
import { settingsActions } from '../store'
import { breathAnimation, mouseInteract } from './Live2DAvatarSettings'
import { Rectangle } from 'pixi.js'

function lerpParams(
  currentValue: () => number,
  targetValue: number,
  deltaTime: number,
  smoothing: number,
  applyValue: (val: number) => void,
) {
  applyValue(lerp(currentValue(), targetValue, deltaTime * smoothing))
}

export type PixelPerfectProps = {
  cachedPixels: Uint8ClampedArray | undefined;
  trimmedRect: Rectangle;
};

export type LayerProps = {
  url: string;
  playerId: string;
  entity: Entity | undefined;
  color: string;
};

export type ColorOverlayProps = {
  colorData?: any;
  ColorOverlayEnable?: boolean;
};

let parameters = colyseusClient.parameters as ILive2DParameter[]

const nativeDecompressFile = true

export class Live2DAvatarLoader implements AvatarLoader {

  matchIcon(f: string) {
    return f.toLowerCase().endsWith('icon.png')
      || f.toLowerCase().endsWith('icon.jpeg')
      || f.toLowerCase().endsWith('icon.jpg')
      || f.toLowerCase().endsWith('icon.webp')
  }

  async loadIconFromZip(zip: File): Promise<File | undefined> {
    logTimeStart('Decompress Model')
    const jszip = await JSZip.loadAsync(zip)
    let filePaths: string[] = []
    const newFiles = []

    jszip.forEach((relativePath, file) => {
      if (!file.dir && this.filter.checkFile(relativePath)) {
        filePaths.push(relativePath)
      }
    })

    // const model3FilePath = filePaths.find((filePath) => filePath.endsWith('model3.json'))!
    // const model3File = await jszip.file(model3FilePath)!.async('string')
    // const settings = new Cubism4ModelSettings({ ...JSON.parse(model3File), url: '.' })

    const requiredFilePaths: string[] = [
      // model3FilePath,
      filePaths.find(f => this.matchIcon(f))!,
    ]

    // const transformedFilesPath = filePaths.map((path) =>
    //   path.slice(path.indexOf('/') + 1)
    // )
    //
    // // only consume the files defined in settings
    // for (const definedFile of settings.getDefinedFiles()) {
    //   const actualPath = decodeURI(definedFile)
    //
    //   const i = transformedFilesPath.indexOf(actualPath)
    //   if (i !== -1) {
    //     requiredFilePaths.push(filePaths[i])
    //   }
    // }

    filePaths = requiredFilePaths

    // logTimeStart('Decompress Model - 1')
    for (let i = 0; i < filePaths.length; i++) {
      const path = filePaths[i]

      const f = jszip.file(path)
      if (!f) continue
      const fileName = path.slice(path.indexOf('/') + 1)

      // logTimeStart('Decompress Model - 2')
      let blob: Blob
      blob = await f.async('blob')
      // logTimeEnd('Decompress Model - 2')

      newFiles.push(new File([blob], fileName))
    }
    // logTimeEnd('Decompress Model - 1')
    logTimeEnd('Decompress Model')

    if (newFiles.length > 0) {
      return newFiles[0]
    }

    return undefined

    // files = await this.filterFiles(newFiles)
    // files.push(...newFiles)
  }



  async loadAvatar(files: File[]): Promise<Entity | undefined> {
    let icon = files.find((f) => this.matchIcon(f.name))

    // Load Icon from zip
    if (!icon && files.length === 1 && files[0].name.endsWith('.zip')) {
      icon = await this.loadIconFromZip(files[0])
    }

    const liveModel = await Live2DModel.from(files, {
      motionPreload: MotionPreloadStrategy.ALL,
    })
    liveModel.scale.set(0.2)

    //TODO Enable back when we have a better way to handle this
    // liveModel.filters = [new colyseusClient.PIXI.filters.ColorMatrixFilter()];
    liveModel.anchor.set(0.5, 0.5)

    liveModel.autoInteract = false

    // Disable eye blink
    if (liveModel.internalModel instanceof Cubism4InternalModel) {
      //cubism 4.0
      liveModel.internalModel.eyeBlink = undefined
      breathAnimation(liveModel)
      if (reduxStore.getState().settings.maskBufferResolution != 256)
        liveModel.internalModel.renderer.setClippingMaskBufferSize(
          reduxStore.getState().settings.maskBufferResolution,
        )
    } else if (liveModel.internalModel instanceof Cubism2InternalModel) {
      //cubism 2.0
      liveModel.internalModel.eyeBlink = undefined
      breathAnimation(liveModel)
    }

    liveModel.x = window.innerWidth / 2
    liveModel.y = window.innerHeight / 2

    debugPanelEvent.emit('models-updated', liveModel)


    log('icon', icon)

    const entity = new Entity<PixelPerfectProps>(liveModel, () => {
      removeItemOnce(colyseusClient.allEntities!, entity)
      if (entity.icon)
        URL.revokeObjectURL(entity.icon!)
    })

    if (icon)
      entity.icon = URL.createObjectURL(icon)

    entity.props.name = extractModelFileName(files)
    log(entity.props.name)
    events('model', {
      name: entity.props.name == '' ? 'default_model' : entity.props.name,
    })
    colyseusClient.allEntities?.push(entity)

    entity.setupAnimate = (isRemote, playerId) => {
      entity.sessionId = isRemote ? playerId : 'self'

      const model = entity.object as Live2DModel

      //addDebugNameTag(playerId, model);
      // Local player
      const updateFn = model.internalModel.motionManager.update
      // model.internalModel.on('beforeModelUpdate', () => {
      //   breathAnimation(model)
      // })

      // Prevent default idle animation
      // https://guansss.github.io/pixi-live2d-display/motions_expressions/
      if (!reduxStore.getState().settings.playIdleAnimation)
        model.internalModel.motionManager.groups.idle = 'Ignore'

      if (!isRemote) {
        model.internalModel.motionManager.update = (
          m: object,
          now: DOMHighResTimeStamp,
        ) => {
          mouseInteract(model, entity.sessionId!)
          // https://github.com/guansss/pixi-live2d-display/issues/15
          parameters = colyseusClient.parameters as ILive2DParameter[]

          if (!parameters) {
            parameters = []
            // Apply default motion if vtube studio not connected
            // if (colyseusClient.playIdleAnimation)
            updateFn.apply(model.internalModel.motionManager, [m, now])

            if (model.internalModel instanceof Cubism4InternalModel) {
              const params =
                model.internalModel.coreModel.getModel().parameters

              for (let i = 0; i < params.count; i++) {
                // parameters.;
                parameters.push({
                  defaultValue: params.defaultValues[i],
                  max: params.maximumValues[i],
                  min: params.minimumValues[i],
                  name: params.ids[i],
                  value: params.values[i],
                })
              }//cubism 4.0


              // Sending the blendshapes to the server
              // if (colyseusClient.currentRoom) {
              //   sendRoomEventSafe('updateBlendShape', parameters)
              // }
            }
            return true
          }

          if (model.internalModel instanceof Cubism4InternalModel) {
            //cubism 4.0
            const coreModel = model.internalModel.coreModel
            parameters.forEach((parameter) => {
              coreModel.setParameterValueById(parameter.name, parameter.value)
            })
          } else if (model.internalModel instanceof Cubism2InternalModel) {
            //cubism 2.0
            const coreModel = model.internalModel.coreModel
            parameters.forEach((parameter) => {
              coreModel.setParamFloat(parameter.name, parameter.value)
            })
          }

          // Sending the blendshapes to the server
          // if (colyseusClient.currentRoom) {
          //   sendRoomEventSafe('updateBlendShape', parameters);
          // }
          return true
        }

        // Remote player
      } else {
        let lastUpdateTime = 0

        model.internalModel.motionManager.update = (
          m: object,
          now: DOMHighResTimeStamp,
        ) => {
          // Disable to block default motion update
          // https://github.com/guansss/pixi-live2d-display/issues/15
          // updateFn.apply(model.internalModel.motionManager, [m, now]);

          let deltaTime = now - lastUpdateTime
          lastUpdateTime = now

          const player =
            colyseusClient.currentRoom?.state.players.get(playerId)

          if (!player) return true

          mouseInteract(model, playerId)

          if (model) {
            const smoothing = 16

            var remoteParameters = player.blendshapes

            let getP: (name: string) => number
            let setP: (name: string, val: number, weight?: number) => void

            if (model.internalModel instanceof Cubism4InternalModel) {
              getP = model.internalModel.coreModel.getParameterValueById
              setP = model.internalModel.coreModel.setParameterValueById
            } else if (model.internalModel instanceof Cubism2InternalModel) {
              getP = model.internalModel.coreModel.getParamFloat
              setP = model.internalModel.coreModel.setParamFloat
            }

            remoteParameters?.forEach((remoteParam) => {
              lerpParams(
                () =>
                  getP.apply(model.internalModel.coreModel, [remoteParam.name]),
                remoteParam.value,
                deltaTime,
                smoothing,
                (val) =>
                  setP.apply(model.internalModel.coreModel, [
                    remoteParam.name,
                    val,
                  ]),
              )
            })
          }
          return true
        }
      }
    }

    entity.props.type = 'live2d'

    // //initialize color Overlay data
    // colyseusClient.TempcolorData.R = 1
    // colyseusClient.TempcolorData.G = 1
    // colyseusClient.TempcolorData.B = 1

    return entity
  }

  checkIfSupported(files: File[]): boolean {
    if (files.length === 1) {
      // Check if its a zip file
      return files[0].name.endsWith('.zip') || files[0].name.endsWith('live2d')
    } else {
      // Check if it has .model3.json
      return (
        files.find((file) => file.name.endsWith('.model3.json')) !== undefined
      )
    }
  }

  filter: FileFilter = new FileFilter(
    [],
    ['vtube.json', 'items_pinned_to_model.json', '.cmo3'],
  )

  // filter and process the dropped files
  async filterFiles<T extends File>(files: T[]): Promise<T[]> {
    let newFiles = this.filter.filterFiles(files)
    newFiles.map((file) => {
      // @ts-ignore
      let relativePath = file.path as string

      if (!relativePath) {
        relativePath = file.webkitRelativePath
      }

      if (!relativePath) {
        relativePath = file.name
      }

      // relative path should just be relative...
      if (relativePath.startsWith('/')) {
        relativePath = relativePath.slice(1)
      }

      // let's borrow this property...
      Object.defineProperty(file, 'webkitRelativePath', {
        value: relativePath,
      })
    })
    return newFiles
  }
}

avatarLoaders.live2d = new Live2DAvatarLoader()

export function updateBlendShape() {
  if (colyseusClient.currentRoom && parameters) {
    sendRoomEventSafe('updateBlendShape', parameters)
  }

  if (colyseusClient.trackingData) {
    const self = reduxStore.getState().players.players['self'].syncState
    patchRemotePlayerStateSelf({
      movementX: self.movementX,
      movementY: self.movementY,
      movementZ: self.movementZ,
    })
  }
}
