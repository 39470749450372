export async function encryptblob(
  blob: Blob,
): Promise<[Blob, string, JsonWebKey]> {
  let iv = crypto.getRandomValues(new Uint8Array(12));
  let algorithm = {
    name: 'AES-GCM',
    iv: iv,
  };

  let key = await crypto.subtle.generateKey(
    {
      name: 'AES-GCM',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  );

  let data = await blob.arrayBuffer();

  const result = await crypto.subtle.encrypt(algorithm, key, data);

  let exportedkey = await crypto.subtle.exportKey('jwk', key);

  return [new Blob([result]), iv.toString(), exportedkey];
}

export async function decryptblob(
  encblob: Blob,
  ivdata: string,
  exportedkey: string,
) {
  let key = await crypto.subtle.importKey(
    'jwk',
    {
      kty: 'oct',
      k: exportedkey,
      alg: 'A256GCM',
      ext: true,
      key_ops: ["encrypt", "decrypt"],
    },
    { name: 'AES-GCM' },
    true,
    ['encrypt', 'decrypt'],
  );

  let iv = new Uint8Array(ivdata.split(',').map((x) => parseInt(x)));

  let algorithm = {
    name: 'AES-GCM',
    iv: iv,
  };

  let data = await encblob.arrayBuffer();

  let decryptedData = await crypto.subtle.decrypt(algorithm, key, data);

  return new Blob([decryptedData]);
}
