import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../reduxStore";
import { BackgroundType } from "../../types/BackgroundState";
import { PlayerSyncState, Vector3 } from "playground-data-model";

export interface ShortcutState {
  state: Partial<PlayerSyncState>[];
  scene: Partial<PlayerSyncState>[];
  sceneName: string[]
}

// Define the initial state using that type
const initialState: ShortcutState = {
  state: [
    {} as PlayerSyncState,
    {} as PlayerSyncState,
    {} as PlayerSyncState,
    {} as PlayerSyncState,
  ],
  scene: [
    {} as PlayerSyncState,
    {} as PlayerSyncState,
    {} as PlayerSyncState,
    {} as PlayerSyncState,
  ],
  sceneName: ['', '', '', ''],
};

const slice = createSlice({
  name: "shortcutSettings",
  initialState,
  reducers: {
    setShortcutState: (
      state,
      action: PayloadAction<{ index: number; state: Partial<PlayerSyncState> }>
    ) => {
      state.state[action.payload.index] = action.payload.state;
    },
    setSceneState: (
      state,
      action: PayloadAction<{ index: number; scene: Partial<PlayerSyncState> }>
    ) => {
      state.scene[action.payload.index] = action.payload.scene;
    },
    setSceneName: (
      state,
      action: PayloadAction<{ index: number; sceneName: string }>
    ) => {
      state.sceneName[action.payload.index] = action.payload.sceneName;
    },
  },
});

export const shortcutKeyActions = slice.actions;
export const selectShortcutKeyState = (state: RootState, index: number) =>
  state.shortcutKey.state[index];
export const selectSceneState = (state: RootState, index: number) =>
  state.shortcutKey.scene[index];
export const selectSceneName = (state: RootState, index: number) =>
  state.shortcutKey.sceneName[index];
export const selectAllShortcutKey = (state: RootState) =>
  state.shortcutKey.state;

export default slice.reducer;
