import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../reduxStore";
import { RoomSyncState, defaultRoomSyncState, PlayerSyncState } from 'playground-data-model'

export interface RoomIdState {
  roomId: string | null
  sessionId: string | null
}

export interface RoomState extends RoomIdState {
  roomUserCount?: number
  loadedRoomUserCount?: number
  passwordProtected?: boolean
  syncRoomState: RoomSyncState
}

// Define the initial state using that type
const initialState: RoomState = {
  roomId: null,
  sessionId: null,
  roomUserCount: 0,
  loadedRoomUserCount: 0,
  passwordProtected: false,
  syncRoomState: defaultRoomSyncState
}

const slice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    joinRoomSuccess(state, action: PayloadAction<RoomIdState>) {
      state.roomId = action.payload.roomId;
      state.sessionId = action.payload.sessionId;
    },
    leaveRoom(state) {
      state.roomId = null;
      state.sessionId = null;
      state.roomUserCount = 0;
      state.passwordProtected = false;
    },
    updateRoomUserCount(state, action: PayloadAction<number>) {
      state.roomUserCount = action.payload;
    },
    updateLoadedRoomUserCount(state, action: PayloadAction<number>) {
      state.loadedRoomUserCount = action.payload;
    },
    updatePasswordProtected(state, action: PayloadAction<boolean>) {
      state.passwordProtected = action.payload;
    },

    onRoomStateUpdate(state, action: PayloadAction<{
      changes: {
        changedField: string
        changedValue: any
      }[]
    }>) {
        action.payload.changes.forEach((change) => {
          (state.syncRoomState as any)[change.changedField] = change.changedValue
        })
    },
  },
});

export const { leaveRoom, joinRoomSuccess, updateRoomUserCount, updatePasswordProtected, onRoomStateUpdate } = slice.actions
export const roomActions = slice.actions;
export const selectRoomId = (state: RootState) => state.room.roomId
export const selectIsInRoom = (state: RootState) => state.room.roomId !== null
export const selectSessionId = (state: RootState) => state.room.sessionId
export const selectIsHost = (state: RootState) => state.room.sessionId === state.room.syncRoomState.hostSessionId && state.room.roomId !== null
export const selectRoomUserCount = (state: RootState) => state.room.roomUserCount
export const selectLoadedRoomUserCount = (state: RootState) => state.room.loadedRoomUserCount
export const selectPasswordProtected = (state: RootState) => state.room.passwordProtected
export const selectHostId = (state: RootState) => state.room.syncRoomState.hostSessionId

export default slice.reducer