import { Cubism2InternalModel, Cubism4InternalModel, InternalModel, Live2DModel } from 'pixi-live2d-display'

import './AvatarLoader'
import '../live2d/Live2DAvatarLoader'

import { colyseusClient } from '../colyseus/colyseusClient'
import { sendRoomEventSafe } from '../colyseus/multiplayer'
import { log, logTimeEnd, logTimeStart } from '../logger/logger'
import { delay } from '../vtubestudio/delay'
import { Bounds, DisplayObject } from 'pixi.js'
import { Entity } from '../ecs/core/Entity'
import { getAvatarLoaderFromFiles } from './AvatarLoader'
import { PixelPerfectProps } from '../live2d/Live2DAvatarLoader'
import { showToastPromise } from '../toast/showToast'
import { reduxStore } from '../store/reduxStore'
import { playersActions } from '../store/reducers/playersSlice'
import * as PIXI from 'pixi.js'
import { roomActions } from '../../core/store'
import { getModelBoundingBox } from '../../components/scene/utils/generatePreviewImage'

export function setModelLayer(model: DisplayObject, layer: number) {
  model.zIndex = layer
  // debugPanelEvent.emit('layer-update', true);
}

export function getModelLayer(model: Live2DModel<InternalModel>): number {
  return model.zIndex
}

export function getFrontLayer(): number {
  var front: number = -999
  colyseusClient.allEntities?.map((entity) => {
    if (entity.object.zIndex > front) front = entity.object.zIndex
  })
  return front
}

export function getBackLayer(): number {
  var back: number = 999
  colyseusClient.allEntities?.map((entity) => {
    if (entity.object.zIndex < back) back = entity.object.zIndex
  })
  return back
}

export function updatePlayerLayer(
  entity: Entity,
  playerId: string,
  layer: number,
) {
  const mappedId = colyseusClient.currentRoom && colyseusClient.currentRoom.sessionId === playerId ? 'self' : playerId
  log(mappedId)
  reduxStore.dispatch(
    playersActions.onPlayerUpdate(
      {
        playerId: mappedId,
        changedField: 'z',
        changedValue: layer,
      },
    ),
  )
  if (entity) {
    setModelLayer(entity.object, layer)
  } else {
    console.error('Entity not found when updating layer, if this is intended, ignore this message')
  }

  if (colyseusClient.currentRoom && reduxStore.getState().settings.syncObjectTransform) {
    sendRoomEventSafe('updateLayer', {
      target: playerId,
      z: layer,
    })
  }
}

/**
 * Loads a resource files from avatar loader.
 * @param modelFiles
 * @throws {Error}
 */
export async function loadModelPure(
  modelFiles: File[],
): Promise<Entity> {
  let entity: Entity | undefined
  const loader = getAvatarLoaderFromFiles(modelFiles)!
  log(`loader name: ` + loader)

  logTimeStart('Load Avatar')
  entity = await loader.loadAvatar(modelFiles)
  logTimeEnd('Load Avatar')

  if (!entity) throw new Error('Failed to load model')

  return entity
}

export async function loadModel(
  modelFiles: File[],
): Promise<Entity | undefined> {
  const p = new Promise<Entity | undefined>(async (resolve, reject) => {
    let entity: Entity | undefined

    const loader = getAvatarLoaderFromFiles(modelFiles)!
    log(modelFiles)
    log(`loader name: ` + loader)
    try {
      await delay(100)
      logTimeStart('Load Avatar')
      entity = await loader.loadAvatar(modelFiles)
      logTimeEnd('Load Avatar')
    } catch (e) {
      console.warn(e)
      reject(e)
    } finally {
      resolve(entity)
    }
  })

  return await showToastPromise(
    p,
    {
      pending: 'Loading model...',
      success: 'Model loaded',
      error: 'Error loading model',
    },
    {
      autoClose: 1000,
    },
  )
}

export async function trimModelAsync(entity: Entity) {
  const externalModel = entity.object as Live2DModel<InternalModel>

  // TODO needs more checking, i have used request animation frame in the upper calls, so to make sure this will not run in background

  // if (externalModel instanceof Cubism2InternalModel) {
  //   const cubismModel = externalModel.internalModel as Cubism2InternalModel;
  //   const getRendered = async () => {
  //     return await new Promise<boolean>(async (resolve) => {
  //       if (!cubismModel.coreModel.drawParamWebGL.firstDraw) {
  //         resolve(true);
  //       }
  //     });
  //   };
  //   await getRendered();
  // } else if (externalModel instanceof Cubism4InternalModel) {
  //   const cubismModel = externalModel.internalModel as Cubism4InternalModel;
  //   const getRendered = async () => {
  //     return await new Promise<boolean>(async (resolve) => {
  //       if (!cubismModel.renderer.firstDraw) {
  //         resolve(true);
  //       }
  //     });
  //   };
  //   await getRendered();
  // }
  // await delay(100);

  let url: string | null
  if (entity.icon)
    url = entity.icon
  else{
    // url = await trimModelHitbox(entity, true)
    // let trimRect = getModelBoundingBox(entity);
    let largerSide = 0
    if (externalModel.internalModel.width > externalModel.internalModel.height){
      largerSide = externalModel.internalModel.width
    }else{
      largerSide = externalModel.internalModel.height
    }
    const trim = getModelBoundingBox(entity);
    url = takePhoto(entity, largerSide / 70, {x: trim.worldLeft, y: trim.worldTop, width: trim.worldRight, height: trim.worldBottom})
    // url = takePhoto(entity, largerSide / 50)

  }
  if (url) {
    reduxStore.dispatch(playersActions.onPlayerIconUpdate({
      playerId: entity.props.playerId === '' ? 'self' : entity.props.playerId,
      iconUrl: url,
    }))
  }
  entity.object.cacheAsBitmap = false
}

export async function animateModel(
  entity: Entity,
  isRemote: boolean,
  playerId: string,
) {
  if (!isRemote) {
    // Make sure self model is in the top most layer.
    // entity.object.zIndex = 99;
  }
  entity.setupAnimate?.(isRemote, playerId)
  entity.props.playerId = playerId

  // Calling this function in animation frame to make sure it runs in foreground
  requestAnimationFrame(
    () => {
      log('Getting photo snapshot')
      trimModelAsync(entity)
      reduxStore.dispatch(roomActions.updateLoadedRoomUserCount(reduxStore.getState().room.loadedRoomUserCount! + 1))
    },
  )
}

function addDebugNameTag(playerId: string, model: Live2DModel<InternalModel>) {
  let text = new PIXI.Text(playerId === '' ? 'you' : playerId, {
    fontFamily: 'Arial',
    fontSize: 80,
    fill: 0xff1010,
    // align: 'bottom',
  })
  text.x = model.internalModel.width / 2
  text.y = model.internalModel.height
  model.addChild(text)
}

function addFrame(model: Live2DModel) {
  const foreground = colyseusClient.PIXI.Sprite.from(
    colyseusClient.PIXI.Texture.WHITE,
  )
  foreground.width = model.internalModel.width
  foreground.height = model.internalModel.height
  foreground.alpha = 0.2

  model.addChild(foreground)
}

export async function trimModelHitbox(
  entity: Entity,
  needPhoto: boolean,
): Promise<string | null> {
  const model = entity.object as Live2DModel<InternalModel>

  if (model.x < 0 || model.x > window.innerWidth || model.y < 0 || model.y > window.innerHeight) {
    model.position.x = window.innerWidth / 2
    model.position.y = window.innerHeight / 2
    await delay(10)
  }

  // maybe no internal model width?
  let bounds = model.getBounds(true)
  log(bounds)
  let localBounds = model.getLocalBounds()
  log(localBounds)

  let region: PIXI.Rectangle = new colyseusClient.PIXI.Rectangle()
  if (bounds.x < 0)
    region.x = bounds.x
  else
    region.x = localBounds.x
  if (bounds.y < 0)
    region.y = bounds.y
  else
    region.y = localBounds.y

  region.width = localBounds.width + Math.abs(bounds.x)
  region.height = localBounds.height + Math.abs(bounds.y)
  const renderTexture: PIXI.RenderTexture =
    colyseusClient.app!.renderer.generateTexture(
      model,
      colyseusClient.PIXI.SCALE_MODES.LINEAR,
      1,
      new colyseusClient.PIXI.Rectangle(region.x, region.y, region.width, region.height),
    )
  const sprite: PIXI.Sprite = new colyseusClient.PIXI.Sprite(renderTexture)
  sprite.scale.set(1 / model.scale.x, 1 / model.scale.y)
  // when scale = 0.2, pos x = -1550, y = -350
  sprite.position.x =
    (-model.position.x * 1) / model.scale.x + sprite.texture.width * 0.5
  sprite.position.y =
    (-model.position.y * 1) / model.scale.y + sprite.texture.height * 0.5

  let image: Uint8Array = colyseusClient.app!.renderer.plugins.extract.pixels(
    sprite,
    colyseusClient.PIXI.FORMATS.RGBA,
    colyseusClient.PIXI.MSAA_QUALITY.LOW,
  )

  const count: number = image.length
  const width: number = Math.round(renderTexture.width)
  const height: number = Math.round(renderTexture.height)
  var leftCut: number = 0
  for (var i = 0; i < width * 4; i = i + 4) {
    var vertical: number = 0
    while (image[i + vertical * width * 4 + 3] === 0 && vertical < height) {
      vertical++
    }
    if (vertical === height || height - vertical < 5) {
      leftCut++
    } else {
      break
    }
  }
  var rightCut: number = 0
  for (var i = width * 4; i > 0; i = i - 4) {
    var vertical: number = 0
    while (image[i + vertical * width * 4 + 3] === 0 && vertical < height) {
      vertical++
    }
    if (vertical === height || height - vertical < 5) {
      rightCut++
    } else {
      break
    }
  }
  var topCut: number = 0
  for (var i = 0; i < height * width * 4 - width * 4; i = i + width * 4) {
    var horizontal: number = 0
    while (image[i + horizontal * 4 + 3] === 0 && horizontal < width) {
      horizontal++
    }
    if (horizontal === width || width - horizontal < 5) {
      topCut++
    } else {
      break
    }
  }
  var downCut: number = 0
  for (var i = height * width * 4 - width * 4; i > 0; i = i - width * 4) {
    var horizontal: number = 0
    while (image[i + horizontal * 4 + 3] === 0 && horizontal < width) {
      horizontal++
    }
    if (horizontal === width || width - horizontal < 5) {
      downCut++
    } else {
      break
    }
  }

  var rightTotal: number = width - rightCut - leftCut
  var downTotal: number = height - downCut - topCut
  log(
    'LeftCut:' +
    leftCut +
    ', RightCut: ' +
    rightTotal +
    ', TopCut: ' +
    topCut +
    ', DownCut: ' +
    downTotal,
  )
  var rect: PIXI.Rectangle = new colyseusClient.PIXI.Rectangle(
    leftCut,
    topCut,
    rightTotal,
    downTotal,
  )

  let url: string | null = null
  if (needPhoto) {
    url = takePhotoFromTrim(sprite, rect)
  }
  renderTexture.destroy(true)
  sprite.destroy({ children: true, texture: true, baseTexture: true })

  model.hitArea = rect

  let pixelEntity = entity as Entity<PixelPerfectProps>
  pixelEntity.props.trimmedRect = rect

  return url
}

const useTrimmedDownTexture = true

export function takePhotoFromTrim(fromSprite: PIXI.Sprite, rect?: PIXI.Rectangle): string {
  logTimeStart('Thumbnail rendering')
  const renderer = colyseusClient.app!.renderer

  let photo: PIXI.RenderTexture =
    renderer.generateTexture(
      fromSprite,
      colyseusClient.PIXI.SCALE_MODES.LINEAR,
      1,
      rect,
    )

  if (useTrimmedDownTexture) {
    const targetSize = 80
    const scale = targetSize / rect!.width
    const sprite: PIXI.Sprite = new colyseusClient.PIXI.Sprite(photo)
    sprite.scale.set(scale, scale)
    const final = colyseusClient.PIXI.RenderTexture.create({
      height: targetSize,
      width: targetSize,
      scaleMode: colyseusClient.PIXI.SCALE_MODES.NEAREST,
    })
    renderer.render(sprite, {
      renderTexture: final,
    })

    let url = colyseusClient.app!.renderer.plugins.extract.base64(final, 'image/webp', 0.2)
    photo.destroy(true)
    final.destroy(true)
    logTimeEnd('Thumbnail rendering')
    return url
  } else {
    let url = colyseusClient.app!.renderer.plugins.extract.base64(photo, 'image/webp', 0.2)
    photo.destroy(true)
    logTimeEnd('Thumbnail rendering')
    return url
  }
}



export function takePhoto(entity: Entity, compression: number, trim?: {x: number, y: number, width: number, height: number}){
  const externalModel = entity.object as Live2DModel<InternalModel>
  let pos: {x: number, y: number, width: number, height: number} | undefined = undefined
  let renderTexture: PIXI.RenderTexture | undefined = undefined
  const bounds = externalModel.getBounds(true)

  let trimRect: PIXI.Rectangle | undefined = undefined
  if (trim){
    // console.log(trim)
    renderTexture = colyseusClient.PIXI.RenderTexture.create({width: Math.abs((trim.width - trim.x)) * Math.abs((1 / entity.object.scale.x) / compression), height: Math.abs(trim.height - trim.y) * Math.abs((1 / entity.object.scale.y)) / compression})
    pos = {x: trim.x, y: trim.y, width: trim.width, height: trim.height}
    if (entity.object.scale.x > 0)    trimRect = new colyseusClient.PIXI.Rectangle(trim.x, trim.y, Math.abs(trim.width - bounds.x), Math.abs(trim.height - bounds.y))
    else trimRect = new colyseusClient.PIXI.Rectangle(trim.width, trim.height, Math.abs(trim.x - bounds.x), Math.abs(trim.y - bounds.y))
  }else
  {
    renderTexture = colyseusClient.PIXI.RenderTexture.create({width: bounds.width * Math.abs((1 / entity.object.scale.x)) / compression, height: bounds.height * Math.abs((1 / entity.object.scale.y)) / compression})
    pos = {x: bounds.x, y: bounds.y, width: bounds.width, height: bounds.height}
    trimRect = new colyseusClient.PIXI.Rectangle(bounds.x, bounds.y, externalModel.internalModel.width, externalModel.internalModel.height)
  }
  const entityTexture: PIXI.RenderTexture | undefined = colyseusClient.app?.renderer.generateTexture(
    entity.object,
    colyseusClient.PIXI.SCALE_MODES.NEAREST,
    1,
    trimRect
  )
  const copiedSprite = new colyseusClient.PIXI.Sprite(entityTexture)
  let offsetX = -entity.object.x + bounds.width * 0.5 + bounds.x
  let offsetY = -entity.object.y + bounds.height * 0.5 + bounds.y

  copiedSprite.position.set(offsetX, offsetY)
  copiedSprite.scale.set(Math.abs((1 / entity.object.scale.x) / compression))

  colyseusClient.app?.renderer.render(copiedSprite, {renderTexture: renderTexture});
  copiedSprite.destroy({baseTexture: true, texture: true, children: true})
  // colyseusClient.app?.stage.addChild(copiedSprite)

  let url = colyseusClient.app!.renderer.plugins.extract.base64(renderTexture, 'image/webp', 0.2)
  renderTexture.destroy(true)
  return url
}
