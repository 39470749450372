// extract key types of en
export type EnKeys = keyof typeof en

export const en = {
  // loading screen
  prepare_playground: 'Preparing your collab experience...',

  // first time tips dialog
  tips_dialog_title_1: 'Welcome!',
  tips_dialog_title_1_description:
    'Playground is a collab stream tool designed with VTubers in mind. Take a look around!',
  tips_dialog_title_2: 'Drag and drop model',
  tips_dialog_title_2_description:
    'Import your Live2D model for quick and easy local access. ',
  tips_dialog_title_3: 'Track Motion',
  tips_dialog_title_3_description:
    'Enable camera and sync motion from VTube Studio.',
  tips_dialog_title_4: 'Set background transparency',
  tips_dialog_title_4_description:
    'Enable direct transparency in OBS or select color for chroma key.',
  tips_dialog_title_5: 'Resize models and reorder layers',
  tips_dialog_title_5_description:
    'Scroll up or down over your model to resize. Right click menu or drag tiles to reorder.',
  tips_dialog_title_6: 'Create and share rooms',
  tips_dialog_title_6_description:
    'Create room and copy the room link to share with your friends for easy collab.',
  tips_dialog_title_7: 'Happy collaborating! ~',

  // two button in home page
  create_new_room: 'New Room',
  join_room: 'Join Room',
  create_new_room_discription: 'Secure room for collab stream',

  // bottom left login text
  sign_in_text: 'Sign in',
  or: 'or',
  create_account_text: 'Create account',

  signed_in_text: 'Signed in as',
  sign_out_text: 'Sign out',

  // sign in pop up
  sign_in_title: 'Sign in',
  sign_in_with_twitter: 'Sign in with Twitter',

  // create account pop up
  create_account_title: 'Join Playground with your VTuber friends',
  create_account_description:
    'Create rooms, trusted friends, save your settings, and more!',
  create_account_button_title: 'Sign up with Twitter',

  // create_room_settings
  option_title: 'Choose an option',
  create_and_copy_link: 'Create and copy link',
  room_settings: 'Room settings',

  // join room button
  join_room_placeholder: 'Enter room id or url',

  // join room pop up
  join_room_title: 'Before You Join',
  join_room_description1: `Anyone who has the room link can access the shared room and see you and other people’s models.`,
  join_room_description2: `Do not join suspicious room links. Always ensure it’s coming from a trusted source.`,

  // join room with password
  join_room_with_password_title: 'Enter room password',

  // leave room pop up
  leave_room_title: 'Leave current room?',
  leave_room_description1: 'Are you sure you want to leave the current room?',
  leave_room_description2:
    'Your encrypted model files will be removed from the server after you leave the room.',

  // inside room settings
  room_settings_title: 'Room settings',
  room_entry_password: 'Room Entry Password',
  encryption_info: 'Encryption Info',
  encryption_info_description1: 'Model: AES-GSM 256',
  encryption_info_description2:
    'End-to-end: ECDH (Elliptic-curve Diffie–Hellman)',
  room_settings_confirm: 'Create and Copy Link',

  // create room toast
  create_room_pending: 'Creating room ...',
  create_room_success: 'Room created 👌',
  create_room_error: 'Creation failed! Try again 🤯',

  //=============General Dialog stuff=============//
  confirm_button_text: 'Confirm',
  cancel_button_text: 'Cancel',

  //=============inside a room=============//
  // bottom text
  leave_room_button: 'Leave',
  leave_room_tooltip: 'Leave Room',
  invite_friends_button: 'Invite friends',
  room_settings_button_copied_feedback: 'Copied!',

  // share room pop up
  share_room_title: 'Share Room Info',
  share_room_tooltip: 'Copy Room Link',
  share_room_description1: `Anyone who has the room link can access this room and see you other people's models.`,
  share_room_description2: 'Share only with people you trust.',

  // top right hand corner text
  // 3 dot menu
  toggle_ui: 'Toggle UI',
  sync_position: 'Sync Position',
  preferences: 'Preferences',
  news: 'News',
  language: 'Language',

  // tooltips
  help_tooltip: 'Help',
  logger_tooltips: 'Logger',
  discord_tooltips: 'Discord',
  more_tooltips: 'More',

  // left hand side bar
  // tooltips
  model_tooltip: 'Model',
  motion_track_tooltip: 'Motion Tracking',
  backgroundColor_tooltip: 'Background Color',
  sceneSwitching_tooltip: 'Scene Switching',
  streaming_tooltip: 'Streaming',

  // import model
  import_model_zip: 'Import Model (.zip)',
  import_model_folder: 'Import Model (folder)',
  clear_model: 'Clear Model',
  import_model_description:
    'Drag and drop Live2D folder or .zip to replace model',

  // motion tracking
  motion_tracking_description: 'Choose a method',
  mouse_interact: 'Mouse Interact',

  // VTube Studio connection
  vts_tipdialog_title: 'Enable Playground plugin in VTS',
  vts_tipdialog_message:
    'To allow motion tracking, start API (allow plugin) in VTS and return here to sync. ',
  vts_connecting_notice:
    'In VTube Studio, allow [Playground | Avatech] plugin.',

  // Scene
  scene_select_title: 'Scene',
  color_overlay: 'Color Overlay',

  // Streaming Integration
  streaming_integration_description: 'Streaming Integration',
  setup_with_obs: 'Setup with OBS',
  setup_with_streamlabs: 'Setup with Streamlabs',
  streaming_integration_manual: 'Manual Setup',
  setup_with_url: 'Copy Browser Source Link',

  // Streaming Setup Pop up
  streaming_setup_title: 'Setup with streaming software',
}
