import { logMiddlewareEnd, logMiddleware, logMiddlewareStart } from "./impl/util/middlewareLogger";
import { ResourcePipelineContext } from './impl/ResourcePipelineContext'
import { MiddlewareState, pipelineAction, reduxStore } from '../../store'
import { ModelLoadingMiddleware } from './impl'

/**
 * 'next' function, passed to a middleware
 */
export type Next = () => void | Promise<void>;

/**
 * A middleware
 */
export type Middleware<T> =
  (context: T, next: Next) => Promise<void> | void;

type MiddlewareInstance<T extends ResourcePipelineContext> = {
  middleware: Middleware<T>,
  label: string,
  id: string,
}

export function getRandomId() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

/**
 * A middleware container and invoker
 */
export class MiddlewareDispatcher<T extends ResourcePipelineContext> {
  middlewares: MiddlewareInstance<T>[];

  constructor() {
    this.middlewares = [];
  }

  /**
   * Add a middleware function.
   */
  use(mw: Middleware<T>): void {
    const mwName = (Object.keys(ModelLoadingMiddleware) as (keyof typeof ModelLoadingMiddleware)[]).find((key) => {
      return ModelLoadingMiddleware[key] === mw;
    });

    this.middlewares.push({
      middleware: mw,
      id: getRandomId(),
      label: mwName ?? 'Anonymous',
    });
  }

  /**
   * Execute the chain of middlewares, in the order they were added on a
   * given Context.
   */
  dispatch(context: T): Promise<void> {
    context.pipelineId = getRandomId();
    context.pipelineName = 'Resource loading [' + (context.targetId) + ']'
    const middlewareStates: MiddlewareState[] = this.middlewares.map(mw => ({
      id: mw.id,
      label: mw.label,
      status: 'not-started',
      log: [],
    }))
    reduxStore.dispatch(
      pipelineAction.addPipeline({
        id: context.pipelineId,
        name: context.pipelineName,
        middleware: middlewareStates,
      })
    )
    return invokeMiddlewares(context, this)
  }
}

/**
 * Helper function for invoking a chain of middlewares on a context.
 */
async function invokeMiddlewares<T extends ResourcePipelineContext>(context: T, d: MiddlewareDispatcher<T>): Promise<void> {
  if (!d.middlewares.length) return;

  const mw = d.middlewares[0].middleware;
  context.pipelineNameCurrentMWId = d.middlewares[0].id;

  const middleId = context.pipelineNameCurrentMWId
  console.log('Middleware : ' + middleId + ' : start')

  logMiddlewareStart(context,middleId)
  
  const result = await mw(context, async () => {
    logMiddlewareEnd(context, middleId)
    console.log('Middleware : ' + middleId + ' : end')

    d.middlewares = d.middlewares.slice(1)
    await invokeMiddlewares(context, d);
  })
  // Ensure the last middleware is ended with status if it is still stuck in the loading state, it might actually be the one that skipped the next part
  logMiddlewareEnd(context, middleId)

  return result
}
