import { getColyseusUrl } from '../colyseus/getColyseusUrl'
import { supabase } from './client'
import { showToast } from '../toast/showToast'

export async function fetchUrlWithSupabaseSession(input: RequestInfo, init?: RequestInit) : Promise<Response | undefined> {
  let result: Response
  try {
    result = await fetch(getColyseusUrl()
      .replace('ws', 'http')
      .replace('wss', 'https') + input, {
      ...init,
      headers: {
        'Authorization': 'Bearer ' + supabase.auth.session()?.access_token,
      },
    })
  } catch (e) {
    showToast(
      'Error fetching endpoint',
    )
    return undefined
  }
  return result
}

export async function fetchUrlDirectly(input: RequestInfo, init?: RequestInit) : Promise<Response | undefined> {
  let result: Response
  try {
    result = await fetch(getColyseusUrl()
      .replace('ws', 'http')
      .replace('wss', 'https') + input, {
      ...init,
      // headers: {
      //   'Authorization': 'Bearer ' + supabase.auth.session()?.access_token,
      // },
    })
  } catch (e) {
    showToast(
      'Error fetching endpoint',
    )
    return undefined
  }
  return result
}