import { ResourcePipelineContext } from '../ResourcePipelineContext'
import { Next } from '../../Middleware'
import { beginRemoteResourceRequest, globalBlobQueue, updateStatusProgress } from '../../../../store'
import { defaultModelUrl } from '../../../../colyseus'
import { handleFileOperation } from '../util/HandleFileOperation'
import { ModelType } from 'playground-data-model'
import { logMiddleware } from '../util/middlewareLogger'

export async function DownloadResourceMiddleware(context: ResourcePipelineContext, next: Next): Promise<void> {
  // Skip if there is no model download url
  if (!context.modelDownloadUrl) return next()
  if (!context.modelDownloadUrl.startsWith('http')) throw new Error('modelDownloadUrl should start with http..')

  if (context.inputFiles) throw new Error('Wasn\'t expecting inputFiles, are you sure this is called in the right order?')
  if (!context.modelName) throw new Error('modelName is required')

  let blob = await handleFileOperation(context.targetId, context.modelDownloadUrl, 'GET',
    (req) => {
      globalBlobQueue[context.targetId].request = req

      req.responseType = 'blob'
      req.send()
    },
    false,
  ).then((req) => req.response)

  context.inputFiles = [new File([blob], getFileName(context.modelType, context.modelName))]
  context.encryptedFile = blob
  logMiddleware(context,context.encryptedFile?.size)
  return next()
}

export function getFileName(type?: ModelType, modelName?: string): string {
  let fileName = modelName ?? 'model'
  fileName += '.' + (type ?? 'avatar.live2d.zip')
  return fileName
}
