import { ResourcePipelineContext } from '../ResourcePipelineContext'
import { Next } from '../../Middleware'
import { defaultModelUrl } from '../../../../colyseus'

export async function DefaultModelFallbackMiddleware(context: ResourcePipelineContext, next: Next): Promise<void> {
  // Skip if there is input files
  if (context.inputFiles) return next()

  // Skip if there is modelDownloadUrl
  if (context.modelDownloadUrl) return next()

  context.modelDownloadUrl = defaultModelUrl
  context.modelName = 'default.model.zip'
  context.bypassDecryption = true
  context.bypassCache = true

  return next()
}