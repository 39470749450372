// const items = []

// give me a list of emojis in array
import { logMessage } from "../store/reducers/logSlice";
import { reduxStore } from "../store/reduxStore";

const emojis = [
  "😀",
  "😃",
  "😄",
  "😁",
  "😆",
  "😅",
  "🤣",
  "😂",
  "🙂",
  "🙃",
  "😉",
  "😊",
  "😇",
];

const emoji = emojis[Math.floor(Math.random() * emojis.length)];

let log: (message?: any, ...optionalParams: any[]) => void;

if (process.env.NODE_ENV === "development") {
  // log = console.log.bind(window.console, emoji + ' ->')
  log = function (message?: any, ...optionalParams: any[]) {};
} else {
  log = function (message?: any, ...optionalParams: any[]) {};
}

export { log };

export function logTrace(...data: any[]) {
  if (process.env.NODE_ENV === "development") {
    // if (data.length > 1) console.log(...data);
    // else

    console.groupCollapsed(emoji + " ->", ...data);
    console.trace();
    console.groupEnd();

    // console.log(emojis[Math.floor(Math.random() * emojis.length)], ...data);
  }
}

const timers: {
  [key: string]: number;
} = {};

function timer(name: string) {
  timers[name + "_start"] = window.performance.now();
}

function timerEnd(name: string): number {
  if (!timers[name + "_start"]) return -1;

  const time = window.performance.now() - timers[name + "_start"];

  // const amount = timers[name + '_amount'] = timers[name + '_amount'] ? timers[name + '_amount'] + 1 : 1
  // const sum = timers[name + '_sum'] = timers[name + '_sum'] ? timers[name + '_sum'] + time : time
  // timers[name + '_avg'] = sum / amount

  delete timers[name + "_start"];
  return time;
}

export function logTimeStart(label: string) {
  if (process.env.NODE_ENV === "development") {
    console.time(label);
  }
  timer(label);
}

export function logInfo(message: string) {
  reduxStore.dispatch(
    logMessage({
      message: message,
      timestamp: Date.now(),
    })
  );
}

export function logTimeEnd(label: string): number {
  //Mw stands for middleware
  if (process.env.NODE_ENV === "development") {
    console.timeEnd(label);
  }
  const timeTaken = timerEnd(label);
    reduxStore.dispatch(
      logMessage({
        message: `${label} took ${timeTaken.toFixed(2)}ms`,
        timestamp: Date.now(),
      })
    );
  return timeTaken;
}

export function logMiddlewareTimeStart(label: string) {
  if (process.env.NODE_ENV === "development") {
    console.time(label);
  }
  timer(label);
}

export function logMiddlewareTimeEnd(label: string): number {
  if (process.env.NODE_ENV === "development") {
    console.timeEnd(label);
  }
  const timeTaken = timerEnd(label);

  return timeTaken;
}
