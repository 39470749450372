// import { log } from '../logger/logger'

export class FileFilter {
  whitelist: string[] = []
  blacklist: string[] = []

  constructor(whitelist?: string[], blacklist?: string[]) {
    if (whitelist)
      this.whitelist = whitelist
    if (blacklist)
      this.blacklist = blacklist
  }

  checkFile(name: string): boolean {
    if (this.blacklist.length > 0) {
      for (let i = 0; i < this.blacklist.length; i++) {
        if (name.endsWith(this.blacklist[i])) {
          // log('Black listed : ' + name)
          return false
        }
      }
    }

    if (this.whitelist.length > 0) {
      for (let i = 0; i < this.whitelist.length; i++) {
        if (name.endsWith(this.whitelist[i])) {
          // log('White listed : ' + name)
          return true
        }
      }
      return false
    }

    return true
  }

  filterFiles<T extends File>(files: T[]): T[] {
    return files.filter((file) => {
      return this.checkFile(file.name)
    })
  }
}