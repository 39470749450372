import { log } from '../logger';
import { reduxStore } from '../store'

const serverUrl = '__MULTIPLAYER_SERVER_URL__'

function getSavedColyseusUrl() {
  const savedColyseusUrl = localStorage.getItem('override_server_url')
  if (savedColyseusUrl) {
    return savedColyseusUrl
  }
  return serverUrl
}

export function saveColyseusUrl(url: string) {
  localStorage.setItem('override_server_url', url)
}

export function getColyseusUrl() {
  return getSavedColyseusUrl()

  // let returnUrl = serverUrl
  //
  // if (reduxStore.getState().settings.overrideServerUrl)
  //   returnUrl = reduxStore.getState().settings.overrideServerUrl!
  // log(returnUrl)
  // return  returnUrl
}