import { ResourcePipelineContext } from '../ResourcePipelineContext'
import { Next } from '../../Middleware'
import { decryptblob, encryptblob } from '../../../../util'
import { ModelDecryptKey } from 'playground-data-model'
import { updateStatus } from '../../../../store'
import { colyseusClient } from '../../../../colyseus'
import { log } from '../../../../logger'
import { getFileName } from '../network/DownloadResourceMiddleware'
import { logMiddleware } from '../util/middlewareLogger'

export async function EncryptionMiddleware(context: ResourcePipelineContext, next: Next): Promise<void> {
  updateStatus(context.targetId, 'encrypting')

  if (!context.compressedFile)
    throw new Error('No compressed file')

  const [blob, iv, key] = await encryptblob(context.compressedFile)

  context.decryptKey = { iv, key: key.k! }
  context.rawModelKey = btoa(JSON.stringify(context.decryptKey))
  context.encryptedFile = blob
  if (context.decryptKey)
    // update local decrypt key

    colyseusClient.modelDecryptKey = context.decryptKey

  return next()
}

/**
 * Decrypt the encryptedFile into compressedFile
 * @param context
 * @param next
 * @constructor
 */
export async function DecryptionMiddleware(context: ResourcePipelineContext, next: Next): Promise<void> {
  if (context.bypassDecryption) {
    logMiddleware(context,'by passing, bypassDecryption')
    updateStatus(context.targetId, 'passed')
    return next()
  }

  if (!context.modelName) throw new Error('No model name')

  // Later add back decrypting
  updateStatus(context.targetId, 'decrypting')

  if (!context.encryptedFile) {
    logMiddleware(context,'by passing, no encrypted file')
    updateStatus(context.targetId, 'passed')
    return next()
  }

  // Parsing the raw decrypt key out to decrypt key
  if (context.rawModelKey)
    context.decryptKey = decodeDecryptKey(context.rawModelKey)

  if (!context.decryptKey) {
    updateStatus(context.targetId, 'passed')
    throw new Error('No decrypt key')
  }

  const decryptKey = context.decryptKey
  if (decryptKey) {
    try {
      context.compressedFile = await decryptblob(context.encryptedFile, decryptKey.iv, decryptKey.key) //this already have problem
      context.inputFiles = [new File([context.compressedFile], getFileName(context.modelType, context.modelName))]
      context.filteredFiles = context.inputFiles
    } catch (error: any) {
      log(error)
      log(error.message)
      log(context)
      log('Unable to decrypt the files')
      throw new Error('Unable to decrypt the files')
    }
    log(context)
  }

  updateStatus(context.targetId, 'passed')

  return next()
}

export function decodeDecryptKey(rawModelKey: string): ModelDecryptKey {
  return JSON.parse(atob(rawModelKey)) as ModelDecryptKey
}