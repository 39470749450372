import { log } from '../../../../logger'
import { updateStatus, updateStatusProgress } from '../../../../store'
import { formatBytes } from '../../../formatBytes'

export function handleFileOperation(targetId: string,
                                    url: string,
                                    method: string | 'GET' | 'POST',
                                    onReqReady: (req: XMLHttpRequest) => void,
                                    isUpload: boolean = true,
): Promise<XMLHttpRequest> {
  return new Promise(async (resolve, reject) => {
    const req = new XMLHttpRequest()

    if (isUpload) {
      req.upload.onprogress = updateProgress
      req.upload.onerror = onError
      req.upload.onabort = onError
      req.upload.ontimeout = onError
    } else {
      req.onprogress = updateProgress
      req.onerror = onError
      req.onabort = onError
    }

    // req.onload = transferComplete
    req.onreadystatechange = (ev: Event) => {
      if (req.readyState === XMLHttpRequest.DONE) {
        log(req.status)
        if (req.status === 200) {
          finish()
        } else {
          finishWithError()
        }
      }
    }

    req.open(method, url, true)
    updateStatus(targetId, isUpload ? 'uploading' : 'downloading')
    onReqReady(req)

    function onError(error: ProgressEvent<EventTarget>) {
      log('Got an error')
      log(error)
      finishWithError()
    }

    async function updateProgress(e: ProgressEvent) {
      const progress = e.loaded / e.total
      const pct = progress * 100
      if (progress < 1) {
        updateStatus(targetId, isUpload ? 'uploading' : 'downloading', (isUpload ? 'Uploading' : 'Downloading') + ' ' + formatBytes(e.loaded) + ' / ' + formatBytes(e.total))
        updateStatusProgress(targetId, pct)
      } else {
        updateStatusProgress(targetId, pct)
        updateStatus(targetId, 'finishing')
      }
    }

    function finishWithError() {
      // Mark resource with error
      // updateStatus(targetId, 'error', reason)
      // throw new Error('File request error: ' + req.status + " " + req.statusText)
      reject(new Error('File request error: ' + req.status + " " + req.statusText))
    }

    function finish() {
      // Mark resource finish loading
      updateStatus(targetId, isUpload ? 'uploaded' : 'downloaded')
      resolve(req)
    }
  })
}