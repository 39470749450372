import { colyseusClient, Entity, updateLocalPlayerScale } from 'playground-core'
import * as PIXI from 'pixi.js'
import { Cubism4InternalModel, InternalModel, Live2DModel } from 'pixi-live2d-display';
import { delay } from '../../../utils/delay';
import { loadStaticArrayBufferResource } from './loadStaticArrayBufferResource';
import { AnimatedGIF } from '@pixi/gif';
import { loadPixiImageFormFileFast } from '../../../utils/customLive2dLoader';

// https://avatech-avatar-dev1.nyc3.cdn.digitaloceanspaces.com/images/sakura-bg.png
const templateUrl: string = 'https://avatech-avatar-dev1.nyc3.cdn.digitaloceanspaces.com/images/default-bg.png'
export async  function generatePreviewImage(modelCompression: number, backgroundCompression: number, backgroundUrl: string, options?: {hideModels?: boolean, backgroundWidth?: number, backgroundHeight?: number, backgroundScaleType?: 'largerSide' | 'smallerSide' | 'fit'}): Promise<PIXI.Sprite>{

    const container: PIXI.Container = new colyseusClient.PIXI.Container();

    let renderTextureWidth: number = 0
    let renderTextureHeight: number = 0

    if (options && options.backgroundWidth && options.backgroundHeight){
      renderTextureWidth = options.backgroundWidth;
      renderTextureHeight = options.backgroundHeight;
    }
    else{
      colyseusClient.allEntities?.forEach((entity) =>{   
        const trim = getModelBoundingBox(entity);

        renderTextureWidth += Math.abs((trim.worldRight - trim.worldLeft)) * Math.abs((1 / entity.object.scale.x) / backgroundCompression)
        const temp = Math.abs(trim.worldBottom - trim.worldTop) * Math.abs((1 / entity.object.scale.y)) / backgroundCompression
        if (temp > renderTextureHeight){
            renderTextureHeight = temp
        }
    })
    }

    let renderTexture = colyseusClient.PIXI.RenderTexture.create({width: renderTextureWidth, height: renderTextureHeight})

    // add background
    const backgroundSprite = await fetchSpriteFormUrl(backgroundUrl === '' ? templateUrl : backgroundUrl) as PIXI.Sprite
    const backgroundRatioX = renderTextureWidth / backgroundSprite.texture.width
    const backgroundRatioY = renderTextureHeight / backgroundSprite.texture.height
    if (options && options.backgroundScaleType){
      if (options.backgroundScaleType === 'largerSide') backgroundSprite.scale.set(backgroundRatioX > backgroundRatioY ? backgroundRatioX : backgroundRatioY)
      if (options.backgroundScaleType === 'smallerSide') backgroundSprite.scale.set(backgroundRatioX > backgroundRatioY ? backgroundRatioY : backgroundRatioX)
      if (options.backgroundScaleType === 'fit') backgroundSprite.scale.set(backgroundRatioX, backgroundRatioY)
    }
    else{
      backgroundSprite.scale.set(backgroundRatioX, backgroundRatioY)
    }
    container.addChild(backgroundSprite)

    let stampX: number = 0
    let stampY: number = 0

    // add models
    if (options && options.hideModels === false || !options){
      colyseusClient.allEntities?.forEach(async (entity) =>{
        const externalModel = entity.object as Live2DModel<InternalModel>
        const bounds = externalModel.getBounds(true)
        const trim = getModelBoundingBox(entity);

        const entityTexture: PIXI.RenderTexture | undefined = colyseusClient.app?.renderer.generateTexture(
            entity.object,
            colyseusClient.PIXI.SCALE_MODES.LINEAR,
            1,
            new colyseusClient.PIXI.Rectangle(trim.worldLeft, trim.worldTop, trim.worldRight, trim.worldBottom),
            // new colyseusClient.PIXI.Rectangle(bounds.x, bounds.y, externalModel.internalModel.width, externalModel.internalModel.height)
        )

        const copiedSprite = new colyseusClient.PIXI.Sprite(entityTexture)

        let offsetX = -entity.object.x + stampX + bounds.width * 0.5 + bounds.x
        let offsetY = -entity.object.y + bounds.height * 0.5 + bounds.y

        stampX += Math.abs(trim.worldRight - trim.worldLeft) / Math.abs((1 / entity.object.scale.x))
  
        copiedSprite.position.set(offsetX, offsetY)
        copiedSprite.scale.set(Math.abs((1 / entity.object.scale.x) / modelCompression))
        container.addChild(copiedSprite)
    })
  }

    colyseusClient.app?.renderer.render(container, {renderTexture: renderTexture});
    container.destroy({baseTexture: true, texture: true, children: true})

    const sprite = new colyseusClient.PIXI.Sprite(renderTexture);
    sprite.pivot.set(0, 0)
    return sprite
}
 
function createCircleSprite(radius: number){
    const circle = new colyseusClient.PIXI.Graphics();
    circle.beginFill(0xffffff, 1);
    circle.lineStyle(0);
    circle.drawCircle(radius, radius, radius);
    circle.endFill();

    const renderTexture = colyseusClient.PIXI.RenderTexture.create({width: circle.width * 2, height: circle.height});
    colyseusClient.app?.renderer.render(circle, {renderTexture: renderTexture})

    const sprite = new colyseusClient.PIXI.Sprite(renderTexture);

    circle.destroy({baseTexture: true, texture: true, children: true})

    return sprite
}


export function getModelBoundingBox(entity: Entity): {localLeft: number, localRight: number, localTop: number, localBottom: number, worldLeft: number, worldRight: number, worldTop: number, worldBottom: number}{
  const externalModel = entity.object as Live2DModel<InternalModel>

  let model = externalModel.internalModel as Cubism4InternalModel
  const renderer = model.renderer

  const canvasWidth = renderer.getModel().getCanvasWidth()
  const canvasHeight = renderer.getModel().getCanvasHeight()
  let largerCanvas: number = 0
  let smallerCanvas: number = 0
  if (canvasHeight > canvasWidth) {
    largerCanvas = canvasHeight
    smallerCanvas = canvasWidth
  } else {
    largerCanvas = canvasWidth
    smallerCanvas = canvasHeight
  }
  const centerX = externalModel.internalModel.width / 2
  const centerY = externalModel.internalModel.height / 2

  let localLeft: number = Number.MAX_VALUE;
  let localRight: number = Number.MIN_VALUE
  let localTop: number = Number.MAX_VALUE
  let localBottom: number = Number.MIN_VALUE

  let worldLeft: number = 0;
  let worldRight: number = 0;
  let worldTop: number = 0;
  let worldBottom: number = 0;

  const drawableIds: string[] = renderer.getModel().getDrawableIds()
  for(let i = 0; i < drawableIds.length; i++){
    if (renderer.getModel().getDrawableDynamicFlagIsVisible(i) === true){

      const vertices = renderer.getModel().getDrawableVertexPositions(i)
      for(let k = 0; k < vertices.length; k = k + 2){
        let localX = vertices[k] * externalModel.internalModel.width
        let localY = -vertices[k + 1] * externalModel.internalModel.height
        if (canvasWidth > canvasHeight){
          localX = localX * smallerCanvas / largerCanvas
        }
        if (canvasHeight > canvasWidth){
          localY = localY * smallerCanvas / largerCanvas
        }
        localX += centerX
        localY += centerY

        const globalPoint = externalModel.toGlobal({x: localX, y: localY})

        let worldX = globalPoint.x
        let worldY = globalPoint.y

        if (localX < localLeft){
          localLeft = localX;
          worldLeft = worldX;
        }
        if (localX > localRight){
          localRight = localX;
          worldRight = worldX;
        }
        if (localY > localBottom){
          localBottom = localY;
          worldBottom = worldY;
        }
        if (localY < localTop){
          localTop = localY;
          worldTop = worldY;
        }
      }
    }
  }

  return {localLeft: localLeft, localRight: localRight, localTop: localTop, localBottom: localBottom, worldLeft: worldLeft, worldRight: worldRight, worldTop: worldTop, worldBottom: worldBottom}
}


async function fetchSpriteFormUrl(url: string){
    const abortController = new AbortController()
    
    if (url.endsWith('.gif')) {
      const imageArray = await loadStaticArrayBufferResource(url, abortController.signal)
      if (abortController.signal.aborted) return
      return AnimatedGIF.fromBuffer(imageArray, {
        loop: true,
      })
    } else {
      const imageArray1 = await loadStaticArrayBufferResource(url, abortController.signal)
      if (abortController.signal.aborted) return
      let defaultTexture = await loadPixiImageFormFileFast(new Blob([imageArray1]))
      return new PIXI.Sprite(defaultTexture)
    }
  }