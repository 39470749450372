import { PlayerSyncState } from 'playground-data-model'
import { reduxStore } from '../store'

export function getPlayerDefaultState(): Partial<PlayerSyncState> {
  let pos = reduxStore.getState().settings.playerLocalState?.savedPosition
  let scale = reduxStore.getState().settings.playerLocalState?.savedScale
  let angle = reduxStore.getState().settings.playerLocalState?.savedAngle

  let defaultState: Partial<PlayerSyncState> = {}

  if (pos) {
    defaultState.x = pos.x
    defaultState.y = pos.y

    if (defaultState.x < 0 || defaultState.x > window.innerWidth || defaultState.y < 0 || defaultState.y > window.innerHeight) {
      defaultState.x = window.innerWidth / 2
      defaultState.y = window.innerHeight / 2
    }
  }

  if (scale) {
    defaultState.scaleX = scale.x
    defaultState.scaleY = scale.y
  }

  if (angle) {
    defaultState.angle = angle
  }

  return defaultState
}