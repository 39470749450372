/**
 * @module dialogUtils
 */

import { v4 as uuidv4 } from 'uuid'
import { promptDialogActions, reduxStore } from 'playground-core'
import { DialogType } from '../dialog/DefaultDialog'

export const globalDialogOptions: Record<string, DialogType> = {}

export async function showDialog(options: DialogType): Promise<boolean> {
  return new Promise<boolean>(async (resolve) => {
    const id = uuidv4()

    const onConfirm = () => {
      options.onConfirm?.();
      resolve(true);
    }

    const onCancel = () => {
      options.onCancel?.();
      resolve(false);
    }

    const onClose = () => {
      options.onClose?.();
      resolve(false);
    }

    globalDialogOptions[id] = {
      ...options,
      onConfirm: onConfirm,
      onCancel: onCancel,
      onClose: onClose,
    }

    reduxStore.dispatch(
      promptDialogActions.queueDialog({
        id,
      },
      ),
    )
  })
}

export function dismissDialog(id: string) {
  const dialog = reduxStore.getState().promptDialog.dialogQueue[id]

  delete globalDialogOptions[id]

  reduxStore.dispatch(
    promptDialogActions.dismissDialog(id)
  )
}
