import { Entity } from '../ecs/core/Entity'
import { FileFilter } from './FileFilter'

export interface AvatarLoader {
  filter: FileFilter;
  checkIfSupported(files: File[]): boolean;
  filterFiles<T extends File>(files: T[]): Promise<T[]>;
  loadAvatar(files: File[]): Promise<Entity | undefined>;
}

export function getAvatarLoaderFromFiles(
  files: File[]
): AvatarLoader | undefined {
  const key = getAvatarLoaderKeyFromFiles(files);
  if (key) return avatarLoaders[key];
  return undefined;
}

export function getAvatarLoaderKeyFromFiles(files: File[]): string | undefined {
  for (const loaderKey in avatarLoaders) {
    if (avatarLoaders[loaderKey].checkIfSupported(files)) {
      return loaderKey;
    }
  }
  return undefined;
}

export const avatarLoaders: Record<string, AvatarLoader> = {};