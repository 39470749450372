import { FunctionToString } from '@sentry/react'
import {
    Cubism4InternalModel,
    InternalModel,
    Live2DModel,
} from 'pixi-live2d-display'
import { PixelPerfectProps } from '.'
import { colyseusClient, mapSessionIdToReduxPlayerId } from '../colyseus'
import { Entity } from '../ecs/core'
import { reduxStore, settingsActions } from '../store'

// https://guansss.github.io/pixi-live2d-display/motions_expressions/
export function refreshModel() {
    if (colyseusClient.currentEntity) {
        const model = colyseusClient.currentEntity?.object as Live2DModel
        const group = model.internalModel.motionManager.groups
        group.idle = !reduxStore.getState().settings.playIdleAnimation
            ? 'Ignore'
            : 'Idle'
        if (group.idle === 'Ignore') {
            const customModel =
                colyseusClient.currentEntity! as Entity<PixelPerfectProps>
            customModel.props.cachedPixels = undefined
        }
    }
}

export function resetModelFoucs(model: Live2DModel, playerId: string) {
    const mouse = reduxStore.getState().players.players[playerId].syncState
    model.focus(mouse.x, mouse.y)
}

export function mouseInteract(model: Live2DModel, playerId: string) {
    if (!reduxStore.getState().players.players[playerId].syncState.mouseInteract) {
        resetModelFoucs(model, playerId)
        return
    }
    // console.log(reduxStore.getState().players.players[playerId].syncState.mouseInteract)

    const mouseX = reduxStore.getState().players.players[playerId].syncState.mouseX
    const mouseY = reduxStore.getState().players.players[playerId].syncState.mouseY

    model.focus(mouseX, mouseY)
}

export function breathAnimation(model?: Live2DModel) {
    // const model = colyseusClient.currentEntity?.object as Live2DModel
    if (model?.internalModel instanceof Cubism4InternalModel) {
        if (!reduxStore.getState().settings.breathSettings)
            reduxStore.dispatch(
                settingsActions.setBreathSettings(
                    JSON.parse(JSON.stringify(model.internalModel.breath._breathParameters))
                )
            )
        if (reduxStore.getState().settings.playBreathAnimation)
            model.internalModel.breath.setParameters(
                reduxStore.getState().settings.breathSettings!
            )
        else model.internalModel.breath.setParameters([])
    }
}
