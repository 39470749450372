import { colyseusClient } from '../../colyseus/colyseusClient';
import { DisplayObject } from 'pixi.js';
import { log } from '../../logger/logger';
import { Vector3 } from 'playground-data-model';
import { IComponent } from './IComponent';
import { v4 as uuidv4 } from 'uuid';
import { getComponentMetadata } from './decorators/component';
import { OutlineFilter, AdjustmentFilter } from 'pixi-filters';
import { growthbook } from '../../../utils/GrowthBook';

export type DraggableProps = {
  dragging: boolean;
  _pointerX: number;
  _pointerY: number;
  playerId: number;
};

export type ScalableProps = {
  originalModelScaleX: number;
  originalModelScaleY: number;
};

export type RotationProps = {
  currentModelAngle: number;
};

export type MovableProps = {
  originalModelPosX: number;
  originalModelPosY: number;

  currentModelPosX: number;
  currentModelPosY: number;
};

export type NameProps = {
  name?: string;
  type?: string;
};

export type RenderingProps = {
  visible?: boolean;
};

export type SyncEntityProps = MovableProps &
  ScalableProps &
  DraggableProps &
  NameProps &
  RenderingProps &
  RotationProps;
export class Entity<CustomProps = any> {
  uuid: string;
  sessionId?: string;
  components: IComponent[];
  object: DisplayObject;
  props: SyncEntityProps & CustomProps;
  icon?: string;
  cleanUpAction?: () => void;
  setupAnimate?: (isRemote: boolean, playerId: string) => void;

  constructor(object: DisplayObject, cleanUpAction?: () => void) {
    this.uuid = uuidv4();

    this.object = object;

    // const filter = new BloomFilter(5, 4, 1, 5);
    if (growthbook.isOn('model_outline')) {
      const outlineFilter = new OutlineFilter(2, 0xffffff, 1);
      outlineFilter.enabled = false;
      object.filters = [outlineFilter];
    }

    // colyseusClient!.app!.stage.addChild(object);
    colyseusClient.modelContainer?.addChild(object);

    this.components = [];

    this.props = {
      originalModelPosX: object.x,
      originalModelPosY: object.y,
      currentModelPosX: object.x,
      originalModelScaleX: object.scale.x,
      originalModelScaleY: object.scale.y,
      currentModelPosY: object.scale.y,
      currentModelAngle: object.angle,
      _pointerX: 0,
      _pointerY: 0,
      dragging: false,
      playerId: 0,
    } as SyncEntityProps & CustomProps;

    // this.customProps = {} as CustomProps;

    log(this.props);

    this.cleanUpAction = cleanUpAction;
  }

  addComponent<T extends IComponent>(component: T): T | undefined {
    let contain: boolean = false;
    for (let i = 0; i < this.components.length; i++) {
      if (
        getComponentMetadata(this.components[i]).name ===
        getComponentMetadata(component).name
      ) {
        contain = true;
        break;
      }
    }
    if (contain) {
      return undefined;
    }
    log(getComponentMetadata(component).name + ' added');
    this.components.push(component);
    return component;
  }

  getPosition(): Vector3 {
    const pos = {
      x: this.object.x,
      y: this.object.y,
      z: this.object.zIndex,
    };
    return pos;
  }

  getScale(): Vector3 {
    return {
      x: this.object.scale.x,
      y: this.object.scale.y,
      z: 1,
    };
  }

  setPosition(pos: Vector3): void {
    this.object.x = pos.x;
    this.object.y = pos.y;
    this.object.zIndex = pos.z;
  }

  setScale(scale: Vector3): void {
    this.object.scale.x = scale.x;
    this.object.scale.y = scale.y;
  }

  remove() {
    this.components = [];
    // colyseusClient!.app!.stage.removeChild(this.object);
    colyseusClient!.modelContainer!.removeChild(this.object);
    this.cleanUpAction?.();
  }
}
