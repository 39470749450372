import Tracker from "@openreplay/tracker";
import API from '@openreplay/tracker'
import { events } from '../amplitude/amplitude'
import { tracker } from './reduxStore'

export function initOpenReplay(userId?: string) {
  if (!userId) return;

  const tracker = new Tracker({
    projectKey: "xvwEyieORlagR7ysaqeU",
    // __DISABLE_SECURE_MODE: true
  });

  initAsync(tracker, userId);

  return tracker;
}

export let openReplaySessionId: string | undefined | null;

async function initAsync(tracker: API, userId: string) {
  await tracker.start();
  tracker.setUserID(userId);

  openReplaySessionId = tracker.getSessionID();

  events(
    'open_replay_session',
    {
      session_id: openReplaySessionId,
    }
  )
  //
  // console.log('Getting open replay session id')
  // console.log(tracker.getSessionID())
  // console.log(tracker.sessionID())
}
