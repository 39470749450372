import { ResourcePipelineContext } from '../ResourcePipelineContext'
import { Next } from '../../Middleware'
import { checkInput } from '../util/checkInput'
import { log } from '../../../../logger'
import { fetchSupabase, fetchUrlWithSupabaseSession, supabase } from '../../../../supabase'
import { ModelData, ModelUrlResponse } from 'playground-data-model'
import { updateStatus } from '../../../../store'
import { logMiddleware } from '../util/middlewareLogger'
import { showToast } from '../../../../toast'
import { showDialog } from 'playground-web/src/components/utils/dialogUtils'
import { showCacheConflictsDialog } from 'playground-web/src/components/dialog/CacheConflictsDialog'
import { ModelLoadingMiddleware } from '../index'
import { handleModelKeepRemoteModel_WithLogin } from '../../pipeline/Pipeline_Login'

export async function WriteRemoteCacheMiddleware(context: ResourcePipelineContext, next: Next) {
  if (context.bypassCache) {
    logMiddleware(context,'bypassCache, skip entire pipeline')
    return
    // return next()
  }

  logMiddleware(context,"hi")

  checkInput({
    userId: context.userId
  })

  logMiddleware(context,"hi1.5")

  logMiddleware(context,context.remoteCacheVersion)
  logMiddleware(context,context.localCacheVersion)

  logMiddleware(context,"hi1.8")

  if (context.remoteCacheVersion === context.localCacheVersion) {
    logMiddleware(context,'Remote and local version eq, bypassing remote cache write')
    showToast('Latest Model Cache')
    return
    // return next()
  }

  if (!context.forceWriteRemoteCache)
    if ((context.localCacheVersion ?? 0) > (context.remoteCacheVersion ?? 0) || context.remoteCacheVersion === undefined) {
      const ok = await showCacheConflictsDialog('upload', context.localCacheVersion, context.remoteCacheVersion)

      if (!ok) {
        // User will be going to keep remote version, download the remote one
        // There might be a problem here, later fix
        return await handleModelKeepRemoteModel_WithLogin()
      }
    } else {
      logMiddleware(context, 'Bypassing remote cache write, remote cache is newer')
      return
    }

  logMiddleware(context,"hi1.5")
  if (!context.modelId && !context.remoteCacheVersion) {
    // No remote cache version, insert an item
    const data = await fetchSupabase(
      supabase.from<ModelData>('models').insert(
        {
          user_id: context.userId,
        },
      ),
    )
    if (data) context.modelId = data[0]?.model_id
    logMiddleware(context,'Inserted new model')
  }

  checkInput({
    modelId: context.modelId,
  })
  logMiddleware(context,"hi2")

  const res = await fetchUrlWithSupabaseSession('/get-model-upload-url?modelId=' + context.modelId)
  logMiddleware(context,"hi2.5")

  if (!res || res.status !== 200) {
    logMiddleware(context,'No response from server')
    throw new Error('Unable to get model upload url from server')
  }

  logMiddleware(context,res)
  logMiddleware(context,"hi3")

  const a = await res.json() as ModelUrlResponse

  context.modelUploadUrl = a.url
  context.modelBucket = a.modelBucket

  const n = await next()

  checkInput({
    modelName: context.modelName,
    modelId: context.modelId,
    modelBucket: context.modelBucket,
    rawModelKey: context.rawModelKey
  })

  // When all finished
  const modelData: Partial<ModelData> = {
    model_name: context.modelName,
    model_key: context.rawModelKey,
    upload_time: context.localCacheVersion,
    type: context.modelType,
    model_bucket: context.modelBucket,
  }

  logMiddleware(context,"Update model data")
  const {model_key, ...rest} = modelData
  logMiddleware(context,rest)

  const data = await fetchSupabase(supabase
    .from('models')
    .update(modelData)
    .eq('user_id', context.userId))

  logMiddleware(context,data)

  // logMiddleware(context,data)

  updateStatus(context.targetId, 'uploaded')
  return n
}