import { reduxStore } from '../../../reduxStore'
import { remoteResourcesActions } from '../remoteResourcesSlice'

export function updateLocalVersion(targetId: string, version: number) {
  // Mark resource finish loading
  reduxStore.dispatch(remoteResourcesActions.updateRemoteResourceLocalVersion(
    {
      targetId: targetId,
      version: version,
    },
  ))
}