// declare global {
//     interface DataTransferItem {
//         webkitGetAsEntry(): FileSystemEntry;
//     }

//     interface FileSystemEntry {
//         isFile: boolean;
//         isDirectory: boolean;
//         fullPath: string;
//     }

//     interface FileSystemDirectoryEntry extends FileSystemEntry {
//         createReader(): FileSystemDirectoryReader;
//     }

//     interface FileSystemFileEntry extends FileSystemEntry {
//         file(succeed: (file: File) => void, fail: (e: Error) => void): void;
//     }

//     interface FileSystemDirectoryReader {
//         readEntries(succeed: (entries: FileSystemEntry[]) => void, fail: (e: Error) => void): void;
//     }
// }
import { ZipLoader } from 'pixi-live2d-display'
import JSZip from 'jszip'
// @ts-ignore
// import { saveAs } from 'file-saver';
import { log } from '../logger/logger'
import { avatarLoaders } from '../model-loading/AvatarLoader'

export function basename(path: string): string {
  // https://stackoverflow.com/a/15270931
  return path.split(/[\\/]/).pop()!;
}

/**
 * Checks if it's files being dragged at this drag event.
 *
 * @see https://stackoverflow.com/q/5323668/13237325
 */
export function isDraggingFile(event: DragEvent): boolean {
  return !!event.dataTransfer?.types.some((type) => type === 'Files');
}

async function readEntries(entries: FileSystemEntry[]): Promise<File[]> {
  const files: File[] = [];

  await Promise.all(
    entries.map(async (entry) => {
      if (entry.isFile) {
        files.push(await readFileEntry(entry as FileSystemFileEntry));
      } else if (entry.isDirectory) {
        const subEntries = await readDirEntry(
          entry as FileSystemDirectoryEntry,
        );

        files.push(...(await readEntries(subEntries)));
      }
    }),
  );

  return files;
}

function readDirEntry(
  dirEntry: FileSystemDirectoryEntry,
): Promise<FileSystemEntry[]> {
  return new Promise((resolve, reject) => {
    dirEntry.createReader().readEntries(resolve, reject);
  });
}

async function readFileEntry(fileEntry: FileSystemFileEntry): Promise<File> {
  const file = await new Promise<File>((resolve, reject) =>
    fileEntry.file(resolve, reject),
  );

  let relativePath = fileEntry.fullPath;

  // relative path should just be relative...
  if (relativePath.startsWith('/')) {
    relativePath = relativePath.slice(1);
  }

  // let's borrow this property...
  Object.defineProperty(file, 'webkitRelativePath', {
    value: relativePath,
  });

  return file;
}

export function readAsBase64(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

async function FetchAllFileSystemEntry(
  entries: FileSystemEntry[],
): Promise<FileSystemEntry[]> {
  var allEntries: FileSystemEntry[] = [];
  var complete: boolean = false;
  await Promise.all(
    entries.map(async (entry) => {
      if (!complete) {
        if (entry.isFile) {
          allEntries.push(entry);
        } else if (entry.isDirectory) {
          const subEntries = await readDirEntry(
            entry as FileSystemDirectoryEntry,
          );
          allEntries.push(entry);
          allEntries.push(...(await FetchAllFileSystemEntry(subEntries)));
          complete = true;
        }
      }
    }),
  );
  return allEntries;
}

export async function compressFiles(entries: FileSystemEntry[]): Promise<Blob> {
  log('CompressFiles');

  var allEntries: FileSystemEntry[] = await FetchAllFileSystemEntry(entries);

  var zip = new JSZip();

  await Promise.all(
    allEntries.map(async (entry) => {
      if (avatarLoaders.live2d.filter.checkFile(entry.name)) {
        if (entry.isFile) {
          zip.file(
            entry.fullPath,
            await readFileEntry(entry as FileSystemFileEntry),
            { base64: true },
          );
        } else if (entry.isDirectory) {
          zip.folder(entry.fullPath);
        }
      }
    }),
  );

  return await zip.generateAsync({ type: 'blob' });
}

ZipLoader.zipReader = (data: Blob, url: string) => JSZip.loadAsync(data);

ZipLoader.readText = (jsZip: JSZip, path: string) => {
  const file = jsZip.file(path);

  if (!file) {
    throw new Error('Cannot find file: ' + path);
  }

  return file.async('text');
};

ZipLoader.getFilePaths = (jsZip: JSZip) => {
  const paths: string[] = [];

  jsZip.forEach((relativePath) => {
    if (avatarLoaders.live2d.filter.checkFile(relativePath)) {
      paths.push(relativePath)
    }
  });

  return Promise.resolve(paths);
};

ZipLoader.getFiles = (jsZip: JSZip, paths: string[]) =>
  Promise.all(
    paths.map(async (path) => {
      const fileName = path.slice(path.lastIndexOf('/') + 1);

      const blob = await jsZip.file(path)!.async('blob');

      return new File([blob], fileName);
    }),
  );
