import { update } from './update'
import { fetchVTubeStudioData } from '../../vtubestudio'
import { updatePlayerDrag } from './updatePlayerDrag'
import { AttachmentUpdate } from '../../live2d'
import { updateVtsFilter } from './updateVtsFilter'

export const allUpdateFunctions = {
  update,
  fetchVTubeStudioData,
  updatePlayerDrag,
  AttachmentUpdate,
  updateVtsFilter,
}