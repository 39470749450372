import { ResourcePipelineContext } from '../ResourcePipelineContext'
import { Next } from '../../Middleware'
import { compressFilesDirectly } from '../../../../util'
import { updateStatus, updateStatusProgress } from '../../../../store'
import { log } from '../../../../logger'
import { logMiddleware } from '../util/middlewareLogger'

/**
 * Compress the filteredFiles into compressedFile
 * @param context
 * @param next
 * @constructor
 */
export async function CompressionMiddleware(context: ResourcePipelineContext, next: Next): Promise<void> {
  log('CompressionMiddleware')

  if (!context.filteredFiles || context.filteredFiles.length === 0) {
    throw new Error('No filtered files')
  }
 
  // if filteredFiles is already compressed throw error
  if (context.filteredFiles.length === 1 && 
    (context.filteredFiles[0].name.endsWith('.zip') || 
     context.filteredFiles[0].type === 'application/zip')
    ) {
    logMiddleware(context,context.filteredFiles)
    context.compressedFile = context.filteredFiles[0]
    logMiddleware(context,'bypassing compression, already compressed')
    return next()
    // throw new Error('Filtered files are already compressed')
  }

  // if any of filterFiles do not contain webkitRelativePath, throw error
  if (context.filteredFiles.some(file => !file.webkitRelativePath || file.webkitRelativePath === '')) {
    throw new Error('All filtered files must have webkitRelativePath')
  }

  log(context.filteredFiles)

  updateStatus(context.targetId, 'compressing')
  context.compressedFile = await compressFilesDirectly(context.filteredFiles, (progress) => {
    updateStatusProgress(context.targetId, progress.percent)
  })

  if (!context.compressedFile) {
    throw new Error('Compression failed')
  }

  log(context)

  return next()
}