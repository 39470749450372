import { reduxStore } from '../../../reduxStore'
import { remoteResourcesActions } from '../remoteResourcesSlice'

export function beginRemoteResourceRequest(targetId: string,
                                           shouldCache: boolean) {
  reduxStore.dispatch(remoteResourcesActions.fetchRemoteResource(
    {
      targetId: targetId,
      shouldCache: shouldCache,
    },
  ))
}