import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../reduxStore'
import { BackgroundType } from '../../types/BackgroundState'
import { Vector3 } from 'playground-data-model'
import { en } from '../../../i18n/messages/en-US'
import { string } from '@colyseus/schema/lib/encoding/decode'

export interface PlayerLocalState {
  savedPosition: Vector3
  savedScale: Vector3
  savedAngle: number
}

export interface SettingsState {
  bgTypeState: BackgroundType
  bgColorState: string
  hideGUIState: boolean
  hideDebugGUIState: boolean
  enterRoomPromptState: boolean
  maskBufferResolution: number
  vtubeStudioPort: string
  playIdleAnimation: boolean
  syncObjectTransform: boolean
  colorOverlayEnabled: boolean
  targetFrameRate: number
  showTips: boolean
  showImportTips: boolean

  playerLocalState?: PlayerLocalState
  blackblock: boolean
  mainMenuGUIState: boolean
  hideModelOutlineState: boolean
  playBreathAnimation?: boolean
  breathSettings?: any[] | null
  sharpieColor: string
  overrideServerUrl?: string
  brushStrokeType?: number
  movementConfigX: number[]
  movementConfigY: number[]
  movementConfigZ: number[]
  movementConfigEnable: boolean
  sceneConfigEnable: boolean
  backgroundSceneName?: string

  backgroundColorOverlay?: boolean

  languageName: string
}

// Define the initial state using that type
const initialState: SettingsState = {
  bgTypeState: 'default',
  bgColorState: '#000000',
  hideGUIState: true,
  hideDebugGUIState: true,
  enterRoomPromptState: false,
  maskBufferResolution: 2048,
  vtubeStudioPort: '8001',
  playIdleAnimation: false,
  syncObjectTransform: true,
  colorOverlayEnabled: false,
  targetFrameRate: 60,
  showTips: true,
  showImportTips: false,
  playerLocalState: {
    savedPosition: {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
      z: 0,
    },
    savedScale: { x: 0.2, y: 0.2, z: 1 },
    savedAngle: 0,
  },
  blackblock: true,
  mainMenuGUIState: false,
  sharpieColor: '#000000',
  hideModelOutlineState: true,
  playBreathAnimation: false,
  breathSettings: null,
  brushStrokeType: 0,
  movementConfigX: [0],
  movementConfigY: [0],
  movementConfigZ: [0],
  movementConfigEnable: false,
  sceneConfigEnable: false,
  backgroundSceneName: 'default',

  languageName:
    navigator.language.slice(0, 2) == 'zh'
      ? 'zh'
      : navigator.language.slice(0, 2) == 'ja'
      ? 'ja'
      : 'en',
  // languageName: 'en',
}

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setbgTypeState(state, action: PayloadAction<BackgroundType>) {
      state.bgTypeState = action.payload
    },
    setbgColorState(state, action: PayloadAction<string>) {
      state.bgColorState = action.payload
    },
    sethideGUIState(state, action: PayloadAction<boolean>) {
      state.hideGUIState = action.payload
    },
    sethideDebugGUIState(state, action: PayloadAction<boolean>) {
      state.hideDebugGUIState = action.payload
    },
    setenterRoomPromptState(state, action: PayloadAction<boolean>) {
      state.enterRoomPromptState = action.payload
    },
    setMaskBufferResolution(state, action: PayloadAction<number>) {
      state.maskBufferResolution = action.payload
    },
    setVTubeStudioPort(state, action: PayloadAction<string>) {
      state.vtubeStudioPort = action.payload
    },
    setPlayIdleAnimation(state, action: PayloadAction<boolean>) {
      state.playIdleAnimation = action.payload
    },
    setSyncObjectTransform(state, action: PayloadAction<boolean>) {
      state.syncObjectTransform = action.payload
    },
    setColorOverlayEnabled(state, action: PayloadAction<boolean>) {
      state.colorOverlayEnabled = action.payload
    },
    setTargetFrameRate(state, action: PayloadAction<number>) {
      state.targetFrameRate = action.payload
    },
    setShowTips(state, action: PayloadAction<boolean>) {
      state.showTips = action.payload
    },
    setShowImportTips(state, action: PayloadAction<boolean>) {
      state.showImportTips = action.payload
    },
    setPlayerLocalState(
      state,
      action: PayloadAction<PlayerLocalState | undefined>
    ) {
      state.playerLocalState = action.payload
    },
    setPlayerLocalStatePosition(state, action: PayloadAction<Vector3>) {
      state.playerLocalState!.savedPosition = action.payload
    },
    setPlayerLocalStateScale(state, action: PayloadAction<Vector3>) {
      state.playerLocalState!.savedScale = action.payload
    },
    setPlayerLocalStateAngle(state, action: PayloadAction<number>) {
      state.playerLocalState!.savedAngle = action.payload
    },
    setBlackBlock(state, action: PayloadAction<boolean>) {
      state.blackblock = action.payload
    },
    setMainMenuGUIState(state, action: PayloadAction<boolean>) {
      state.mainMenuGUIState = action.payload
    },
    setHideModelOutlineState(state, action: PayloadAction<boolean>) {
      state.hideModelOutlineState = action.payload
    },
    setPlayBreathAnimation(state, action: PayloadAction<boolean>) {
      state.playBreathAnimation = action.payload
    },
    setBreathSettings(state, action: PayloadAction<any[] | null>) {
      state.breathSettings = action.payload
    },
    setSharpieColor(state, action: PayloadAction<string>) {
      state.sharpieColor = action.payload
    },
    setOverrideServerUrl(state, action: PayloadAction<string | undefined>) {
      state.overrideServerUrl = action.payload
    },
    setBrushStrokeType(state, action: PayloadAction<number>) {
      state.brushStrokeType = action.payload
    },
    setMovementConfigX(state, action: PayloadAction<number[]>) {
      state.movementConfigX = action.payload
    },
    setMovementConfigY(state, action: PayloadAction<number[]>) {
      state.movementConfigY = action.payload
    },
    setMovementConfigZ(state, action: PayloadAction<number[]>) {
      state.movementConfigZ = action.payload
    },
    setMovementConfigEnable(state, action: PayloadAction<boolean>) {
      state.movementConfigEnable = action.payload
    },
    setSceneConfigEnable(state, action: PayloadAction<boolean>) {
      state.sceneConfigEnable = action.payload
    },
    patchSettings(state, action: PayloadAction<Partial<SettingsState>>) {
      Object.assign(state, action.payload)
    },
    setBackgroundSceneName(state, action: PayloadAction<string>) {
      state.backgroundSceneName = action.payload
    },
    setLanguageName(state, action: PayloadAction<string>) {
      state.languageName = action.payload
    },
  },
})

export const settingsActions = slice.actions

export const selectSettings = (state: RootState) =>
  state.settings as SettingsState
export const selectRoomBackgroundType = (state: RootState) =>
  state.settings.bgTypeState
export const selectRoomBackgroundColor = (state: RootState) =>
  state.settings.bgColorState
export const selectRoomHideGUI = (state: RootState) =>
  state.settings.hideGUIState
export const selectRoomHideDebugGUI = (state: RootState) =>
  state.settings.hideDebugGUIState
export const selectEnterRoomPrompt = (state: RootState) =>
  state.settings.enterRoomPromptState
export const selectShowTips = (state: RootState) => state.settings.showTips
export const selectShowImportTips = (state: RootState) =>
  state.settings.showImportTips
export const selectBlackBlock = (state: RootState) => state.settings.blackblock
export const selectMainMenuGUI = (state: RootState) =>
  state.settings.mainMenuGUIState
export const selectSyncObjectTransform = (state: RootState) =>
  state.settings.syncObjectTransform
export const selectSharpieColor = (state: RootState) =>
  state.settings.sharpieColor
export const selectOverrideServerUrl = (state: RootState) =>
  state.settings.overrideServerUrl
export const selectHideModelOutline = (state: RootState) =>
  state.settings.hideModelOutlineState
export const selectBrushStrokeType = (state: RootState) =>
  state.settings.brushStrokeType
export const selectMovementConfigX = (state: RootState) =>
  state.settings.movementConfigX
export const selectMovementConfigY = (state: RootState) =>
  state.settings.movementConfigY
export const selectMovementConfigZ = (state: RootState) =>
  state.settings.movementConfigZ
export const selectMovementConfigEnable = (state: RootState) =>
  state.settings.movementConfigEnable
export const selectBreathAnimation = (state: RootState) =>
  state.settings.playBreathAnimation
export const selectSceneConfigEnable = (state: RootState) =>
  state.settings.sceneConfigEnable
export const selectBackgroundSceneName = (state: RootState) =>
  state.settings.backgroundSceneName
export const selectLanguageName = (state: RootState) =>
  state.settings.languageName

export default slice.reducer
