import { Client, Room } from 'colyseus.js';
import { events } from '../amplitude/amplitude';
import { showToast, showToastPromise } from '../toast/showToast';
import { colyseusClient } from './colyseusClient';
import { handleOnRoomReady } from './handleOnRoomReady';
import { MyRoomState } from 'playground-data-model';
import { reduxStore } from '../store/reduxStore';
import { playersActions } from '../store/reducers/playersSlice';

type ReconnectOptions = {
  roomId?: string;
  sessionId?: string;
};

export async function reconnect(room: Room, options: ReconnectOptions = {}) {
  return await showToastPromise(new Promise<boolean>(async (resolve, reject) => {
    const dispatch = reduxStore.dispatch;
    let { roomId, sessionId } = options;
    roomId = sessionStorage.getItem('roomId')!;
    sessionId = sessionStorage.getItem('sessionId')!;

    if (roomId == '' || sessionId == '') return;
    // console.log('reconnecting to room', roomId, sessionId);
    dispatch(playersActions.onPlayerUpdate(
      {
        playerId: sessionId,
        changedField: 'reconnecting',
        changedValue: true,
      }
    ));
    try {
      const player = await colyseusClient.client.reconnect(roomId, sessionId) as Room<MyRoomState>
      colyseusClient.currentRoom = player;
      handleOnRoomReady(player, true);
      events('reconnect', { room_id: roomId, session_id: sessionId });
      resolve(true);
    } catch (error) {
      reject(true);
    }
    dispatch(playersActions.onPlayerUpdate(
      {
        playerId: sessionId,
        changedField: 'reconnecting',
        changedValue: false,
      }
    ))
  }), {
    pending: 'Reconnecting...',
    success: 'Reconnect to server successfully👌',
    error: 'Failed to reconnect to server🤯',
  })
}
