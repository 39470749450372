import { ResourcePipelineContext } from '../ResourcePipelineContext'
import { Next } from '../../Middleware'
import { getCachedRemoteSource } from '../../../../store'
import { filterModelFiles } from '../../../loadModelUtils'
import { extractModelFileName } from '../../../extractModelFileName'
import { logMiddleware } from '../util/middlewareLogger'

export async function ReadLocalCacheMiddleware(context: ResourcePipelineContext, next: Next): Promise<void> {
  const model = await getCachedRemoteSource(context.targetId)

  if (!model || !model.data) {
    logMiddleware(context,'No local cache')
    return next()
  }

  if (context.inputFiles) {
    logMiddleware(context,'Have input files, by-passing ReadLocalCacheMiddleware')
    return next()
  }

  context.localCacheVersion = model.version
  context.inputFiles = model.data
  context.modelType = model.modelType
  context.filteredFiles = await filterModelFiles(model.data)
  logMiddleware(context,context.filteredFiles)
  context.modelName = extractModelFileName(context.filteredFiles)


  logMiddleware(context, context.modelName)
  // logMiddleware(context)
  logMiddleware(context,'Test')
  logMiddleware(context,context.modelType)
  logMiddleware(context,model.modelType)
  // logMiddleware(context)
  // logMiddleware(context,JSON.stringify(context))
  logMiddleware(context,context.modelType)

  return next()
}