import { Live2DModel } from 'pixi-live2d-display'
import {
  allUpdateFunctions,
  checkOnHover,
  colyseusClient,
  patchRemotePlayerStateSelf, patchRemoteRoomState,
  update,
  updatePlayerDrag,
} from '../colyseus'
import { log } from '../logger'
import { appState, playersActions, reduxStore, selectIsHost, settingsActions, statusActions } from '../store'
import { PlayerSyncState } from 'playground-data-model'
import { fetchVTubeStudioData } from '../vtubestudio'
import { AttachmentUpdate, updateBlendShape } from '../live2d'
import { getPlayerDefaultState } from './getPlayerDefaultState'
import { AnimatedGIFLoader } from '@pixi/gif'
import { AdjustmentFilter, AdvancedBloomFilter, ColorOverlayFilter } from 'pixi-filters'
import * as PIXI from 'pixi.js'

// @ts-ignore
window.PIXI = PIXI;

export let lowFpsTicker: PIXI.Ticker

export async function init() {
  // register the Ticker to support automatic updating of Live2D models
  // PIXI.Application.registerPlugin(PIXI.TickerPlugin);
  // if (colyseusClient.app != null) {
  //   log('Already initialized , skipping!')
  //   return
  // }

  // Live2DModel.registerTicker(PIXI.Ticker)
  colyseusClient.PIXI.Loader.registerPlugin(AnimatedGIFLoader);

  // clean up previous app
  if (colyseusClient.app) {
    console.log('Cleaning up previous resources')
    colyseusClient.app.destroy(true)
  }

  try {
    colyseusClient.app = new colyseusClient.PIXI.Application({
      view: document.getElementById('canvas') as HTMLCanvasElement,
      autoStart: true,
      resizeTo: document.getElementById('canvas')
        ?.parentElement as HTMLElement,
      transparent: true,
    })
  } catch (e) {
    log(e)
    reduxStore.dispatch(statusActions.setShowError(true))
  }

  const advanceBloomFilter = new AdvancedBloomFilter({
    threshold: 0.1,
    bloomScale: 0.5,
    brightness: 0.5,
    blur: 6,
    quality: 4,
  });
  
  // const adjustmentFilter = new AdjustmentFilter({
  //   gamma:1,
  //   saturation: 1,
  //   contrast: 1,
  //   brightness: 1,
  //   red: 0.823529411764*(69/50)+(20/100),
  //   green: 0.901960784313*(69/50)+(20/100),
  //   blue: 0.603921568627*(69/50)+(20/100),
  //   alpha: 1,
  // });

  //  const advanceBloomFilter = new AdvancedBloomFilter({
  //    threshold: 0.1,
  //    bloomScale: 0.52,
  //    brightness: 0.52,
  //    blur: 4,
  //    quality: 4,
  //  });
  //  const adjustmentFilter = new AdjustmentFilter({
  //    gamma:1,
  //    saturation: 1,
  //    contrast: 1,
  //    brightness: 1.0,
  //    red: 1,
  //    green: 1,
  //    blue: 1,
  //    alpha: 1,

  advanceBloomFilter.enabled = false; 
  // adjustmentFilter.enabled = false;

  colyseusClient.overallBloomFilter = advanceBloomFilter;

  colyseusClient.app!.stage.filters = [advanceBloomFilter]

  let pos = reduxStore.getState().settings.playerLocalState?.savedPosition
  let scale = reduxStore.getState().settings.playerLocalState?.savedScale
  let angle = reduxStore.getState().settings.playerLocalState?.savedAngle

  let defaultState: Partial<PlayerSyncState> = {}

  if (pos) {
    defaultState.x = pos.x
    defaultState.y = pos.y

    if (defaultState.x < 0 || defaultState.x > window.innerWidth || defaultState.y < 0 || defaultState.y > window.innerHeight) {
      defaultState.x = window.innerWidth / 2
      defaultState.y = window.innerHeight / 2
    }
  }
  if (scale) {
    defaultState.scaleX = scale.x
    defaultState.scaleY = scale.y
  }
  if (angle) {
    defaultState.angle = angle
  }

  reduxStore.dispatch(playersActions.onPlayerJoin({
    key: 'self',
    defaultState: getPlayerDefaultState(),
  }))
  patchRemotePlayerStateSelf( { bgColorOverlayEnable: appState().settings.backgroundColorOverlay}, true)
  reduxStore.dispatch(settingsActions.setBackgroundSceneName('default'))
  if (selectIsHost(reduxStore.getState()))
    patchRemoteRoomState({ currentSceneId: 'default' })

  colyseusClient.app!.stage.sortableChildren = true

  colyseusClient.modelContainer = new PIXI.Container()
  colyseusClient.modelContainer.sortableChildren = true
  colyseusClient.vtsAdjustmentFilter = new AdjustmentFilter({
    gamma: 1,
    saturation: 1,
    contrast: 1,
    brightness: 1,
    red: 1,
    green: 1,
    blue: 1,
    alpha: 1,
  });
  colyseusClient.vtsAdjustmentFilter.enabled = false;



  const backgroundAdjustmentFilter = new AdjustmentFilter({
    gamma:1,
    saturation: 1,
    contrast: 1,
    brightness: 1,
    red: 0.823529411764*(69/50)+(20/100),
    green: 0.901960784313*(69/50)+(20/100),
    blue: 0.603921568627*(69/50)+(20/100),
    alpha: 1,
  });
  colyseusClient.backgroundAdjustmentFilter = backgroundAdjustmentFilter
  colyseusClient.backgroundAdjustmentFilter.enabled = false


  colyseusClient.modelContainer.filters = [colyseusClient.vtsAdjustmentFilter, colyseusClient.backgroundAdjustmentFilter]
  colyseusClient.app!.stage.addChild(colyseusClient.modelContainer)

  // for local fps
  let ticker = colyseusClient.PIXI.Ticker.shared
  let fps = reduxStore.getState().settings.targetFrameRate || 60
  ticker.maxFPS = fps

  // Define the update function in allUpdateFunctions
  for (const f of Object.values(allUpdateFunctions)) {
    ticker.add(f)
  }

  // for sending fps
  lowFpsTicker = new colyseusClient.PIXI.Ticker()
  lowFpsTicker.maxFPS = 5
  lowFpsTicker.start()
  lowFpsTicker.add(updateBlendShape)
}
