import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../reduxStore'

export interface pixiSceneState{
    scenesImages: Record<string, string>
}

const initialState: pixiSceneState = {
    scenesImages: {}
};


const slice = createSlice({
    name: 'pixiScene',
    initialState,
    reducers: {
        setPixiSceneImage(state, action: PayloadAction<{sceneName: string, sceneImage: string}>){
            state.scenesImages[action.payload.sceneName] = action.payload.sceneImage
        }
    },
});

export const pixiSceneAction = slice.actions;
export const selectPixiSceneImages = (state: RootState) => state.pixiScene.scenesImages;


export default slice.reducer;