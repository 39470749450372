import { EncryptedMessage } from "playground-data-model";
import { log } from "../logger/logger";

export type JwkPair = {
  publicKeyJwk: JsonWebKey;
  privateKeyJwk: JsonWebKey;
};


export async function generateKeyPair(): Promise<JwkPair> {
  const keyPair = await window.crypto.subtle.generateKey(
    {
      name: 'ECDH',
      namedCurve: 'P-256',
    },
    true,
    ['deriveKey', 'deriveBits']
  );

  const publicKeyJwk = await window.crypto.subtle.exportKey(
    'jwk',
    keyPair.publicKey
  );

  const privateKeyJwk = await window.crypto.subtle.exportKey(
    'jwk',
    keyPair.privateKey
  );

  return { publicKeyJwk, privateKeyJwk };
}

export async function deriveKey(
  publicKeyJwk: JsonWebKey,
  privateKeyJwk: JsonWebKey
): Promise<CryptoKey> {
  const publicKey = await window.crypto.subtle.importKey(
    'jwk',
    publicKeyJwk,
    {
      name: 'ECDH',
      namedCurve: 'P-256',
    },
    true,
    []
  );

  const privateKey = await window.crypto.subtle.importKey(
    'jwk',
    privateKeyJwk,
    {
      name: 'ECDH',
      namedCurve: 'P-256',
    },
    true,
    ['deriveKey', 'deriveBits']
  );

  return await window.crypto.subtle.deriveKey(
    { name: 'ECDH', public: publicKey },
    privateKey,
    { name: 'AES-GCM', length: 256 },
    true,
    ['encrypt', 'decrypt']
  );
}

export async function encryptText(text: string, derivedKey: CryptoKey): Promise<EncryptedMessage> {
  const encodedText = new TextEncoder().encode(text);
  let iv = crypto.getRandomValues(new Uint8Array(12));

  log(iv)

  const encryptedData = await window.crypto.subtle.encrypt(
    { name: 'AES-GCM', iv: iv },
    derivedKey,
    encodedText
  );

  const uintArray = new Uint8Array(encryptedData);

  const string = String.fromCharCode.apply(null, Array.from(uintArray));

  // var buf = Buffer.from(uintArray);
  const base64Data = btoa(string)//buf.toString('base64');

  return {
    base64Data,
    iv: iv.toString()
  };
}

export async function decryptText(messageJSON: string, derivedKey: CryptoKey) {
  try {
    const message = JSON.parse(messageJSON) as EncryptedMessage;
    log(message);

    const text = message.base64Data;
    // const initializationVector = new Uint8Array(message.iv).buffer;
    let iv = new Uint8Array(message.iv.split(',').map((x) => parseInt(x)));

    const string = atob(text);
    const uintArray = new Uint8Array(
      [...string].map((char) => char.charCodeAt(0))
    );
    const algorithm = {
      name: 'AES-GCM',
      iv: iv,
    };
    const decryptedData = await window.crypto.subtle.decrypt(
      algorithm,
      derivedKey,
      uintArray
    );
    return new TextDecoder().decode(decryptedData);
  } catch (e) {
    return `error decrypting message: ${e}`;
  }
}
