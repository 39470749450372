import { Button } from './Button';

export function LinkButton({ name, link }: { name: string; link: string }) {
  return (
    <Button
      as={'div'}
      onClick={() => {
        window.open(link, '_blank');
      }}
      name={name}
      content={'?'}
      // href={link}
      className="rounded-full text-xs !bg-secondary text-neutral mx-2 self-center !px-1 !py-0 h-4"
    />
  );
}
