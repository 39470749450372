import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../reduxStore";

export interface TempRoomState {
  roomPassword?: string
}

// Define the initial state using that type
const initialState: TempRoomState = {
  roomPassword: '',
}

const slice = createSlice({
  name: 'tempRoom',
  initialState,
  reducers: {
    updateRoomPassword(state, action: PayloadAction<string>) {
      state.roomPassword = action.payload;
    }
  },
});

export const tempRoomActions = slice.actions
export const selectRoomPassword = (state: RootState) => state.tempRoom.roomPassword

export default slice.reducer